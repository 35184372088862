export enum Basemap {
    OPEN_STREETMAPS = 'OpenStreetMaps',
    OPEN_ELEVATION = 'Open Elevation',
    STADIA_SATELLITE = 'Satellite',
    STADIA_HYBRID = 'Hybrid',
    OSM_DEFAULT_LIGHT = 'White Base',
    OSM_DEFAULT_DARK = 'Dark Base',
    WHITE_BLANK = 'White Blank',
    DARK_BLANK = 'Dark Blank',
}

export const getBasemap = (value: string): Basemap | undefined => {
    const key = Object.keys(Basemap).find((key) => Basemap[key] === value);
    if (!key) return undefined;
    return Basemap[key];
};
