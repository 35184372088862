import { useState } from 'react';

import { ScrollableContainer } from '../Shared/containers';
import MobileAccountHeader from './Account/mobile-account-header';
import ConfirmModal from '../../Shared/Modal/confirm';
import MobileAccountDetails from './Account/mobile-account-details';
import MobileAccountDelete from './Account/mobile-account-delete';

interface MobileProfileAccountProps {
    onExit: () => void;
}

const MobileProfileAccount = (props: MobileProfileAccountProps) => {
    const [confirmExit, setConfirmExit] = useState<boolean>(false);
    const [hasChanges, setHasChanges] = useState<boolean>(false);

    const handleBack = () => {
        if (hasChanges) {
            setConfirmExit(true);
            return;
        }

        props.onExit();
    };

    const handleEditSuccess = () => {
        props.onExit();
    };

    const handleEditCancel = () => {
        props.onExit();
    };

    const handleDeleteSuccess = () => {
        props.onExit();
    };

    const handleDeleteCancel = () => {
        props.onExit();
    };

    return (
        <ScrollableContainer>
            <MobileAccountHeader onBack={() => handleBack()} />
            <MobileAccountDetails
                onSuccess={() => handleEditSuccess()}
                onCancel={() => handleEditCancel()}
                onChange={(hasChange) => setHasChanges(hasChange)}
            />
            <MobileAccountDelete onSuccess={() => handleDeleteSuccess()} onCancel={() => handleDeleteCancel()} />
            {confirmExit && (
                <ConfirmModal
                    isOpen={true}
                    title="Unsaved Changes"
                    message={'It seems you have some unsaved changes, do you want to continue?'}
                    onToggle={() => setConfirmExit(false)}
                    onConfirm={() => props.onExit()}
                />
            )}
        </ScrollableContainer>
    );
};

export default MobileProfileAccount;
