import L from 'leaflet';

import {
    LeafletContextInterface,
    LeafletElement,
    PathProps,
    createElementHook,
    createElementObject,
    useLayerLifecycle,
    useLeafletContext,
} from '@react-leaflet/core';

import PolygonPath, { selectedPolygonOutlineOptions } from './polygon';

import { createLengthLabel, createTotalLengthLabel } from '../Measurement/length-label';
import {
    isLineSegmentShortEnoughToShowMeasurement,
    shouldAllowLineSegmentLength,
} from '../Measurement/measurement-util';
import LayersUtil from '../layers-util';
import { polygonToPaddedPolygon } from '../use-select-annotation-utils';
import { nodeMarkerIcon } from './node-marker-handles';
import { createAreaLabel } from '../Measurement/area-label';
import { createPolygonDragElement } from './polygon-drag-element';

interface PolygonAnnotationProps {
    isSelected: boolean;
    isDisabled?: boolean;
    polygon: PolygonPath;
    onUpdatePolygon?: (polygon: PolygonPath) => void;
    children?: React.ReactNode;
}

// We don't want the screen to become cluttered with edit nodes if the polygon is complex, such as a freehand polygon
// These types of features are not editable, the user will have to delete and re-create them if they wish to modify the geometry
const MAXIMUM_NODE_COUNT_TO_ALLOW_EDITING_GEOMETRY = 20;

const createNodeMarkers = (
    pathElement: Readonly<{ instance: L.Polygon; context: Readonly<{ map: L.Map }> }>,
    context: LeafletContextInterface
): LeafletElement<L.Marker, LeafletContextInterface>[] => {
    if ((pathElement.instance.getLatLngs()[0] as L.LatLng[]).length > MAXIMUM_NODE_COUNT_TO_ALLOW_EDITING_GEOMETRY) {
        return [];
    } else {
        const controlPaneId = LayersUtil.getControlPaneId(context.map);
        const nodeMarkers = (pathElement.instance.getLatLngs()[0] as L.LatLng[]).map((position, index) => {
            const marker = new L.Marker(position, {
                interactive: true,
                draggable: true,
                icon: nodeMarkerIcon,
                pane: controlPaneId,
            });
            const markerElement = createElementObject<L.Marker>(marker, context);

            markerElement.instance.on('drag', (e: L.LeafletMouseEvent) => {
                const latlngs = pathElement.instance.getLatLngs()[0] as L.LatLng[];
                pathElement.instance.setLatLngs(latlngs.map((latLng, i) => (i === index ? e.latlng : latLng)));
                pathElement.instance.fire('update');
            });

            context.map.addLayer(markerElement.instance);
            return markerElement;
        });

        return nodeMarkers;
    }
};

const createPolygonAnnotation = (props: PolygonAnnotationProps, context: LeafletContextInterface) => {
    const paneId = LayersUtil.getPaneId(context.map, props.polygon);

    const fillPattern = props.polygon.fillPattern || '';
    const polygon = new L.Polygon(props.polygon.positions, {
        ...props.polygon.options,
        fillColor: fillPattern ? fillPattern : props.polygon.options.fillColor,
        pane: paneId,
    });

    const polygonElement = createElementObject<L.Polygon, PathProps>(polygon, context);

    const selectedOutlinePaneId = LayersUtil.getSelectedOutlinePaneId(context.map);
    const polygonBounds = new L.Polygon(polygonToPaddedPolygon(context.map, props.polygon.positions), {
        ...selectedPolygonOutlineOptions,
        pane: selectedOutlinePaneId,
    });
    const polygonBoundsElement = createElementObject<L.Polygon, PathProps>(polygonBounds, context);

    const polygonDragElement = createPolygonDragElement(
        { polygonElement: polygonElement, positions: props.polygon.positions, isSelected: props.isSelected },
        context
    );

    let nodeMarkers: LeafletElement<L.Marker, LeafletContextInterface>[] = [];
    let areaLabel;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let lineMeasurementLabels: any[] = [];

    const addPathMeasurementLabels = () => {
        if (shouldAllowLineSegmentLength(props.polygon)) {
            if (props.polygon.showLength === 'sections') {
                const latlngs = (polygonElement.instance.getLatLngs() as L.LatLng[][])[0];
                latlngs.forEach((latLng, index) => {
                    const nextLatLng = index < latlngs.length - 1 ? latlngs[index + 1] : latlngs[0];
                    if (isLineSegmentShortEnoughToShowMeasurement(latLng, nextLatLng)) {
                        const label = createLengthLabel(
                            latLng,
                            nextLatLng,
                            polygonElement.instance.getLatLngs() as L.LatLng[],
                            props.polygon.units,
                            props.polygon.labelColor,
                            props.polygon.labelBgColor,
                            paneId,
                            context
                        );
                        lineMeasurementLabels.push(label);
                        context.map.addLayer(label.instance);
                    }
                });
            } else if (props.polygon.showLength === 'total') {
                const segments = polygonElement.instance.getLatLngs()[0] as L.LatLng[];
                segments.push(segments[0]); // included the last segment to close the polygon

                createTotalLengthLabel(
                    segments,
                    props.polygon.units,
                    props.polygon.labelColor,
                    props.polygon.labelBgColor,
                    paneId,
                    context
                ).then((label) => {
                    lineMeasurementLabels.push(label);
                    context.map.addLayer(label.instance);
                });
            }
        } else {
            removePathMeasurementLabels();
            if (props.onUpdatePolygon) {
                props.onUpdatePolygon({
                    ...props.polygon,
                    positions: polygonElement.instance.getLatLngs()[0] as L.LatLng[],
                    showLength: false,
                });
            }
        }
    };

    const removePathMeasurementLabels = () => {
        lineMeasurementLabels.forEach((label) => {
            context.map.removeLayer(label.instance);
        });
        lineMeasurementLabels = [];
    };

    const updateAreaLabel = () => {
        if (areaLabel) {
            context.map.removeLayer(areaLabel.instance);
        }
        areaLabel = createAreaLabel(
            props.polygon.positions,
            props.polygon.units,
            props.polygon.labelColor,
            props.polygon.labelBgColor,
            paneId,
            context
        );
        context.map.addLayer(areaLabel.instance);

        const bounds = areaLabel.instance.getBounds();
        const ne = bounds.getNorthEast();
        const nw = bounds.getNorthWest();
        const screenWidthOfBounds = context.map.latLngToLayerPoint(ne).x - context.map.latLngToLayerPoint(nw).x;

        const svgElement = areaLabel.instance.getElement();
        const svgElementWidth = svgElement.getBBox().width;

        if (svgElementWidth > screenWidthOfBounds) {
            context.map.removeLayer(areaLabel.instance);
        }
    };

    const addAreaLabel = () => {
        if (!areaLabel) {
            updateAreaLabel();
        }
    };

    const removeAreaLabel = () => {
        if (areaLabel) {
            context.map.removeLayer(areaLabel.instance);
        }
        context.map.off('zoomend', onZoomEnd);
    };

    const onZoomEnd = () => {
        if (props.polygon.showArea) {
            updateAreaLabel();
        }
    };

    const addNodeMarkers = () => {
        context.map.addLayer(polygonBoundsElement.instance);
        nodeMarkers.forEach((nodeMarker) => {
            context.map.removeLayer(nodeMarker.instance);
        });
        nodeMarkers = [];

        nodeMarkers = createNodeMarkers(polygonElement, context);
        nodeMarkers.forEach((nodeMarker) => {
            context.map.addLayer(nodeMarker.instance);
        });
    };

    const removeNodeMarkers = () => {
        context.map.removeLayer(polygonBoundsElement.instance);

        nodeMarkers.forEach((nodeMarker) => {
            context.map.removeLayer(nodeMarker.instance);
        });
        nodeMarkers = [];
    };

    polygonElement.instance.on('update-label', () => {
        if (props.polygon.showArea) {
            updateAreaLabel();
        } else {
            removeAreaLabel();
        }
    });

    polygonElement.instance.on('add', () => {
        if (props.polygon.showArea) {
            addAreaLabel();
        }

        if (props.polygon.showLength) {
            addPathMeasurementLabels();
        }

        if (props.isSelected) {
            addNodeMarkers();
            context.map.addLayer(polygonDragElement.instance);
        } else {
            removeNodeMarkers();
            context.map.removeLayer(polygonDragElement.instance);
        }
        context.map.on('zoomend', onZoomEnd);
    });

    polygonElement.instance.on('remove', () => {
        context.map.off('zoomend', onZoomEnd);
        context.map.removeLayer(polygonBoundsElement.instance);
        removeAreaLabel();
        removePathMeasurementLabels();

        removeNodeMarkers();
    });

    polygonElement.instance.on('path-drag-start', () => {
        removeNodeMarkers();
        removePathMeasurementLabels();

        context.map.removeLayer(polygonBoundsElement.instance);
    });

    polygonElement.instance.on('path-drag-end', () => {
        addNodeMarkers();
        addPathMeasurementLabels();

        polygonBoundsElement.instance.setLatLngs(polygonToPaddedPolygon(context.map, props.polygon.positions));

        context.map.addLayer(polygonBoundsElement.instance);
    });

    polygonElement.instance.on('mouseover', () => {
        L.DomUtil.addClass(context.map.getContainer(), 'leaflet-interactive');
        const currentWeight = props.polygon.options.weight || 3;
        polygonElement.instance.setStyle({ weight: currentWeight + 1 });
    });

    polygonElement.instance.on('mouseout', () => {
        L.DomUtil.removeClass(context.map.getContainer(), 'leaflet-interactive');
        polygonElement.instance.setStyle({ weight: props.polygon.options.weight || 3 });
    });

    polygonElement.instance.on('update', () => {
        polygonBoundsElement.instance.setLatLngs(polygonToPaddedPolygon(context.map, props.polygon.positions));
        polygonElement.instance.fireEvent('update-label');

        const updatedPolygon = {
            ...props.polygon,
            positions: polygonElement.instance.getLatLngs()[0] as L.LatLng[],
        };
        props.onUpdatePolygon && props.onUpdatePolygon(updatedPolygon);
    });

    // The interactive option adds or removes the leaflet-interactive class which changes the cursor
    // This means we have a third state of interactivity that needs to be handled when the annotation is disabled
    // otherwise hovering when editing will not show the pointer cursor
    if (props.isDisabled) {
        polygonElement.instance.options.interactive = false;
    } else {
        polygonElement.instance.options.interactive = true;
    }

    return polygonElement;
};

const updatePolygonAnnotation = (instance: L.Polygon, props: PolygonAnnotationProps, _: PolygonAnnotationProps) => {
    const fillPattern = props.polygon.fillPattern || '';
    instance.setStyle({
        ...instance.options,
        color: props.polygon.options.color,
        fillColor: fillPattern ? fillPattern : props.polygon.options.fillColor,
        weight: props.polygon.options.weight,
    });
};

const usePolygonAnnotation = createElementHook<L.Polygon, PolygonAnnotationProps, LeafletContextInterface>(
    createPolygonAnnotation,
    updatePolygonAnnotation
);

const PolygonAnnotation = (props: PolygonAnnotationProps) => {
    const context = useLeafletContext();
    const polygon = usePolygonAnnotation(props, context);
    useLayerLifecycle(polygon.current, context);

    return null;
};

export default PolygonAnnotation;
