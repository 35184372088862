import styled from 'styled-components';
import { Navbar } from 'reactstrap';

import { UserDTO } from '../../../../api/model';
import { MobileButtonText } from '../../Shared/button';
import ProfileName from '../../../Drawer/SideDrawer/ProfileDrawer/Profile/profile-name';

interface MobileViewHeaderProps {
    profile: UserDTO;
    onClose?: () => void;
}

const MobileViewHeader = (props: MobileViewHeaderProps) => {
    return (
        <ProfileHeader dark>
            <ProfileHeaderName
                isCommunityModerator={props.profile?.communityModerator ?? false}
                isReputable={props.profile?.reputable ?? false}
            >
                {props.profile?.name || 'Soar User'}
            </ProfileHeaderName>

            {props.onClose && (
                <ProfileCloseButton onClick={props.onClose}>
                    <ProfileCloseIcon className="fa fa-times" />
                </ProfileCloseButton>
            )}
        </ProfileHeader>
    );
};

export default MobileViewHeader;

const ProfileHeader = styled(Navbar)`
    background-color: ${(props) => props.theme.color.black};
    border-bottom: 1px solid ${(props) => props.theme.color.lightGray};

    position: fixed;
    width: 100%;
    height: 60px;
    z-index: ${(props) => props.theme.zIndex.nav};

    justify-content: center;
`;

const ProfileHeaderName = styled(ProfileName)`
    color: ${(props) => props.theme.color.white} !important;
    font-size: ${(props) => props.theme.fontSize.xlarge};
    font-weight: 700;

    margin: 0 auto;
    flex: 0 1 auto;
    max-width: 75%;

    display: flex;
    justify-content: center;

    span {
        text-decoration: none !important;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
`;

const ProfileCloseButton = styled(MobileButtonText)`
    position: absolute;
    top: 50%;
    right: 0;

    transform: translateY(-50%);
`;
const ProfileCloseIcon = styled.i`
    font-size: ${(props) => props.theme.fontSize.xlarge};
`;
