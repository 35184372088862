import { DefaultTheme, css } from 'styled-components';

/**
 * The concrete theme should only include the bare minimum of properties. Think of it like constants.
 * Before adding to it, think carefully if this property belongs to the entire application or not.
 *
 * If you require some properties to be shared between disparate components, consider using the `css` function. For example;
 *
 *     const border = css`
 *          border: 1px solid rgba(255, 255, 255, 0.6);
 *     `;
 *
 *     const ItemContainer = styled.div`
 *         ${border}
 *     `;
 *
 */

const theme: DefaultTheme = {
    color: {
        black: '#000000',
        red: '#ff0000',
        green: '#00ff00',
        orange: '#ffa500',
        yellow: '#eed926',
        opaqueYellow: `rgba(238, 217, 35, 0.8)`,
        gray: '#343a40',
        opaqueGray: 'rgba(52,58,64,0.8)',
        lightGray: '#d3d3d3',
        opaqueLightGray: 'rgba(211, 211, 211, 0.6)',
        white: '#ffffff',
        opaqueWhite: 'rgba(255, 255, 255, 0.8)',
        transparentDark: 'rgba(0, 0, 0, 0.8)',
        background: '#181a1a',

        mobile: {
            divider: 'rgba(255, 255, 255, 0.3)',
        },
    },
    fontSize: {
        small: '0.8rem',
        normal: '1rem',
        large: '1.2rem',
        xlarge: '1.5rem',
    },
    lineHeight: {
        small: '1rem',
        normal: '1.5rem',
        large: '2rem',
        xlarge: '2.5rem',
    },
    borderRadius: '6px',
    zIndex: {
        nav: 10025,
        drawer: 1010,
        control: 1005, // map controls outside map container
        tooltip: 1020,
        modal: 10000,
        toast: 10001,
        map: {
            tiles: 200, // leaflet default
            overlay: 900, // should be above annotations
            shadow: 920,
            marker: 940,
            tooltip: 960,
            popup: 980,
        },
    },
};

export { theme };

export const lightDropShadow = css`
    box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.3), 0px 23px 36px 3px rgba(0, 0, 0, 0.24),
        0px 9px 44px 8px rgba(0, 0, 0, 0.22);
    border: 1px solid #515151;
`;
