import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';

import {
    selectLoggedIn,
    selectMyListings,
    selectMyMapsLoading,
    selectMyProfile,
} from '../../../store/Account/selectors';
import { actionGetMyListings } from '../../../store/Account/actions';

import Analytics from '../../../lib/user-analytics';

import { shareLink } from '../mobile-utils';

import ProfileHeader from './mobile-profile-header';
import ProfileDetails from './mobile-profile-details';
import ProfileListings from './mobile-profile-listings';

import MobileAccess from './Access/mobile-access';
import MobileProfileEdit from './mobile-profile-edit';
import MobileProfileAccount from './mobile-profile-account';
const MobileProfile = () => {
    const dispatch = useDispatch();

    const isLoggedIn = useSelector(selectLoggedIn);
    const myProfile = useSelector(selectMyProfile);
    const myListings = useSelector(selectMyListings);
    const myListingsLoading = useSelector(selectMyMapsLoading);

    const [editProfile, setEditProfile] = useState<boolean>(false);
    const [editAccount, setEditAccount] = useState<boolean>(false);
    const [avatarPreview, setAvatarPreview] = useState<string>('');

    useEffect(() => {
        if (!isLoggedIn || !myProfile) {
            return;
        }

        if (!myListings) {
            dispatch(actionGetMyListings());
        }
    }, [dispatch, isLoggedIn, myListings, myProfile]);

    const generateProfileLink = () => {
        if (!myProfile) {
            return;
        }
        return `${window.location.origin}/profile/${myProfile.userId}`;
    };

    const handleShare = () => {
        const profileLink = generateProfileLink();
        if (!profileLink) {
            return;
        }

        shareLink(profileLink, 'Share your Soar profile', 'Check out my profile on Soar.Earth!').then(() => {
            Analytics.Event('Mobile Profile', 'Share Soar Profile', profileLink);
        });
    };

    const handleEditProfile = () => {
        setEditProfile(true);
    };

    const handleEditAccount = () => {
        setEditAccount(true);
    };

    if (!isLoggedIn || !myProfile) {
        return <MobileAccess />;
    }

    if (editProfile) {
        return <MobileProfileEdit onExit={() => setEditProfile(false)} setAvatarPreview={setAvatarPreview} />;
    }

    if (editAccount) {
        return <MobileProfileAccount onExit={() => setEditAccount(false)} />;
    }

    return (
        <ProfileContainer>
            <ProfileHeader
                profile={myProfile}
                onShare={handleShare}
                onEditProfile={handleEditProfile}
                onEditAccount={handleEditAccount}
            />
            <ProfileDetails profile={myProfile} avatarPreview={avatarPreview} listings={myListings ?? []} />
            <ProfileListings listings={myListings} listingLoading={myListingsLoading ?? false} />
        </ProfileContainer>
    );
};

export default MobileProfile;

const ProfileContainer = styled.section`
    display: flex;
    flex-direction: column;
    align-items: stretch;

    overflow-y: auto;

    height: calc(100vh - 60px);
`;
