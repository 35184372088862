import L, { LeafletMouseEvent } from 'leaflet';
import {
    createElementHook,
    createElementObject,
    LeafletContextInterface,
    useLayerLifecycle,
    useLeafletContext,
} from '@react-leaflet/core';
import FreehandPolyline, { defaultFreehandPolylineOptions } from './freehand-polyline';
import { v4 as uuidv4 } from 'uuid';
import LayersUtil, { defaultZIndex } from '../layers-util';
import store from '../../../../store/store';
import { defaultLabelColor } from '../Measurement/measurement-toolbar-label-color';
import { defaultBackgroundColor } from '../Measurement/measurement-toolbar-background-color';

interface FreehandPolylineBuilderProps {
    onCreateFreehand: (freehandPolyline: FreehandPolyline) => void;
    onCancelBuild: () => void;
    zIndex?: number;
}

const createFreehandPolylineBuilder = (props: FreehandPolylineBuilderProps, context: LeafletContextInterface) => {
    const lastUsedFreehandPolylineOptions =
        store.getState().annotationDomain.present.freehandReducer.freehandDrawOptions;
    const builderPaneId = LayersUtil.getBuilderPaneId(context.map);

    const freehandPolylinePath = new L.Polyline([], {
        ...defaultFreehandPolylineOptions,
        ...(lastUsedFreehandPolylineOptions ?? {}),
        interactive: false,
        pane: builderPaneId,
    });
    const freehandPolylinePathElement = createElementObject<L.Polyline, FreehandPolylineBuilderProps>(
        freehandPolylinePath,
        context
    );

    const onKeyDown = (e: KeyboardEvent) => {
        if (e.key === 'Escape') {
            props.onCancelBuild();
            context.map.off('mousemove', onMouseMove);
            context.map.off('mousedown', onMouseDown);
            context.map.off('mouseup', onMouseUp);
            context.map.dragging.enable();
            L.DomUtil.removeClass(context.map.getContainer(), 'leaflet-crosshair');
            document.removeEventListener('keydown', onKeyDown);
        }
    };

    const onMouseDown = (e: LeafletMouseEvent) => {
        context.map.on('mouseup', onMouseUp);
        context.map.on('mousemove', onMouseMove);
        freehandPolylinePath.setLatLngs([e.latlng]);
    };

    const onMouseUp = () => {
        context.map.off('mousemove', onMouseMove);
        context.map.off('mouseup', onMouseUp);

        const freehandPolyline: FreehandPolyline = {
            annotationType: 'FreehandPolyline',
            id: uuidv4(),
            positions: freehandPolylinePath.getLatLngs() as L.LatLng[],
            options: freehandPolylinePathElement.instance.options as L.PolylineOptions,
            zIndex: props.zIndex || defaultZIndex,
            showLength: false,
            units: 'metric',
            labelColor: defaultLabelColor,
            labelBgColor: defaultBackgroundColor,
        };

        // Only create the freehand polyline if there are more than 1 points
        if (freehandPolylinePath.getLatLngs().length > 1) {
            props.onCreateFreehand(freehandPolyline);
        } else {
            props.onCancelBuild();
        }
    };

    const onMouseMove = (e: LeafletMouseEvent) => {
        if (freehandPolylinePath.getLatLngs().length === 0) {
            freehandPolylinePath.addLatLng(e.latlng);
        } else {
            const THRESHOLD = 1; // Must be more than 1px away from the last point to add a new point
            const lastPoint = (freehandPolylinePath.getLatLngs() as L.LatLng[])[
                freehandPolylinePath.getLatLngs().length - 1
            ];
            const lastPointScreenPoint = context.map.latLngToContainerPoint(lastPoint);
            const screenPoint = context.map.latLngToContainerPoint(e.latlng);

            const dx = screenPoint.x - lastPointScreenPoint.x;
            const dy = screenPoint.y - lastPointScreenPoint.y;
            const distance = Math.sqrt(dx * dx + dy * dy);
            if (distance > THRESHOLD) {
                freehandPolylinePath.addLatLng(e.latlng);
            }
        }
    };

    freehandPolylinePathElement.instance.on('add', () => {
        context.map.dragging.disable();
        context.map.on('mousedown', onMouseDown);
        L.DomUtil.addClass(context.map.getContainer(), 'leaflet-crosshair');
    });

    freehandPolylinePathElement.instance.on('remove', () => {
        context.map.dragging.enable();
        context.map.off('mousedown', onMouseDown);
        context.map.off('mouseup', onMouseUp);
        context.map.off('mousemove', onMouseMove);
        L.DomUtil.removeClass(context.map.getContainer(), 'leaflet-crosshair');
        document.removeEventListener('keydown', onKeyDown);
    });

    document.addEventListener('keydown', onKeyDown);

    return freehandPolylinePathElement;
};

const useFreehandPolylineBuilder = createElementHook<L.Polyline, FreehandPolylineBuilderProps, LeafletContextInterface>(
    createFreehandPolylineBuilder
);

const FreehandPolylineBuilder = (props: FreehandPolylineBuilderProps) => {
    const context = useLeafletContext();
    const freehandPolylineBuilder = useFreehandPolylineBuilder(props, context);
    useLayerLifecycle(freehandPolylineBuilder.current, context);
    return null;
};

export default FreehandPolylineBuilder;
