import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { UserDTO } from '../../../../api/model';
import UserHelper from '../../../../lib/user-helper';
import Analytics from '../../../../lib/user-analytics';
import { shareLink } from '../../mobile-utils';

interface MobileViewDetailsShareProps {
    userProfile: UserDTO;
}

const MobileViewDetailsShare = (props: MobileViewDetailsShareProps) => {
    const [websiteUrl, setWebsiteUrl] = useState<string | undefined>(undefined);
    const [twitterUrl, setTwitterUrl] = useState<string | undefined>(undefined);
    const [facebookUrl, setFacebookUrl] = useState<string | undefined>(undefined);
    const [instagramUrl, setInstagramUrl] = useState<string | undefined>(undefined);
    const [linkedInUrl, setLinkedInUrl] = useState<string | undefined>(undefined);
    const [userSoarProfileUrl, setUserSoarProfileUrl] = useState<string | undefined>(undefined);

    useEffect(() => {
        setWebsiteUrl(UserHelper.validateUrl(props.userProfile.websiteUrl));
        setTwitterUrl(UserHelper.validateUrl(props.userProfile.twitterUrl));
        setFacebookUrl(UserHelper.validateUrl(props.userProfile.facebookUrl));
        setInstagramUrl(UserHelper.validateUrl(props.userProfile.instagramUrl));
        setLinkedInUrl(UserHelper.validateUrl(props.userProfile.linkedinUrl));
        setUserSoarProfileUrl(UserHelper.validateUrl(props.userProfile.userSoarProfileUrl));
    }, [props.userProfile]);

    const generateProfileLink = () => {
        if (!props.userProfile) {
            return;
        }
        return `${window.location.origin}/profile/${props.userProfile.userId}`;
    };

    const handleShare = () => {
        const profileLink = generateProfileLink();
        if (!profileLink) {
            return;
        }

        shareLink(profileLink, 'Share your Soar profile', 'Check out my profile on Soar.Earth!').then(() => {
            Analytics.Event('Mobile Profile', 'Share Soar Profile', profileLink);
        });
    };

    return (
        <ProfileShareContainer>
            {UserHelper.hasShareLinks(props.userProfile) && (
                <React.Fragment>
                    {props.userProfile && props.userProfile.twitterUrl && twitterUrl ? (
                        <a
                            href={twitterUrl}
                            title="X"
                            target="_blank"
                            onClick={() => Analytics.Event('Profile', 'X', props.userProfile.name)}
                        >
                            <ProfileSocialImage src="/assets/social-share-icons/twitter.png" />
                        </a>
                    ) : null}

                    {props.userProfile && props.userProfile.facebookUrl && facebookUrl ? (
                        <a
                            href={facebookUrl}
                            title="Facebook"
                            target="_blank"
                            onClick={() => Analytics.Event('Profile', 'Facebook', props.userProfile.name)}
                        >
                            <ProfileSocialImage src="/assets/social-share-icons/facebook.png" />
                        </a>
                    ) : null}

                    {props.userProfile && props.userProfile.instagramUrl && instagramUrl ? (
                        <a
                            href={instagramUrl}
                            title="Instagram"
                            target="_blank"
                            onClick={() => Analytics.Event('Profile', 'Instagram', props.userProfile.name)}
                        >
                            <ProfileSocialImage src="/assets/social-share-icons/instagram.png" />
                        </a>
                    ) : null}

                    {props.userProfile && props.userProfile.linkedinUrl && linkedInUrl ? (
                        <a
                            href={linkedInUrl}
                            title="LinkedIn"
                            target="_blank"
                            onClick={() => Analytics.Event('Profile', 'LinkedIn', props.userProfile.name)}
                        >
                            <ProfileSocialImage src="/assets/social-share-icons/linkedin.png" />
                        </a>
                    ) : null}

                    {props.userProfile.websiteUrl ? (
                        <a
                            href={websiteUrl}
                            title="Website"
                            target="_blank"
                            onClick={() => Analytics.Event('Profile', 'Link', props.userProfile.name)}
                        >
                            <ProfileSocialImage src="/assets/social-share-icons/website.png" />
                        </a>
                    ) : null}
                </React.Fragment>
            )}
            <UserSoarProfile href={userSoarProfileUrl} title="Soar Profile" onClick={() => handleShare()}>
                <ProfileSocialImage src="/assets/social-share-icons/icon-share.png" />
            </UserSoarProfile>
        </ProfileShareContainer>
    );
};

export default MobileViewDetailsShare;

const ProfileShareContainer = styled.div`
    padding: 10px 0;
    text-align: center;

    display: flex;
    flex-direction: row;
    justify-content: center;
`;

const UserSoarProfile = styled.a`
    cursor: pointer;
    align-items: center;
    justify-content: center;
`;

const ProfileSocialImage = styled.img`
    width: 32px;
    height: 32px;
    margin: 0px 10px;
    pointer-events: all;
`;
