import React from 'react';
import { useSelector } from 'react-redux';
import PathAnnotation from '../../../MapView/Annotations/Polyline/polyline-annotation';
import { selectPolylines } from '../../../../store/Annotations/Path/selectors';

const MobilePolyline = () => {
    const polylines = useSelector(selectPolylines);

    return (
        <React.Fragment>
            {polylines.map((polyline) => {
                return (
                    <PathAnnotation
                        key={`
                            ${polyline.id}-
                            ${polyline.options.color}-
                            ${polyline.options.dashArray}-
                            ${polyline.options.dashOffset}-
                            ${polyline.options.weight}-
                            ${polyline.positions.length}-
                            ${polyline.showLength}-
                            ${polyline.labelColor}-
                            ${polyline.units}-
                            ${polyline.showLength}-
                            ${polyline.zIndex}-
                            ${polyline?.positions ? JSON.stringify(polyline.positions) : ''}
                        `}
                        polyline={polyline}
                        isDisabled={true}
                        isSelected={false}
                    />
                );
            })}
        </React.Fragment>
    );
};

export default MobilePolyline;
