import styled from 'styled-components';

import { MobileButtonBack } from '../../Shared/button';
import { ContentHeader } from '../../Shared/containers';

interface MobileAccountHeaderProps {
    onBack?: () => void;
}

const MobileAccountHeader = (props: MobileAccountHeaderProps) => {
    return (
        <ProfileEditHeader>
            {props.onBack && <MobileButtonBack onClick={props.onBack} />}
            <ProfileName>Your Account</ProfileName>
        </ProfileEditHeader>
    );
};

export default MobileAccountHeader;

const ProfileEditHeader = styled(ContentHeader)`
    position: relative;
    padding: 1rem;
`;

const ProfileName = styled.strong`
    color: ${(props) => props.theme.color.white};
    font-size: ${(props) => props.theme.fontSize.large};
    font-weight: 700;

    margin: 0 auto;
`;
