import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { actionSetSelectedSearchTerm } from '../../../store/Map/MapSelection/actions';
import { actionActiveMapCleared } from '../../../store/Map/SuperMap/actions';
import SearchBarAutocomplete from '../../Search/search-bar-autocomplete';
import { setSearchTermToLocalStorage } from '../../Search/search-term-localstorage';
import { selectSelectedSearchTerm } from '../../../store/Map/MapSelection/selectors';
import { capitalize } from '../../../lib/string-util';
import Analytics from '../../../lib/user-analytics';

const MobileSearchBar = () => {
    const dispatch = useDispatch();

    const searchBarContainerRef = useRef(null);

    const searchTerm = useSelector(selectSelectedSearchTerm);
    const [searchInput, setSearchInput] = useState<string>(searchTerm || '');
    const [isSearchInputFocused, setIsSearchInputFocused] = useState<boolean>(false);

    const onSearch = (searchTerm: string) => {
        setSearchTermToLocalStorage(searchTerm);
        setIsSearchInputFocused(false);
        dispatch(actionActiveMapCleared());
        dispatch(actionSetSelectedSearchTerm(searchTerm));
        Analytics.Event('Mobile Search', 'Search', searchTerm);
    };

    const onClearSearch = () => {
        setSearchInput('');
        dispatch(actionSetSelectedSearchTerm(undefined));
        Analytics.Event('Mobile Search', 'Cleared Search');
    };

    const onChangeSearchInput = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchInput(e.target.value);

        if (e.target.value.length === 0) {
            onClearSearch();
        }
    };

    const onFocusSearchInput = () => {
        setIsSearchInputFocused(true);
    };

    const onClickBack = () => {
        setIsSearchInputFocused(false);
    };

    return (
        <SearchInputContainer
            tabIndex={0}
            id="search-input"
            isFocused={isSearchInputFocused}
            ref={searchBarContainerRef}
        >
            {isSearchInputFocused ? (
                <BackButton onClick={onClickBack}>
                    <BackButtonIcon className="fa fa-chevron-left" />
                </BackButton>
            ) : null}

            <Input
                onFocus={onFocusSearchInput}
                value={searchInput}
                onChange={onChangeSearchInput}
                type="text"
                placeholder={'Search for Maps!'}
                onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                        onSearch(searchInput);
                    }

                    if (e.key === 'Escape') {
                        onClearSearch();
                    }
                }}
            />

            {isSearchInputFocused ? (
                <SearchBarAutocomplete
                    searchInput={searchInput}
                    onSelectAutocomplete={(autocomplete) => {
                        setSearchInput(capitalize(autocomplete.title));
                        onSearch(autocomplete.title);
                    }}
                />
            ) : null}

            <IconContainer
                isFocused={isSearchInputFocused}
                onClick={() => {
                    if (searchInput.length > 0) {
                        onClearSearch();
                        setIsSearchInputFocused(false);
                    }
                }}
            >
                {searchInput.length === 0 ? (
                    <SearchIcon src="/assets/side-drawer/discover_search_icon_black.svg" title="Search" />
                ) : (
                    <CloseIcon src="/assets/close-black.png" title="Clear Search" />
                )}
            </IconContainer>
        </SearchInputContainer>
    );
};

export default MobileSearchBar;

const SearchInputContainer = styled.div<{ isFocused: boolean }>`
    position: absolute;
    z-index: ${(props) => (props.isFocused ? 99999 : 9999)}; // prevents to overlap with basemap options
    height: 40px;
    width: ${(props) => (props.isFocused ? '100vw' : 'calc(100vw - 116px)')};
    left: ${(props) => (props.isFocused ? '0' : '60px')};
    top: ${(props) => (props.isFocused ? '0' : '10px')};
    background: white;
    margin: 0;
    padding: 0;
    border-radius: ${(props) => (props.isFocused ? '0' : '10px')};
    border: ${(props) => `2px solid ${props.theme.color.lightGray}`};
    transition: width 0.3s ease, left 0.3s ease, top 0.3s ease, border-radius 0.3s ease, border 0.3s ease;
    display: flex;
`;

const IconContainer = styled.div<{ isFocused: boolean }>`
    height: 37px;
    width: 60px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: ${(props) => (props.isFocused ? '0' : '10px')};
    background: ${(props) => props.theme.color.yellow};
    margin: 0;
    padding: 0;
    cursor: pointer;
`;

const SearchIcon = styled.img`
    height: 34px;
    width: auto;
    margin: 2px 0 0 8px;
`;

const Input = styled.input`
    background: transparent;
    border: none;
    width: 100%;
    outline: none;
    color: black;
    padding: 0 0 0 15px;
    margin: 0;
    font-size: 1.3rem;

    &::placeholder {
        color: black;
        font-weight: 200;
    }
`;

const CloseIcon = styled.img`
    width: 16px;
    height: 16px;
    margin: 11px 0 12px 18px;
`;

const BackButton = styled.button`
    color: black;
    border: none;
    background: transparent;
    font-size: ${(props) => props.theme.fontSize.normal};
    padding: 0px 10px;
    user-select: none;
    align-self: flex-start;
    margin-top: 10px;

    &:focus {
        outline: none;
    }
`;

const BackButtonIcon = styled.i`
    margin-right: 4px;
    user-select: none;
`;
