import styled from 'styled-components';

export const ScrollableContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: calc(100vh - 60px);

    border-bottom: 1px solid ${(props) => props.theme.color.lightGray};

    overflow-y: auto;

    font-size: ${(props) => props.theme.fontSize.normal};
    color: ${(props) => props.theme.color.white};
`;

export const ContentHeader = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    padding: 16px;

    box-sizing: content-box;
`;

export const Content = styled.div`
    padding: 64px 32px 32px;
`;

export const ContentFooter = styled.div`
    padding: 16px 32px 32px;

    color: ${(props) => props.theme.color.white};
`;

export const ContentError = styled.div`
    color: red !important;
    -webkit-text-fill-color: red;
    padding: 10px;
    padding-top: 0px;
    display: flex;
`;

export const ContentErrorIcon = styled.div`
    background-image: url("data:image/svg+xml,%3Csvg width='15' height='15' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M20 17V29' stroke='%23FF0000' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M20 11V13' stroke='%23FF0000' stroke-linecap='round' stroke-linejoin='round'/%3E%3Ccircle cx='20' cy='20' r='19.5' stroke='%23FF0000'/%3E%3C/svg%3E ");
    background-position: center;
    background-repeat: no-repeat;
    width: 20px;
    height: 20px;
    margin-right: 10px;
`;
