import React from 'react';
import styled from 'styled-components';
import Masonry from 'react-masonry-css';
import { useWindowSize } from '@react-hook/window-size';

import { ListingDTO } from '../../../../api/model';

import DiscoverCardGridView from '../../../Drawer/SideDrawer/DiscoverDrawer/discover-card-grid-view';
import { ContentLoading } from '../Shared/panels';

interface MobileViewListingsProps {
    listings: ListingDTO[];
    listingsLoading: boolean;
}

const MAX_CARD_WIDTH = 185;

const MobileViewListings = (props: MobileViewListingsProps) => {
    const [windowWidth] = useWindowSize();

    const masonryBreakPoints = (windowWidth: number): number => {
        const numCards = Math.floor(windowWidth / MAX_CARD_WIDTH);
        return numCards;
    };

    if (props.listingsLoading) {
        return <ContentLoading />;
    }

    return (
        <ProfileDetailsContainer>
            <ProfileMasonry
                breakpointCols={masonryBreakPoints(windowWidth)}
                className="masonry-grid"
                columnClassName="masonry-grid_column"
            >
                {props.listings.map((listing, index) => (
                    <DiscoverCardGridView key={index} listing={listing} hideAuthor={true} />
                ))}
            </ProfileMasonry>
        </ProfileDetailsContainer>
    );
};

export default MobileViewListings;

const ProfileDetailsContainer = styled.div``;

const ProfileMasonry = styled(Masonry)`
    &.masonry-grid {
        display: -webkit-box; /* Not needed if autoprefixing */
        display: -ms-flexbox; /* Not needed if autoprefixing */
        display: flex;
        width: auto;
    }

    &.masonry-grid_column {
        background-clip: padding-box;
        will-change: transform; /* Enable hardware acceleration for smoother animations */
    }
`;
