import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import moment from 'moment';
import { ListingDTO, ListingType } from '../../../../api/model';
import MapViewHistoryHelper from '../../../../lib/map-view-history-helper';
import UriHelper from '../../../../lib/uri-helper';
import Analytics from '../../../../lib/user-analytics';
import { actionActiveMapCleared } from '../../../../store/Map/SuperMap/actions';
import { actionUpdateActiveSearchPosition } from '../../../../store/Search/actions';
import { SideDrawerMode } from '../../../../store/SideDrawer/model';
import { actionSetResetCommentSection } from '../../../../store/SocialMapping/actions';
import ActiveMapLegend from '../../../Drawer/Maps/ActiveMap/active-map-legend';
import { CommentList } from '../../../Drawer/Maps/Comments/comment-list';
import ProfileName from '../../../Drawer/SideDrawer/ProfileDrawer/Profile/profile-name';
import { MarkdownViewer } from '../../../Shared/markdown-viewer';
import { BottomSheetState } from '../../BottomSheet/bottom-sheet';
import ActiveMapShare from './active-map-share';
import MapSearchDrawListingCardChip from '../../../Drawer/SideDrawer/ListingsDrawer/map-search-draw-listing-card-chip';
import {
    attachChipToListingDTO,
    listingChipForType,
} from '../../../Drawer/SideDrawer/ListingsDrawer/listing-card-chip';
import TemporalCategoryControl from '../../../Drawer/Shared/TemporalCategory/temporal-category-control';
import ActiveMapExternalSourceNotification from '../../../Drawer/Maps/ActiveMap/active-map-external-source-notification';

interface ActiveMapBottomSheetProps {
    listing: ListingDTO;
    bottomSheetState: BottomSheetState;
    onCloseMap: () => void;
    isClosing: boolean;
}

const ActiveMapBottomSheet = (props: ActiveMapBottomSheetProps) => {
    const { isClosing, onCloseMap } = props;

    const dispatch = useDispatch();

    useEffect(() => {
        if (props.listing) {
            MapViewHistoryHelper.addListingToViewedMapsHistory(props.listing);
        }
    }, [props.listing]);

    useEffect(() => {
        const closeActiveMap = () => {
            UriHelper.navigateToDrawer(SideDrawerMode.MAPS);
            dispatch(actionSetResetCommentSection(true));
            onCloseMap();
            Analytics.Event('Mobile - Active Map Bottom Sheet', 'Clicked Close Active Map Bottom Sheet');
        };

        if (isClosing) {
            closeActiveMap();
        }
    }, [dispatch, isClosing, onCloseMap]);

    // Clears the marker if the user selects a map or has added a marker then leaves the map
    // Note: This is an opinionated choice to have the marker removed when a map is selected
    // and could remain on the map until the search input has actually been cleared.
    useEffect(() => {
        const handleClearMarker = () => {
            dispatch(actionUpdateActiveSearchPosition(undefined));
        };

        handleClearMarker();
        return () => {
            handleClearMarker();
        };
    }, [dispatch]);

    const handleViewProfile = () => {
        dispatch(actionActiveMapCleared());
        UriHelper.navigateToPath(`/profile/${props.listing.userId}`);
    };

    const listingWithChip = attachChipToListingDTO(props.listing);
    const listingChip = listingWithChip.chip ? listingChipForType(listingWithChip.chip) : undefined;

    return (
        <React.Fragment>
            <TopContainer bottomSheetClosed={props.bottomSheetState === BottomSheetState.close}>
                <MapDetails>
                    <TitleContainer>
                        <Title singleLine={props.bottomSheetState === BottomSheetState.close}>
                            {props.listing.title}
                        </Title>

                        <CloseButton onClick={() => props.onCloseMap()}>
                            <CloseIcon className="fa fa-close" />
                        </CloseButton>
                    </TitleContainer>
                    <Author>
                        by&nbsp;
                        <UserProfileLink
                            userId={props.listing.userId}
                            onClick={handleViewProfile}
                            isCommunityModerator={props.listing.communityModerator}
                            isReputable={props.listing.reputable}
                        >
                            {props.listing.userName || 'Soar User'}
                        </UserProfileLink>
                        {listingChip ? (
                            <div style={{ marginRight: '12px', height: 'auto' }}>
                                <MapSearchDrawListingCardChip chip={listingChip} />
                            </div>
                        ) : null}
                    </Author>
                    {props.listing.listingType !== ListingType.WMS && (
                        <DateUploaded>{moment(props.listing.dateUploaded).fromNow()}</DateUploaded>
                    )}
                </MapDetails>
            </TopContainer>
            <ActiveMapExternalSourceNotification listing={props.listing} />

            <Divider />
            <ActiveMapShare listing={props.listing} />
            <TemporalCategoryControl
                listing={props.listing}
                fontSize="11px"
                margin="10px 0px 0px 0px"
                showDefaultCategories
            />
            {props.listing.description && props.listing.description.length > 0 && (
                <DescriptionContainer>
                    <MarkdownViewer text={props.listing.description} />
                </DescriptionContainer>
            )}

            {props.listing.tags?.length ? (
                <TagContainer>
                    {props.listing.tags.map((tag, index) => {
                        return <Tag key={`${tag}-${index}`}>{tag}</Tag>;
                    })}
                </TagContainer>
            ) : null}

            {props.listing.files && props.listing.files.length > 0 && (
                <React.Fragment>
                    <ActiveMapLegend listing={props.listing} />
                </React.Fragment>
            )}

            <CommentList
                listingId={props.listing.id}
                commentUpdate={false}
                setCommentCount={() => ''}
                allowComment={false}
            />
        </React.Fragment>
    );
};

export default ActiveMapBottomSheet;

const TopContainer = styled.div<{ bottomSheetClosed: boolean }>`
    height: ${(props) => (props.bottomSheetClosed ? '100px' : 'auto')};
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;

const MapDetails = styled.div`
    width: 100%;
`;

const TitleContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: start;
`;

const Title = styled.h1<{ singleLine: boolean }>`
    display: inline-block; /* for inline elements e.g. span, strong, em etc */
    width: calc(100%); /* The trick is here! */
    color: white;
    text-align: left;
    font-size: 22px;
    line-height: 3rem;
    margin: 0 0 4px 12px;

    text-overflow: ellipsis;
    overflow: hidden;
    white-space: ${(props) => (props.singleLine ? 'nowrap' : 'unset')};
    overflow-wrap: ${(props) => (props.singleLine ? 'unset' : 'break-word')};
`;

const CloseButton = styled.button`
    margin: 4px 0 0 8px;
    background: transparent;
    border: none;
    outline: none !important;
    text-align: center;
    pointer-events: all;
    align-items: top;
`;

const CloseIcon = styled.i`
    color: rgba(255, 255, 255, 0.8);
    font-size: 18px;
    margin-right: 9px;
`;

const Author = styled.div`
    display: flex;
    text-overflow: ellipsis;
    width: auto;
    text-align: left;
    font-size: ${(props) => props.theme.fontSize.large} !important;
    color: white;
    margin: 0 0 16px 12px;
    line-height: 21px;
`;

const UserProfileLink = styled(ProfileName)`
    display: flex;
    width: 100%;
    flex: 1;
    margin: 0 !important;
    font-size: ${(props) => props.theme.fontSize.large} !important;

    position: relative;

    a {
        display: block;
        min-width: 0;
        color: #eed926 !important;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-weight: normal;

        text-decoration: underline !important;
        pointer-events: all;

        max-width: calc(
            95% -
                (
                    ${(props) => (props.isCommunityModerator ? '40px' : '0px')} +
                        ${(props) => (props.isReputable ? '40px' : '0px')}
                )
        );

        & ~ sup {
            font-size: ${(props) => props.theme.fontSize.normal};
            line-height: ${(props) => props.theme.fontSize.xlarge};
        }
    }
`;

const Divider = styled.div`
    height: 1px;
    width: 100vw;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
`;

const DescriptionContainer = styled.div`
    margin: 12px 12px 0 12px;
`;

const TagContainer = styled.div`
    flex: 0 1 auto;
    text-align: left;
    display: flex;
    margin: 12px 12px 8px 12px;
    flex-wrap: wrap;
    max-height: auto;
    overflow: visible;
`;

const Tag = styled.div`
    border: 1px solid rgba(255, 255, 255, 0.5);
    border-radius: 6px;
    padding: 3px;
    line-height: 14px;
    margin: 0px 2px 2px 0px;
    color: white;
    font-size: 12px;
    word-break: break-all;
`;

const DateUploaded = styled.div`
    margin: -6px 0 6px 12px;
    color: rgba(255, 255, 255, 0.6);
    font-size: 1rem;
`;
