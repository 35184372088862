import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import { ScrollableContainer } from '../Shared/containers';
import ConfirmModal from '../../Shared/Modal/confirm';
import MobileEditHeader from './Edit/mobile-edit-header';
import MobileEditDetails from './Edit/mobile-edit-details';

import { actionGetMyProfile } from '../../../store/Account/actions';

interface MobileProfileEditProps {
    onEditComplete?: () => void;
    onExit: () => void;
    setAvatarPreview?: (preview: string) => void;
}

const MobileProfileEdit = (props: MobileProfileEditProps) => {
    const dispatch = useDispatch();

    const [confirmExit, setConfirmExit] = useState<boolean>(false);
    const [hasChanges, setHasChanges] = useState<boolean>(false);

    const handleEditSuccess = () => {
        toast.dark('Profile updated');

        dispatch(actionGetMyProfile());

        if (props.onEditComplete) {
            props.onEditComplete();
        }
    };

    const handleEditCancel = () => {
        props.onExit();
    };

    const handleBack = () => {
        if (hasChanges) {
            setConfirmExit(true);
            return;
        }

        props.onExit();
    };

    return (
        <ScrollableContainer>
            <MobileEditHeader onBack={() => handleBack()} />
            <MobileEditDetails
                onSuccess={() => handleEditSuccess()}
                onCancel={() => handleEditCancel()}
                onChange={(withChange) => setHasChanges(withChange)}
                onAvatarUpdate={(preview) => props.setAvatarPreview && props.setAvatarPreview(preview)}
            />
            {confirmExit && (
                <ConfirmModal
                    isOpen={true}
                    title="Unsaved Changes"
                    message={'It seems you have some unsaved changes, do you want to continue?'}
                    onToggle={() => setConfirmExit(false)}
                    onConfirm={() => handleEditCancel()}
                />
            )}
        </ScrollableContainer>
    );
};

export default MobileProfileEdit;
