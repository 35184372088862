import turfDistance from '@turf/distance';
import L from 'leaflet';
import Polyline from '../Polyline/polyline';

export const bearing = (start: L.LatLng, end: L.LatLng) => {
    const rad = Math.PI / 180;
    const lat1 = start.lat * rad;
    const lat2 = end.lat * rad;
    const lon1 = start.lng * rad;
    const lon2 = end.lng * rad;
    const y = Math.sin(lon2 - lon1) * Math.cos(lat2);
    const x = Math.cos(lat1) * Math.sin(lat2) - Math.sin(lat1) * Math.cos(lat2) * Math.cos(lon2 - lon1);

    const bearing = ((Math.atan2(y, x) * 180) / Math.PI + 360) % 360;
    return bearing >= 180 ? bearing - 360 : bearing;
};

const MAX_COUNT_FOR_SHOWING_LINE_SEGMENT_TOTAL_LENGTH = 30;
const MAX_LENGTH_FOR_SHOWING_LINE_SEGMENT_LENGTH = 1200000; // 1,200 km

// Anything larger than 1,200 km will be distorted by geodesic paths and will need #6812 to be usable
export const isLineSegmentShortEnoughToShowMeasurement = (startLatLng: L.LatLng, endLatLng: L.LatLng): boolean => {
    const segmentLength = turfDistance([startLatLng.lng, startLatLng.lat], [endLatLng.lng, endLatLng.lat], {
        units: 'meters',
    });
    return segmentLength < MAX_LENGTH_FOR_SHOWING_LINE_SEGMENT_LENGTH;
};

export const shouldAllowLineSegmentLength = (
    polyline: Polyline,
    maxLength = MAX_LENGTH_FOR_SHOWING_LINE_SEGMENT_LENGTH
): boolean => {
    let show = true;
    polyline.positions.forEach((position, index) => {
        if (index === 0) return;
        const previousPosition = polyline.positions[index - 1];
        const segmentLength = turfDistance([previousPosition.lng, previousPosition.lat], [position.lng, position.lat], {
            units: 'meters',
        });
        if (segmentLength > maxLength) {
            show = false;
        }
    });
    return show;
};

export const shouldAllowLineSegmentTotalLength = (
    polyline: Polyline,
    maxSegmentCount = MAX_COUNT_FOR_SHOWING_LINE_SEGMENT_TOTAL_LENGTH
): boolean => {
    return polyline.positions.length <= maxSegmentCount;
};

export const formatMetricLengthString = (distanceInMeters: number): string => {
    if (distanceInMeters > 4000) {
        return `${(distanceInMeters / 1000).toLocaleString(undefined, { maximumFractionDigits: 2 })}km`;
    } else {
        return `${distanceInMeters.toLocaleString(undefined, { maximumFractionDigits: 2 })}m`;
    }
};

export const formatImperialLengthString = (distanceInFeet: number): string => {
    if (distanceInFeet < 5280) {
        return `${distanceInFeet.toLocaleString(undefined, { maximumFractionDigits: 2 })}ft`;
    } else {
        return `${(distanceInFeet / 5280).toLocaleString(undefined, { maximumFractionDigits: 2 })}mi`;
    }
};

export const formatMetricAreaString = (areaInSquareMeters: number): string => {
    if (areaInSquareMeters > 1000000) {
        return `${(areaInSquareMeters / 1000000).toLocaleString(undefined, { maximumFractionDigits: 2 })}km²`;
    } else {
        return `${areaInSquareMeters.toLocaleString(undefined, { maximumFractionDigits: 2 })}m²`;
    }
};

export const formatImperialAreaString = (areaInSquareFeet: number): string => {
    if (areaInSquareFeet < 27878400) {
        return `${areaInSquareFeet.toLocaleString(undefined, { maximumFractionDigits: 2 })}ft²`;
    } else {
        return `${(areaInSquareFeet / 27878400).toLocaleString(undefined, { maximumFractionDigits: 2 })}mi²`;
    }
};
