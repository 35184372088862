import { useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { Collapse, Nav, Navbar, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import ProfileName from '../../Drawer/SideDrawer/ProfileDrawer/Profile/profile-name';

import { UserDTO } from '../../../api/model';
import SignOutModal from '../../Shared/Modal/sign-out';
import { actionLogoutUser } from '../../../store/Account/actions';
import Constants from '../../../constants';
import Analytics from '../../../lib/user-analytics';

interface MobileProfileHeaderProps {
    profile: UserDTO;
    onShare: () => void;
    onEditProfile: () => void;
    onEditAccount: () => void;
}

const MobileProfileHeader = (props: MobileProfileHeaderProps) => {
    const dispatch = useDispatch();

    const [menuIsOpen, setMenuIsOpen] = useState(false);
    const [confirmSignOut, setConfirmSignOut] = useState(false);

    const handleShareProfile = () => {
        props.onShare();
        setMenuIsOpen(false);
    };
    const handleEditProfile = () => {
        props.onEditProfile();
        setMenuIsOpen(false);
    };
    const handleEditAccount = () => {
        props.onEditAccount();
        setMenuIsOpen(false);
    };
    const handleSignOut = () => {
        setConfirmSignOut(true);
        setMenuIsOpen(false);
    };

    const handleDocsLink = (doc: string) => {
        Analytics.Event('Mobile Profile', `Clicked ${doc === 'terms' ? 'Terms of Use' : 'Privacy Policy'}`);
    };

    const signOutUser = () => {
        dispatch(actionLogoutUser());
        window.location.href = `${window.location.protocol}//${window.location.host}`;
    };

    return (
        <ProfileHeader dark>
            <ProfileHeaderName
                isCommunityModerator={props.profile?.communityModerator ?? false}
                isReputable={props.profile?.reputable ?? false}
            >
                {props.profile?.name || 'Soar User'}
            </ProfileHeaderName>
            <ProfileMenuToggle onClick={() => setMenuIsOpen(!menuIsOpen)} />
            <ProfileMenuContainer isOpen={menuIsOpen} navbar>
                <ProfileMenuNav navbar>
                    <ProfileMenuNavItem>
                        <ProfileMenuNavLink onClick={handleShareProfile}>Share Profile</ProfileMenuNavLink>
                    </ProfileMenuNavItem>
                    <ProfileMenuNavItem>
                        <ProfileMenuNavLink onClick={handleEditProfile}>Edit Profile</ProfileMenuNavLink>
                    </ProfileMenuNavItem>
                    <ProfileMenuNavItem>
                        <ProfileMenuNavLink onClick={handleEditAccount}>Account Settings</ProfileMenuNavLink>
                    </ProfileMenuNavItem>
                    <ProfileMenuNavItem className="signOut">
                        <ProfileMenuNavLink onClick={handleSignOut}>Sign out</ProfileMenuNavLink>
                    </ProfileMenuNavItem>
                    <ProfileMenuNavItem className="terms">
                        <ProfileMenuNavLink
                            href={Constants.DOCS.TERMS_OF_USE}
                            target="soar-tc"
                            onClick={() => handleDocsLink('terms')}
                        >
                            Terms of Use
                        </ProfileMenuNavLink>
                        <ProfileMenuNavLink
                            href={Constants.DOCS.PRIVACY_POLICY}
                            target="soar-priv"
                            onClick={() => handleDocsLink('privacy')}
                        >
                            Privacy Policy
                        </ProfileMenuNavLink>
                    </ProfileMenuNavItem>
                </ProfileMenuNav>
            </ProfileMenuContainer>
            <SignOutModal
                isOpen={confirmSignOut}
                onToggle={() => setConfirmSignOut(!confirmSignOut)}
                onSignOut={() => signOutUser()}
                marginTop="100px"
            />
        </ProfileHeader>
    );
};

export default MobileProfileHeader;

const ProfileHeader = styled(Navbar)`
    background-color: ${(props) => props.theme.color.black};
    border-bottom: 1px solid ${(props) => props.theme.color.lightGray};

    position: fixed;
    width: 100%;
    height: 60px;
    z-index: ${(props) => props.theme.zIndex.nav};
`;

const ProfileHeaderName = styled(ProfileName)`
    color: ${(props) => props.theme.color.white} !important;
    font-size: ${(props) => props.theme.fontSize.large};
    font-weight: 700;

    flex: 1 0;
    max-width: calc(100% - 60px);

    display: flex;
    justify-content: flex-start;

    span {
        text-decoration: none !important;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
`;

const ProfileMenuToggle = styled(NavbarToggler)`
    color: ${(props) => props.theme.color.white} !important;
`;

const ProfileMenuContainer = styled(Collapse)`
    position: absolute;
    top: 60px;
    right: 0;
    z-index: ${(props) => props.theme.zIndex.nav};

    width: 50%;
    background-color: rgb(34, 34, 34);

    @media screen and (max-width: 320px) {
        width: 75%;
    }
`;

const ProfileMenuNav = styled(Nav)`
    padding: 0.5rem 1rem;
`;

const ProfileMenuNavLink = styled(NavLink)`
    color: ${(props) => props.theme.color.white} !important;
    padding: 0.75rem 0;

    user-select: none;

    &:disabled,
    &[disabled] {
        color: ${(props) => props.theme.color.lightGray} !important;
        text-decoration: line-through !important;
    }
`;

const ProfileMenuNavItem = styled(NavItem)`
    &.terms {
        display: flex;
        flex-direction: column;
        align-items: center;

        font-size: ${(props) => props.theme.fontSize.normal};
        text-align: center;

        ${ProfileMenuNavLink} {
            color: ${(props) => props.theme.color.yellow} !important;
            padding-left: 0.5rem;
            padding-right: 0.5rem;
        }
    }
    &.terms,
    &.signOut {
        border-top: 1px solid ${(props) => props.theme.color.lightGray};
    }
`;
