import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

interface CollapsibleTextProps {
    children: React.ReactNode;
    maxHeight?: number;
    toggleTextMore?: string;
    toggleTextLess?: string;
    className?: string;
}

const DEFAULT_TEXT_HEIGHT = 80;

const CollapsibleText = (props: CollapsibleTextProps) => {
    const [showLongText, setShowLongText] = useState(true);
    const [showLongTextToggle, setShowLongTextToggle] = useState(false);

    const breakpointHeight = props.maxHeight ?? DEFAULT_TEXT_HEIGHT;

    const shouldBeCollapsible = (scrollHeight: number) => {
        const CollapseThreshhold = 21;
        if (scrollHeight > breakpointHeight) {
            const wholeNum = Math.floor(scrollHeight / breakpointHeight);
            const remainder = scrollHeight % breakpointHeight;

            if (wholeNum > 1) {
                return true;
            }

            if (remainder >= CollapseThreshhold) {
                return true;
            }
        }

        return false;
    };

    useEffect(() => {
        // Check if the profile description is too long to display
        const collapsibleText = document?.querySelector('.collapsible-text');

        if (collapsibleText && shouldBeCollapsible(collapsibleText.scrollHeight)) {
            setShowLongText(false);
            setShowLongTextToggle(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <CollapsibleTextContainer className={props.className}>
            <CollapsibleTextContent
                key={`collapsible-text${showLongText ? '-open' : '-closed'}`}
                className={`collapsible-text ${showLongText ? 'open' : 'closed'}`}
                showAll={showLongText}
            >
                {props.children}
            </CollapsibleTextContent>
            {showLongTextToggle && (
                <CollapsibleTextToggle onClick={() => setShowLongText(!showLongText)}>
                    {showLongText ? props.toggleTextLess ?? 'Read less' : props.toggleTextMore ?? 'Read more'}
                </CollapsibleTextToggle>
            )}
        </CollapsibleTextContainer>
    );
};

export default CollapsibleText;

const CollapsibleTextContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

const CollapsibleTextContent = styled.div<{ showAll: boolean }>`
    margin-bottom: 8px;
    overflow: hidden;
    text-overflow: ellipsis;

    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: ${(props) => (props.showAll ? 'none' : 3)};
    line-clamp: ${(props) => (props.showAll ? 'none' : 3)};

    // old browsers workaround for line-clamp
    @supports not (line-clamp) {
        max-height: ${(props) => (props.showAll ? 'fit-content' : `calc(1.5rem * 3)`)};
        padding-right: ${(props) => (props.showAll ? 0 : `1rem`)};
        position: relative;
        text-overflow: clip;
    }

    * {
        color: ${(props) => props.theme.color.white};
        font-size: ${(props) => props.theme.fontSize.normal};
        line-height: ${(props) => props.theme.lineHeight.normal};
    }

    p {
        margin-bottom: ${(props) => props.theme.lineHeight.normal};
    }

    p:last-child {
        margin-bottom: 0;
    }
`;

const CollapsibleTextToggle = styled.button`
    background: transparent;
    border: none;

    padding: 8px 16px;
    color: ${(props) => props.theme.color.white};
    font-weight: 700;

    width: auto;
    align-self: flex-end;

    &:hover,
    &:focus {
        outline: none;
    }
`;
