import { useState } from 'react';
import { useDispatch } from 'react-redux';
import Analytics from '../../lib/user-analytics';
import SoarModal from '../Shared/soar-modal';
import ForgotPassword from './forgot-password';
import Login from './login';
import { LoginModalMode } from './login-enum';
import { PasswordReset } from './password-reset';
import Register from './register';
import RegisterConfirmation from './register-confirmation';
import { actionShowLoginDialog } from '../../store/App/actions';

interface LoginRegisterDialogProps {
    isOpen: boolean;
    initialMode?: LoginModalMode;
    onClose?: (isLoggedIn?: boolean) => void;
    onRegisterSuccess?: () => void;
    onLoginSuccess?: () => void;
    disabledClose?: boolean;
}

const LoginRegisterDialog = (props: LoginRegisterDialogProps) => {
    const [mode, setMode] = useState<LoginModalMode | undefined>(props.initialMode);
    const [email, setEmail] = useState<string>('');

    const dispatch = useDispatch();

    const handleClickLogin = () => {
        Analytics.Event('Registration', 'Clicked Sign in');
        setMode(LoginModalMode.LOGIN);
    };

    const handleClickRegister = () => {
        Analytics.Event('Registration', 'Clicked Register');
        setMode(LoginModalMode.REGISTER);
    };

    const handleClickForgotPassword = () => {
        Analytics.Event('Registration', 'Clicked Forgot Password');
        setMode(LoginModalMode.FORGOT_PASSWORD);
    };

    const handleChangeMode = (newMode: LoginModalMode) => {
        if (newMode === LoginModalMode.LOGIN) {
            dispatch(actionShowLoginDialog(true));
            onClose();
        } else {
            setMode(newMode);
        }
    };

    const onClose = (isLoggedIn?: boolean) => {
        if (props.onClose) {
            setMode(props.initialMode);
            props.onClose(isLoggedIn);
        }
    };

    if (mode === LoginModalMode.REGISTER_SUCCESS) {
        return <RegisterConfirmation toggle={onClose} />;
    }

    return (
        <SoarModal
            title={generateDialogTitle(mode)}
            width={'400px !important'}
            isOpen={props.isOpen}
            toggle={() => onClose()}
            disabledClose={props.disabledClose ?? false}
        >
            {mode === LoginModalMode.LOGIN ? (
                <Login
                    email={email}
                    onEmailChange={setEmail}
                    onClose={() => onClose(true)}
                    handleClickForgotPassword={() => handleClickForgotPassword()}
                    handleClickRegister={() => handleClickRegister()}
                    onLoginSuccess={() => {
                        if (props.onLoginSuccess) {
                            props.onLoginSuccess();
                        }
                    }}
                />
            ) : null}
            {mode === LoginModalMode.REGISTER ? (
                <Register
                    email={email}
                    onEmailChange={setEmail}
                    onClose={onClose}
                    handleClickLogin={() => handleClickLogin()}
                    onRegisterSuccess={() => {
                        // Stops the welcome modal displaying, where the user is already using a feature
                        // where the flow should not be interupted
                        if (props.onRegisterSuccess) {
                            onClose();
                            props.onRegisterSuccess();
                        } else {
                            setMode(LoginModalMode.REGISTER_SUCCESS);
                        }
                    }}
                />
            ) : null}
            {mode === LoginModalMode.FORGOT_PASSWORD ? (
                <ForgotPassword
                    email={email}
                    onEmailChange={setEmail}
                    onClose={onClose}
                    handleClickLogin={() => handleClickLogin()}
                />
            ) : null}
            {mode === LoginModalMode.PASSWORD_RESET ? (
                <PasswordReset onClose={() => handleChangeMode(LoginModalMode.LOGIN)} />
            ) : null}
        </SoarModal>
    );
};

export default LoginRegisterDialog;

const generateDialogTitle = (mode?: LoginModalMode): string => {
    switch (mode) {
        case LoginModalMode.LOGIN:
            return 'Sign in';
        case LoginModalMode.REGISTER:
            return 'Create your free account';
        case LoginModalMode.FORGOT_PASSWORD:
            return 'Forgot password';
        case LoginModalMode.PASSWORD_RESET:
            return 'Reset password';
        default:
            return 'Login / Register Dialog';
    }
};
