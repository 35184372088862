import { LeafletContextInterface, createElementObject } from '@react-leaflet/core';
import turfDistance from '@turf/distance';
import L from 'leaflet';
import { bearing, formatImperialLengthString, formatMetricLengthString } from './measurement-util';
import { MeasurementLabelColor } from './measurement-toolbar-label-color';
import { MeasurementBackgroundColor } from './measurement-toolbar-background-color';

const lengthSVGLabel = (
    text: string,
    labelColor: MeasurementLabelColor,
    labelBgColor: MeasurementBackgroundColor,
    angle: number
): SVGSVGElement => {
    const backgroundColor = 'transparent';
    const textColor = labelColor;
    const strokeColor = labelColor === 'black' ? 'white' : 'black';
    const fontSize = `1.6rem`;

    const svg = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
    svg.setAttribute('xmlns', 'http://www.w3.org/2000/svg');
    svg.setAttribute('fill', backgroundColor);
    svg.style.backgroundColor = backgroundColor;

    const textElement = document.createElementNS('http://www.w3.org/2000/svg', 'text');
    textElement.setAttribute('x', '50%');
    textElement.setAttribute('y', '50%');
    textElement.setAttribute('fill', textColor);
    textElement.setAttribute('font-size', fontSize);
    textElement.setAttribute('font-family', 'Manrope');
    textElement.setAttribute('font-weight', 'bold');
    textElement.setAttribute('text-anchor', 'middle');
    textElement.setAttribute('dominant-baseline', 'text-after-edge');
    textElement.setAttribute('stroke', strokeColor);
    textElement.setAttribute('stroke-width', '0.25px');
    textElement.setAttribute('transform-origin', `50% 50%`);

    textElement.textContent = text;
    textElement.style.transform = `rotate(${angle}deg)`;
    svg.appendChild(textElement);

    const textBgElement = document.createElementNS('http://www.w3.org/2000/svg', 'rect');
    textBgElement.setAttribute('x', '0');
    textBgElement.setAttribute('y', '0');
    textBgElement.setAttribute('rx', '10');
    textBgElement.setAttribute('width', '0');
    textBgElement.setAttribute('height', '0');
    textBgElement.setAttribute('fill', labelBgColor);
    textBgElement.setAttribute('transform-origin', `50% 50%`);
    textBgElement.style.transform = `rotate(${angle}deg)`;

    svg.insertBefore(textBgElement, textElement);

    return svg;
};

export const createLengthLabel = (
    startPosition: L.LatLng,
    endPosition: L.LatLng,
    allPositions: L.LatLng[],
    units: 'imperial' | 'metric',
    labelColor: MeasurementLabelColor,
    labelBgColor: MeasurementBackgroundColor,
    paneId: string,
    context: LeafletContextInterface
) => {
    const center = new L.LatLngBounds([
        [startPosition.lat, startPosition.lng],
        [endPosition.lat, endPosition.lng],
    ]).getCenter();

    // The angle using Great Circle bearing
    const angle = bearing(startPosition, endPosition) - 90;

    // The angle using Pythagoras
    //const dY = endPosition.lat - startPosition.lat;
    //const dX = endPosition.lng - startPosition.lng;
    //const angle = -1 * Math.atan2(dY, dX) * (180 / Math.PI);

    // Correct the cardinality so the label is always facing upwards
    const displayAngle = angle > 90 || angle < -90 ? angle + 180 : angle;

    let distanceString: string;
    const distanceInMeters = turfDistance([startPosition.lng, startPosition.lat], [endPosition.lng, endPosition.lat], {
        units: 'meters',
    });

    if (units === 'imperial') {
        const distanceInFeet = distanceInMeters * 3.28;
        distanceString = formatImperialLengthString(distanceInFeet);
    } else {
        distanceString = formatMetricLengthString(distanceInMeters);
    }

    const bounds = center.toBounds(distanceInMeters);
    const svg = lengthSVGLabel(distanceString, labelColor, labelBgColor, displayAngle);

    const svgElement = createElementObject<L.SVGOverlay, L.SVGOverlayStyleOptions>(
        new L.SVGOverlay(svg, bounds, { interactive: false, pane: paneId, className: 'leaflet-svg-area-label' }),
        context
    );

    const updateLabelVisibility = () => {
        const textLength = (svg.childNodes[1] as SVGTextElement).getComputedTextLength();

        const startScreenPosition = context.map.latLngToLayerPoint(startPosition);
        const endScreenPosition = context.map.latLngToLayerPoint(endPosition);
        const screenDistancePadding = 10; //px
        const screenDistance = startScreenPosition.distanceTo(endScreenPosition) - screenDistancePadding;

        const svgTextNode = svgElement.instance.getElement()?.childNodes[1] as SVGTextElement;
        if (textLength > 0 && textLength > screenDistance) {
            svgTextNode.setAttribute('opacity', '0');
            svgElement.instance.fireEvent('hide');
        } else {
            svgTextNode.setAttribute('opacity', '1');
            svgElement.instance.fireEvent('show');
        }

        const svgTextNodeBox = svgTextNode.getBBox();
        const svgTextBgNode = svgElement.instance.getElement()?.childNodes[0] as SVGRectElement;
        if (textLength > 0 && textLength > screenDistance) {
            svgTextBgNode.setAttribute('width', '0');
            svgTextBgNode.setAttribute('height', '0');
        } else {
            svgTextBgNode.setAttribute('x', `${svgTextNodeBox.x - 5}`);
            svgTextBgNode.setAttribute('y', `${svgTextNodeBox.y - 5}`);
            svgTextBgNode.setAttribute('width', `${svgTextNodeBox.width + 10}`);
            svgTextBgNode.setAttribute('height', `${svgTextNodeBox.height + 10}`);
        }
    };

    const onZoomEnd = () => {
        updateLabelVisibility();
    };

    svgElement.instance.on('add', () => {
        updateLabelVisibility();
        context.map.on('zoomend', onZoomEnd);
    });

    svgElement.instance.on('remove', () => {
        context.map.off('zoomend', onZoomEnd);
    });

    return svgElement;
};

export const createTotalLengthLabel = async (
    allPositions: L.LatLng[],
    units: 'imperial' | 'metric',
    labelColor: MeasurementLabelColor,
    labelBgColor: MeasurementBackgroundColor,
    paneId: string,
    context: LeafletContextInterface
) => {
    const positions = [...allPositions];
    let longestSegment: L.LatLng[] = [];
    let longestDistance = 0;
    let totalDistanceInMeters = 0;
    let previousPosition = positions[0];

    await Promise.all(
        positions.map((position, index) => {
            if (index === 0) return;

            const distanceInMeters = turfDistance(
                [previousPosition.lng, previousPosition.lat],
                [position.lng, position.lat],
                {
                    units: 'meters',
                }
            );

            if (longestDistance < distanceInMeters) {
                longestDistance = distanceInMeters;
                longestSegment = [previousPosition, position];
            }
            totalDistanceInMeters += distanceInMeters;

            previousPosition = position;
        })
    );

    // Center of the longest segment
    const center = new L.LatLngBounds([
        [longestSegment[0].lat, longestSegment[0].lng],
        [longestSegment[1].lat, longestSegment[1].lng],
    ]).getCenter();

    // The angle using Great Circle bearing
    const angle = bearing(longestSegment[0], longestSegment[1]) - 90;

    // Correct the cardinality so the label is always facing upwards
    const displayAngle = angle > 90 || angle < -90 ? angle + 180 : angle;

    let distanceString: string;
    if (units === 'imperial') {
        const distanceInFeet = totalDistanceInMeters * 3.28;
        distanceString = formatImperialLengthString(distanceInFeet);
    } else {
        distanceString = formatMetricLengthString(totalDistanceInMeters);
    }

    const displayDistanceString = `Total Length: ${distanceString}`;

    const bounds = center.toBounds(totalDistanceInMeters);
    const svg = lengthSVGLabel(displayDistanceString, labelColor, labelBgColor, displayAngle);

    const svgElement = createElementObject<L.SVGOverlay, L.SVGOverlayStyleOptions>(
        new L.SVGOverlay(svg, bounds, { interactive: false, pane: paneId, className: 'leaflet-svg-area-label' }),
        context
    );

    const updateLabel = () => {
        const textLength = (svg.childNodes[1] as SVGTextElement).getComputedTextLength();
        const startScreenPosition = context.map.latLngToLayerPoint(longestSegment[0]);
        const endScreenPosition = context.map.latLngToLayerPoint(longestSegment[1]);
        const screenDistancePadding = 10; //px
        const screenDistance = startScreenPosition.distanceTo(endScreenPosition) - screenDistancePadding;

        const svgTextNode = svgElement.instance.getElement()?.childNodes[1] as SVGTextElement;
        if (textLength > 0 && textLength > screenDistance) {
            svgTextNode.setAttribute('opacity', '0');
            svgElement.instance.fireEvent('hide');
        } else {
            svgTextNode.setAttribute('opacity', '1');
            svgElement.instance.fireEvent('show');
        }

        const svgTextNodeBox = svgTextNode.getBBox();
        const svgTextBgNode = svgElement.instance.getElement()?.childNodes[0] as SVGRectElement;
        if (textLength > 0 && textLength > screenDistance) {
            svgTextBgNode.setAttribute('width', '0');
            svgTextBgNode.setAttribute('height', '0');
        } else {
            svgTextBgNode.setAttribute('x', `${svgTextNodeBox.x - 5}`);
            svgTextBgNode.setAttribute('y', `${svgTextNodeBox.y - 5}`);
            svgTextBgNode.setAttribute('width', `${svgTextNodeBox.width + 10}`);
            svgTextBgNode.setAttribute('height', `${svgTextNodeBox.height + 10}`);
        }
    };

    const onZoomEnd = () => {
        updateLabel();
    };

    svgElement.instance.on('add', () => {
        updateLabel();
        context.map.on('zoomend', onZoomEnd);
    });

    svgElement.instance.on('remove', () => {
        context.map.off('zoomend', onZoomEnd);
    });

    return svgElement;
};
