import L from 'leaflet';

import {
    LeafletContextInterface,
    LeafletElement,
    PathProps,
    createElementHook,
    createElementObject,
    extendContext,
    useLayerLifecycle,
    useLeafletContext,
} from '@react-leaflet/core';

import { selectedPolygonOutlineOptions } from '../Polygon/polygon';
import { nodeMarkerOptions } from '../Polygon/node-marker-handles';
import LayersUtil from '../layers-util';
import { polylineToPaddedPolygon } from '../use-select-annotation-utils';
import Polyline from './polyline';
import { shouldAllowLineSegmentLength } from '../Measurement/measurement-util';
import { createPolylineDragElement } from './polyline-drag-element';
import { createLengthLabel, createTotalLengthLabel } from '../Measurement/length-label';

interface PolylineAnnotationProps extends L.PathOptions {
    isSelected: boolean;
    polyline: Polyline;
    onUpdatePolyline?: (polyline: Polyline) => void;
    children?: React.ReactNode;
    isDisabled?: boolean;
}

const createNodeMarkers = (
    polyline: L.Polyline<GeoJSON.LineString | GeoJSON.MultiLineString>,
    pane: string,
    context: LeafletContextInterface
): LeafletElement<L.Marker, LeafletContextInterface>[] => {
    const nodeMarkers = (polyline.getLatLngs() as L.LatLng[]).map((position, index) => {
        const marker = new L.Marker(position, { ...nodeMarkerOptions, pane: pane });
        const markerElement = createElementObject<L.Marker>(marker, context);

        markerElement.instance.on('add', () => {
            markerElement.instance.on('dragstart', () => {
                polyline.fire('node-drag-start');
            });

            markerElement.instance.on('dragend', () => {
                polyline.fire('node-drag-end');
            });

            markerElement.instance.on('drag', (e: L.LeafletMouseEvent) => {
                polyline.setLatLngs(polyline.getLatLngs().map((latLng, i) => (i === index ? e.latlng : latLng)));
                polyline.fire('update');
            });
        });

        context.map.addLayer(markerElement.instance);
        return markerElement;
    });

    return nodeMarkers;
};

const createPolylineAnnotation = (props: PolylineAnnotationProps, context: LeafletContextInterface) => {
    const paneId = LayersUtil.getPaneId(context.map, props.polyline);
    const polyline = new L.Polyline(props.polyline.positions, { ...props.polyline.options, pane: paneId });
    const polylineElement = createElementObject<L.Polyline, PathProps>(
        polyline,
        extendContext(context, { overlayContainer: polyline })
    );

    let nodeMarkers: LeafletElement<L.Marker, LeafletContextInterface>[] = [];

    const selectedOutlinePaneId = LayersUtil.getSelectedOutlinePaneId(context.map);
    const polylineBounds = new L.Polygon(polylineToPaddedPolygon(context.map, props.polyline.positions), {
        ...selectedPolygonOutlineOptions,
        pane: selectedOutlinePaneId,
    });
    const polylineBoundsElement = createElementObject<L.Polygon, PathProps>(polylineBounds, context);

    const polylineDragElement = createPolylineDragElement(
        { polylineElement: polylineElement, positions: props.polyline.positions, isSelected: props.isSelected },
        context
    );

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let polylineMeasurementLabels: any[] = [];

    const addPathMeasurementLabels = () => {
        if (shouldAllowLineSegmentLength(props.polyline)) {
            if (props.polyline.showLength === 'sections') {
                (polylineElement.instance.getLatLngs() as L.LatLng[]).forEach((latLng, index) => {
                    if (index < (polylineElement.instance.getLatLngs() as L.LatLng[]).length - 1) {
                        const nextLatLng = (polylineElement.instance.getLatLngs() as L.LatLng[])[index + 1];
                        const label = createLengthLabel(
                            latLng,
                            nextLatLng,
                            polylineElement.instance.getLatLngs() as L.LatLng[],
                            props.polyline.units,
                            props.polyline.labelColor,
                            props.polyline.labelBgColor,
                            paneId,
                            context
                        );
                        polylineMeasurementLabels.push(label);
                        context.map.addLayer(label.instance);
                    }
                });
            } else if (props.polyline.showLength === 'total') {
                createTotalLengthLabel(
                    polylineElement.instance.getLatLngs() as L.LatLng[],
                    props.polyline.units,
                    props.polyline.labelColor,
                    props.polyline.labelBgColor,
                    paneId,
                    context
                ).then((label) => {
                    polylineMeasurementLabels.push(label);
                    context.map.addLayer(label.instance);
                });
            }
        } else {
            removePathMeasurementLabels();
            if (props.onUpdatePolyline) {
                props.onUpdatePolyline({
                    ...props.polyline,
                    positions: polylineElement.instance.getLatLngs() as L.LatLng[],
                    showLength: false,
                });
            }
        }
    };

    const removePathMeasurementLabels = () => {
        polylineMeasurementLabels.forEach((label) => {
            context.map.removeLayer(label.instance);
        });
        polylineMeasurementLabels = [];
    };

    const addNodeMarkers = () => {
        nodeMarkers.forEach((nodeMarker) => {
            context.map.removeLayer(nodeMarker.instance);
        });
        nodeMarkers = [];
        const controlPaneId = LayersUtil.getControlPaneId(context.map);
        nodeMarkers = createNodeMarkers(polylineElement.instance, controlPaneId, context);
        nodeMarkers.forEach((nodeMarker) => {
            context.map.addLayer(nodeMarker.instance);
        });
    };

    const removeNodeMarkers = () => {
        nodeMarkers.forEach((nodeMarker) => {
            context.map.removeLayer(nodeMarker.instance);
        });
        nodeMarkers = [];
    };

    polylineElement.instance.on('add', () => {
        if (props.isSelected) {
            addNodeMarkers();
            context.map.addLayer(polylineDragElement.instance);
            context.map.addLayer(polylineBoundsElement.instance);
        } else {
            removeNodeMarkers();
            context.map.removeLayer(polylineDragElement.instance);
            context.map.removeLayer(polylineBoundsElement.instance);
        }

        if (props.polyline.showLength) {
            addPathMeasurementLabels();
        } else {
            removePathMeasurementLabels();
        }
    });

    polylineElement.instance.on('remove', () => {
        context.map.removeLayer(polylineBoundsElement.instance);
        context.map.removeLayer(polylineDragElement.instance);
        removePathMeasurementLabels();

        nodeMarkers.forEach((nodeMarker) => {
            context.map.removeLayer(nodeMarker.instance);
        });
        nodeMarkers = [];
    });

    polylineElement.instance.on('polyline-drag-start', () => {
        removePathMeasurementLabels();
        context.map.removeLayer(polylineBoundsElement.instance);
    });

    polylineElement.instance.on('polyline-drag-end', () => {
        polylineBoundsElement.instance.setLatLngs(
            polylineToPaddedPolygon(context.map, polylineElement.instance.getLatLngs() as L.LatLng[])
        );
        context.map.addLayer(polylineBoundsElement.instance);
        addPathMeasurementLabels();
    });

    polylineElement.instance.on('node-drag-start', () => {
        removePathMeasurementLabels();
    });

    polylineElement.instance.on('node-drag-end', () => {
        addPathMeasurementLabels();
    });

    polylineElement.instance.on('update', () => {
        polylineBoundsElement.instance.setLatLngs(
            polylineToPaddedPolygon(context.map, polylineElement.instance.getLatLngs() as L.LatLng[])
        );
        const updatedPath = {
            ...props.polyline,
            positions: polylineElement.instance.getLatLngs() as L.LatLng[],
        };
        props.onUpdatePolyline && props.onUpdatePolyline(updatedPath);
    });

    polylineElement.instance.on('update-node-markers', () => {
        addNodeMarkers();
    });

    // The interactive option adds or removes the leaflet-interactive class which changes the cursor
    // This means we have a third state of interactivity that needs to be handled when the annotation is disabled
    // otherwise hovering when editing will not show the pointer cursor
    if (props.isDisabled) {
        polylineElement.instance.options.interactive = false;
    } else {
        polylineElement.instance.options.interactive = true;
    }

    return polylineElement;
};

const updatePathAnnotation = (instance: L.Polyline, props: PolylineAnnotationProps, _: PolylineAnnotationProps) => {
    instance.setStyle({ ...instance.options, ...props.polyline.options });
};

const usePathAnnotation = createElementHook<L.Polyline, PolylineAnnotationProps, LeafletContextInterface>(
    createPolylineAnnotation,
    updatePathAnnotation
);

const PathAnnotation = (props: PolylineAnnotationProps) => {
    const context = useLeafletContext();
    const polygon = usePathAnnotation(props, context);
    useLayerLifecycle(polygon.current, context);
    return null;
};

export default PathAnnotation;
