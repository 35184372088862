import styled from 'styled-components';
import Analytics from '../../../lib/user-analytics';
import { useBasemap } from '../../../store/Map/Basemap/use-basemap';
import { Basemap } from '../../../store/Map/Basemap/model';

interface MobileBasemapOptionsProps {
    onOptionSelected: () => void;
}

const MobileBasemapOptions = (props: MobileBasemapOptionsProps) => {
    const { basemap, setBasemap } = useBasemap();

    const handleSelectBaseMap = (bm: Basemap) => {
        Analytics.Event('Basemap', 'Changed basemap', bm);
        Analytics.Event('Mobile Basemap Options', 'Selected Basemap', bm);
        setBasemap(bm);
        props.onOptionSelected();
    };

    return (
        <MobileShareContainer role="dialog" aria-modal="true" data-testid="mobile-basemap-menu">
            <MobileBasemapContainerTitle>Choose Basemap</MobileBasemapContainerTitle>
            <MenuList>
                {Object.keys(Basemap).map((bm) => (
                    <MenuListItem
                        data-testid="mobile-basemap-menu-item"
                        className={basemap === Basemap[bm] ? 'selected-base-map' : ''}
                        onClick={() => handleSelectBaseMap(Basemap[bm])}
                    >
                        {Basemap[bm]}
                    </MenuListItem>
                ))}
            </MenuList>
        </MobileShareContainer>
    );
};

export default MobileBasemapOptions;

const MobileShareContainer = styled.section`
    width: 100%;
    border-radius: 6px;
`;

const MobileBasemapContainerTitle = styled.div`
    color: rgba(255, 255, 255, 0.87);
    padding: 10px 5px;
    margin-left: 6px;
    font-size: 1.25rem;
    font-weight: 500;
    text-align: center;
    font-weight: bold;
    overflow: hidden;
    white-space: nowrap;
`;

const MenuList = styled.ul`
    list-style: none;
    padding: 0;
    border-top: 1px solid rgba(255, 255, 255, 0.4);
    margin-bottom: 0px;
`;

const MenuListItem = styled.li`
    border-bottom: 1px solid rgba(255, 255, 255, 0.4);
    width: 100vw;
    color: rgba(255, 255, 255, 0.87);
    padding: 10px 5px;
    font-size: 16px;
    padding-left: 12px;

    &.selected-base-map {
        background-color: #eed926;
        color: rgba(0, 0, 0, 0.87);
    }

    &.last-basemap-item {
        border-bottom: none;
    }
`;
