import { toast } from 'react-toastify';

export const shareLink = async (link: string, title?: string, message?: string, copySuccessMsg?: string) => {
    if (window.navigator.share) {
        await window.navigator.share({
            title: title ?? 'Share your link',
            text: message ?? 'Check out my link from Soar.Earth!',
            url: link,
        });
    } else {
        if (window.navigator.clipboard) {
            await window.navigator.clipboard.writeText(link);
        } else {
            const textField = document.createElement('textarea');
            textField.innerText = link;
            document.body.appendChild(textField);
            textField.select();
            document.execCommand('copy');
            textField.remove();
        }
        toast.dark(copySuccessMsg ?? 'Copied to clipboard');
    }
};
