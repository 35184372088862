import React from 'react';
import styled, { keyframes } from 'styled-components';

interface MobileSatelliteLoadingProps {
    customMessage?: string;
}

const MobileSatelliteLoading = (props: MobileSatelliteLoadingProps) => {
    return (
        <React.Fragment>
            <LoadingText>{props.customMessage ? props.customMessage : 'Waiting for Satellite Images ...'}</LoadingText>
            <LoadingSatelliteIcon src="/assets/floating-drawer-icons/icon-UI-satellite-white.png" />
            <LoadingWave src="/assets/floating-drawer-loading-icons/loading-wave.svg" />
        </React.Fragment>
    );
};

export default MobileSatelliteLoading;

const LoadingText = styled.p`
    margin-top: 20px;
    color: white;
    padding: 0;
    text-align: center;
`;

const LoadingSatelliteIcon = styled.img`
    margin: 10px calc(50vw + 20px);
`;

const WaveAnimation = keyframes`
    0% {
        transform: translate(0, 0) scale(0.3) rotate(135deg);
        opacity: 0.6;
    }
    90% {
        transform: translate(-55px, 50px) scale(0.7) rotate(135deg);
        opacity: 0.4;
    }
    100% {
        transform: translate(-60px, 60px) scale(0.3) rotate(135deg);
        opacity: 0.2;
    }
`;

const LoadingWave = styled.img`
    transform: rotate(135deg) scale(0.3);
    animation: ${WaveAnimation} 1.2s infinite linear;
    position: absolute;
    left: 47%;
    top: 90px;
    transform-origin: center;
`;
