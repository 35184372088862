import styled from 'styled-components';
import { ContentHeader } from '../../Shared/containers';
import { MobileButtonBack } from '../../Shared/button';

interface MobileEditHeaderProps {
    onBack?: () => void;
}

const MobileEditHeader = (props: MobileEditHeaderProps) => {
    return (
        <ProfileEditHeader>
            {props.onBack && <MobileButtonBack onClick={props.onBack} />}
            <ProfileName>You</ProfileName>
        </ProfileEditHeader>
    );
};

export default MobileEditHeader;

const ProfileEditHeader = styled(ContentHeader)`
    position: relative;
    padding: 1rem;
`;

const ProfileName = styled.strong`
    color: ${(props) => props.theme.color.white};
    font-size: ${(props) => props.theme.fontSize.large};
    font-weight: 700;

    margin: 0 auto;
`;
