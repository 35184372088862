import MeasurementToolbar from '../Measurement/measurement-toolbar';
import MeasurementToolbarBackgroundColor, {
    MeasurementBackgroundColor,
} from '../Measurement/measurement-toolbar-background-color';
import MeasurementToolbarLabelColor, { MeasurementLabelColor } from '../Measurement/measurement-toolbar-label-color';
import MeasurementToolbarPolygonLength, {
    MeasurementLengthType,
} from '../Measurement/measurement-toolbar-polygon-length';
import MeasurementToolbarUnits, { MeasurementUnits } from '../Measurement/measurement-toolbar-units';
import Polyline from './polyline';

// If there are a lot of points in the polyline, we disable the length labels
// because it will impact performance
const POLYLINE_GEOMETRY_THRESHOLD_FOR_LENGTH_LABELS = 30;

interface PolylineMeasurementToolbarProps {
    polyline: Polyline;
    disabledLengthLabels?: boolean;
    onChangeShowLength: (showLength: MeasurementLengthType) => void;
    onChangeUnits: (units: MeasurementUnits) => void;
    onChangeLabelColor: (labelColor: MeasurementLabelColor) => void;
    onChangeLabelBgColor: (labelColor: MeasurementBackgroundColor) => void;
}

const PolylineMeasurementToolbar = (props: PolylineMeasurementToolbarProps) => {
    const disableLengthLabels =
        props.disabledLengthLabels || props.polyline.positions.length > POLYLINE_GEOMETRY_THRESHOLD_FOR_LENGTH_LABELS;
    const disableLengthTotalLabel = props.polyline.positions.length <= 2;

    return (
        <MeasurementToolbar>
            <MeasurementToolbarPolygonLength
                poly={props.polyline}
                onChangeShowLength={props.onChangeShowLength}
                disabled={disableLengthLabels}
                disabledTotal={disableLengthTotalLabel}
                isPolyline={true}
            />
            <MeasurementToolbarUnits units={props.polyline.units} onChangeUnits={props.onChangeUnits} />
            <MeasurementToolbarLabelColor
                labelColor={props.polyline.labelColor}
                onChangeLabelColor={props.onChangeLabelColor}
            />
            <MeasurementToolbarBackgroundColor
                backgroundColor={props.polyline.labelBgColor}
                onChangeBackgroundColor={props.onChangeLabelBgColor}
            />
        </MeasurementToolbar>
    );
};

export default PolylineMeasurementToolbar;
