import React from 'react';
import styled from 'styled-components';

interface MobileSatelliteRibbonHintProps {
    text: string;
}

const MobileSatelliteRibbonHint = (props: MobileSatelliteRibbonHintProps) => {
    return (
        <HintContainer>
            <HintIcon src="/assets/floating-drawer-icons/info-icon.svg" />
            <HintText>{props.text}</HintText>
        </HintContainer>
    );
};

export default MobileSatelliteRibbonHint;

const HintContainer = styled.div`
    display: flex;
    flex-direction: row;
    margin: 5px;
`;

const HintIcon = styled.img`
    width: 16px;
    height: 16px;
    margin: 0 5px 0 5px;
    align-self: center;
`;

const HintText = styled.span`
    color: white;
    font-size: 1rem;
`;
