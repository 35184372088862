import React from 'react';
import SoarModal, { StyledModalBody, StyledModalFooter } from '../soar-modal';
import { StyledDarkButton, StyledDeleteButton } from '../styled-button';
import { PulseLoader } from 'react-spinners';

interface RemoveBookmarkModalProps {
    isOpen: boolean;
    isBusy: boolean;
    title?: string;
    children?: React.ReactNode;
    toggle: () => void;
    onRemove: () => void;
    btnRemoveText?: string;
    onCancel?: () => void;
    btnCancelText?: string;
}

const RemoveBookmarkModal = (props: RemoveBookmarkModalProps) => {
    const handleRemove = () => {
        props.onRemove();
    };

    const handleCancel = () => {
        props.onCancel && props.onCancel();
        props.toggle();
    };

    return (
        <SoarModal title={props.title ?? 'Remove Bookmark'} isOpen={props.isOpen} toggle={props.toggle} width="600px">
            <StyledModalBody>{props.children ?? 'You are about to remove a bookmark'}</StyledModalBody>
            <StyledModalFooter>
                <StyledDarkButton onClick={() => handleCancel()} disabled={props.isBusy}>
                    {props.isBusy ? <PulseLoader /> : props.btnRemoveText ?? 'CANCEL'}
                </StyledDarkButton>
                <StyledDeleteButton onClick={() => handleRemove()} disabled={props.isBusy}>
                    {props.isBusy ? <PulseLoader /> : props.btnRemoveText ?? 'REMOVE'}
                </StyledDeleteButton>
            </StyledModalFooter>
        </SoarModal>
    );
};

export default RemoveBookmarkModal;
