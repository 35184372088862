import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import MobileOpacityControl from '../../Shared/opacity-control';
import { selectActiveAllMap } from '../../../../store/Map/SuperMap/selectors';
import { selectTileLayersOpacity } from '../../../../store/Map/TileLayer/selectors';
import { actionSetTileLayerOpacity } from '../../../../store/Map/TileLayer/actions';

interface ActiveMapOpacityControlProps {
    className?: string;
}

const ActiveMapOpacityControl = (props: ActiveMapOpacityControlProps) => {
    const activeMap = useSelector(selectActiveAllMap);
    const tileLayersOpacity = useSelector(selectTileLayersOpacity);

    const dispatch = useDispatch();
    const setTileLayerOpacity = (opacity: number): void => {
        if (!activeMap) {
            return;
        }

        dispatch(actionSetTileLayerOpacity(activeMap.id, opacity));
    };

    if (!activeMap) {
        return <React.Fragment />;
    }

    return (
        <MobileOpacityControl
            className={props.className}
            opacity={tileLayersOpacity[activeMap.id] ?? 1}
            onOpacityChange={setTileLayerOpacity}
        />
    );
};

export default ActiveMapOpacityControl;
