import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedAnnotationAction, incrementZIndexAction } from '../../../../store/Annotations/actions';
import { selectSelectedAnnotation, selectCurrentZIndex } from '../../../../store/Annotations/selectors';
import AnnotationControl, { AnnotationControlProps } from '../annotation-control';
import useAnnotationBuildLock from '../use-annotation-build-lock';
import Rectangle from './rectangle';
import RectangleAnnotation from './rectangle-annotation';
import RectangleAnnotationBuilder from './rectangle-annotation-builder';
import { setRectangleAddAction, setRectangleUpdateAction } from '../../../../store/Annotations/Rectangle/actions';
import { selectRectangles } from '../../../../store/Annotations/Rectangle/selectors';
import { useDrawToolUpdated } from '../use-draw-tool-updated';
import DrawToolAnalytics from '../Analytics/draw-tool-analytics';

const RectangleControl = (props: AnnotationControlProps) => {
    const [isRectangleControlActive, setIsRectangleControlActive] = useState(false);
    const dispatch = useDispatch();
    const selectedAnnotation = useSelector(selectSelectedAnnotation);
    const currentZIndex = useSelector(selectCurrentZIndex);

    const { lockAnnotationsEvent, unlockAnnotationsEvent } = useAnnotationBuildLock();
    const rectangles = useSelector(selectRectangles);

    const onUpdateRectangle = useDrawToolUpdated((rectangle: Rectangle) => {
        dispatch(setRectangleUpdateAction(rectangle));
    });

    const isInteractive = !props.locked && props.visible;

    return (
        <React.Fragment>
            <AnnotationControl
                active={isRectangleControlActive}
                disabled={props.disabled}
                layout="end"
                visible={props.visible}
                iconDark="/assets/annotations/black-annotations/icon-rectangle-black.svg"
                iconLight="/assets/annotations/icon-rectangle.svg"
                name="Rectangle"
                onClick={() => {
                    setIsRectangleControlActive((isActive) => !isActive);
                    lockAnnotationsEvent();
                }}
                dataTestId="annotation-control-rectangle"
            />

            {isRectangleControlActive ? (
                <RectangleAnnotationBuilder
                    zIndex={currentZIndex}
                    onCreateRectangle={(rectangle) => {
                        setIsRectangleControlActive(false);
                        dispatch(setRectangleAddAction(rectangle));
                        dispatch(incrementZIndexAction());
                        unlockAnnotationsEvent();
                        requestAnimationFrame(() => {
                            dispatch(setSelectedAnnotationAction(rectangle));
                        });
                        DrawToolAnalytics.add('rectangle');
                    }}
                    onCancelBuild={() => {
                        setIsRectangleControlActive(false);
                        unlockAnnotationsEvent();
                    }}
                />
            ) : null}

            {!isInteractive &&
                rectangles.map((rectangle) => {
                    return (
                        <RectangleAnnotation
                            key={`
                                ${rectangle.id}-locked-
                                ${selectedAnnotation ? selectedAnnotation.id : ''}-
                                ${rectangle.options.color}-
                                ${rectangle.options.fillOpacity}-
                                ${rectangle.options.dashArray}-
                                ${rectangle.options.dashOffset}-
                                ${rectangle.options.weight}-
                                ${rectangle.fillPattern ? rectangle.fillPattern : ''}
                                ${rectangle.showArea}-
                                ${rectangle.showLength}-
                                ${rectangle.labelColor}-
                                ${rectangle.labelBgColor}-
                                ${rectangle.zIndex}-
                                ${rectangle.units}-
                                ${rectangle.bounds ? JSON.stringify(rectangle.bounds) : ''}
                            `}
                            rectangle={rectangle}
                            isSelected={false}
                            isDisabled={true}
                            onUpdateRectangle={() => null}
                        />
                    );
                })}

            {isInteractive &&
                rectangles.map((rectangle) => {
                    return (
                        <RectangleAnnotation
                            key={`
                                ${rectangle.id}-
                                ${selectedAnnotation ? selectedAnnotation.id : ''}-
                                ${rectangle.options.color}-
                                ${rectangle.options.fillOpacity}-
                                ${rectangle.options.dashArray}-
                                ${rectangle.options.dashOffset}-
                                ${rectangle.options.weight}-
                                ${rectangle.fillPattern ? rectangle.fillPattern : ''}
                                ${rectangle.showArea}-
                                ${rectangle.showLength}-
                                ${rectangle.labelColor}-
                                ${rectangle.labelBgColor}-
                                ${rectangle.zIndex}-
                                ${rectangle.units}-
                                ${rectangle.bounds ? JSON.stringify(rectangle.bounds) : ''}
                            `}
                            isSelected={selectedAnnotation ? selectedAnnotation.id === rectangle.id : false}
                            rectangle={rectangle}
                            onUpdateRectangle={onUpdateRectangle}
                        />
                    );
                })}
        </React.Fragment>
    );
};

export default RectangleControl;
