import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import moment from 'moment';
import UriHelper from '../../../lib/uri-helper';
import Analytics from '../../../lib/user-analytics';

import { StoaryPage, StoaryResponse } from '../../../api/stoaryModel';
import { SideDrawerMode } from '../../../store/SideDrawer/model';
import { actionSetResetCommentSection } from '../../../store/SocialMapping/actions';
import { selectActiveAllMap } from '../../../store/Map/SuperMap/selectors';
import { actionActiveMapCleared } from '../../../store/Map/SuperMap/actions';
import { selectSentinelSelectedFeature } from '../../../store/Map/Sentinel/selectors';

import { MarkdownViewer } from '../../Shared/markdown-viewer';
import ProfileName from '../../Drawer/SideDrawer/ProfileDrawer/Profile/profile-name';
import StoryMapAttribution from '../../MapView/Annotations/StoryMaps/StoryView/story-map-attribution';
import SentinelMapAttribution from '../../MapView/Annotations/StoryMaps/StoryView/sentinel-map-atribution';
import { BottomSheetState } from '../BottomSheet/bottom-sheet';

export interface StoryOwnerDetailsType {
    userId: string;
    userName: string;
    reputable: boolean;
}

interface ActiveMapBottomSheetProps {
    stoary: StoaryResponse;
    page: StoaryPage;
    numberOfPages: number;
    owner: StoryOwnerDetailsType;
    bottomSheetState: BottomSheetState;
    onCloseBottomSheet: () => void;
    onCloseMap: () => void;
    isClosing: boolean;
}

const ActiveStoryProjectBottomSheet = (props: ActiveMapBottomSheetProps) => {
    const { isClosing, onCloseMap } = props;

    const dispatch = useDispatch();

    const activeMap = useSelector(selectActiveAllMap);
    const sentinelFeature = useSelector(selectSentinelSelectedFeature);

    useEffect(() => {
        const closeActiveMap = () => {
            UriHelper.navigateToDrawer(SideDrawerMode.MAPS);
            dispatch(actionSetResetCommentSection(true));
            onCloseMap();
            Analytics.Event(
                'Mobile - Active Project Bottom Sheet',
                'Clicked Close Active Map Bottom Sheet',
                props.page?.id
            );
        };

        if (isClosing) {
            closeActiveMap();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isClosing, props.page?.id]);

    const handleViewProfile = () => {
        dispatch(actionActiveMapCleared());
        UriHelper.navigateToProfile(props.owner.userId);
    };

    const renderPageTitle = () => {
        if (props.page.projectTitle && props.page.pageTitle) {
            if (
                props.numberOfPages > 1 ||
                (props.numberOfPages === 1 && !/^Page [0-9]+( \(copy\))?$/i.test(props.page.pageTitle))
            ) {
                return <PageTitle>{props.page.pageTitle}</PageTitle>;
            }
        }
        return null;
    };

    return (
        <React.Fragment>
            <TopContainer bottomSheetClosed={props.bottomSheetState === BottomSheetState.close}>
                <MapDetails>
                    <TitleContainer>
                        <Title singleLine={props.bottomSheetState === BottomSheetState.close}>
                            {props.page.projectTitle || props.page.pageTitle || 'Soar Project'}
                        </Title>

                        <CloseButton onClick={() => props.onCloseBottomSheet()}>
                            <CloseIcon className="fa fa-close" />
                        </CloseButton>
                    </TitleContainer>
                    <Author>
                        by&nbsp;
                        <UserProfileLink
                            userId={props.owner.userId}
                            onClick={handleViewProfile}
                            isReputable={props.owner.reputable}
                        >
                            {props.owner.userName}
                        </UserProfileLink>
                    </Author>
                    <DateUploaded>{moment(props.stoary.createdAt * 1000).fromNow()}</DateUploaded>
                </MapDetails>
            </TopContainer>
            {activeMap && <StoryMapAttribution listing={activeMap} />}
            {sentinelFeature && <SentinelMapAttribution feature={sentinelFeature} />}
            {props.page?.description || props.page?.pageTitle ? <Divider /> : null}
            {renderPageTitle()}
            {props.page.description ? (
                <DescriptionContainer>
                    <MarkdownViewer text={props.page.description} />
                </DescriptionContainer>
            ) : null}
        </React.Fragment>
    );
};

export default ActiveStoryProjectBottomSheet;

const TopContainer = styled.div<{ bottomSheetClosed: boolean }>`
    height: ${(props) => (props.bottomSheetClosed ? '120px' : 'auto')};
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;

const MapDetails = styled.div`
    width: 100%;
`;

const TitleContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: start;
`;

const Title = styled.h1<{ singleLine: boolean }>`
    display: inline-block; /* for inline elements e.g. span, strong, em etc */
    width: calc(100%); /* The trick is here! */
    color: white;
    text-align: left;
    font-size: 22px;
    line-height: 3rem;
    margin: 0 0 0 12px;

    text-overflow: ellipsis;
    overflow: hidden;
    white-space: ${(props) => (props.singleLine ? 'nowrap' : 'unset')};
    overflow-wrap: ${(props) => (props.singleLine ? 'unset' : 'break-word')};
`;

const CloseButton = styled.button`
    margin: 4px 0 0 8px;
    background: transparent;
    border: none;
    outline: none !important;
    text-align: center;
    pointer-events: all;
    align-items: top;
`;

const CloseIcon = styled.i`
    color: rgba(255, 255, 255, 0.8);
    font-size: 18px;
    margin-right: 9px;
`;

const Divider = styled.div`
    height: 1px;
    width: 100vw;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
`;

const DescriptionContainer = styled.div`
    margin: 12px 12px 0 12px;

    p {
        font-size: 16px;
        line-height: 21px;
        font-weight: 200;
    }
`;

const Author = styled.p`
    display: flex;
    text-overflow: ellipsis;
    width: calc(100%); /* The trick is here! */
    text-align: left;
    font-size: ${(props) => props.theme.fontSize.large} !important;
    color: white;
    margin: 0 0 12px 12px;
    line-height: 21px;

    a {
        color: #eed926 !important;
        text-decoration: underline !important;
        pointer-events: all;
    }
`;

const UserProfileLink = styled(ProfileName)`
    display: flex;
    width: 100%;
    flex: 1;
    margin: 0 !important;
    font-size: ${(props) => props.theme.fontSize.large} !important;

    a {
        display: block;
        min-width: 0;
        color: #eed926 !important;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-weight: normal;
        max-width: calc(100% - 75px);

        & ~ sup {
            font-size: ${(props) => props.theme.fontSize.normal} !important;
            line-height: ${(props) => props.theme.fontSize.xlarge} !important;
        }
    }
`;

const PageTitle = styled.div`
    margin: 12px 12px 0px 12px;
    color: white;
    font-size: 18px;
    font-weight: 600;
    overflow-wrap: break-word;
`;

const DateUploaded = styled.div`
    margin: -6px 0 6px 12px;
    color: rgba(255, 255, 255, 0.6);
    font-size: 1rem;
`;
