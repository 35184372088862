import { useState } from 'react';
import { useEffect } from 'react';
import { isMobileVersion } from '../../../lib/soar-helper';
import { useDispatch, useSelector } from 'react-redux';
import { Table } from 'reactstrap';
import styled from 'styled-components';
import { actionUpdatePurchasedListings } from '../../../store/Purchases/actions';
import { selectPurchasedListings } from '../../../store/Purchases/selectors';
import NavigationPanel from '../Shared/navigation-panel';
import { PurchaseListItem } from './purchase-list-item';
import { PulseLoader } from '../../Shared/pulse-loader';

const PurchaseHistory = () => {
    const dispatch = useDispatch();
    // NOTE - this is probably not even used now but during updates to the storybooks this presented as been broken as the api response may have changed,
    // since the number of purchases was less than 10 and the chances of seeing is probably nil it seems reasonable to just comment this and make a quick fix..
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const myPurchases = useSelector(selectPurchasedListings) as any;
    const [isLoading, setIsLoading] = useState<boolean>(false);

    useEffect(() => {
        const updatePurchases = () => {
            setIsLoading(true);
            dispatch(actionUpdatePurchasedListings());
            setIsLoading(false);
        };
        if (!myPurchases) {
            updatePurchases();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div>
            <NavigationPanel label="Purchase History" />
            {isLoading && myPurchases ? (
                <LoaderContainer>
                    <PulseLoader />
                </LoaderContainer>
            ) : (
                <>
                    {myPurchases?.purchases?.length > 0 && (
                        <PurchaseTable>
                            <thead>
                                <tr>
                                    {!isMobileVersion && (
                                        <>
                                            <th>Purchase Details</th>
                                            <th>Purchase Date</th>
                                            <th>Download Receipt</th>
                                        </>
                                    )}
                                </tr>
                            </thead>
                            <tbody>
                                {myPurchases?.purchases?.map((purchase) => (
                                    <PurchaseListItem key={purchase.id} purchase={purchase} />
                                ))}
                            </tbody>
                        </PurchaseTable>
                    )}
                </>
            )}
        </div>
    );
};

export default PurchaseHistory;

const PurchaseTable = styled(Table)`
    background-color: transparent;
    color: white;

    thead,
    tbody,
    th,
    td {
        background: transparent !important;
    }

    th {
        color: white;
        border-top: none;
        border-bottom: none !important;
    }
`;

const LoaderContainer = styled.div`
    width: 100%;
    text-align: center;
    margin-top: 20px;
`;
