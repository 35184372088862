import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedAnnotationAction } from '../../../../store/Annotations/actions';
import { selectSelectedAnnotation } from '../../../../store/Annotations/selectors';
import LineStyle from '../Popup/line-style';
import { ToolbarContainer, ToolbarDivider, ToolbarText } from '../annotation-toolbar';
import LayerOrderControl from '../layer-order-control';

import {
    setPolylineDeleteAction,
    setPolylineUpdateAction,
    setPolylinesOptionsAction,
} from '../../../../store/Annotations/Path/actions';
import { selectPolylines } from '../../../../store/Annotations/Path/selectors';
import DrawToolAnalytics from '../Analytics/draw-tool-analytics';
import LineColor from '../ToolBar/line-color';
import Polyline from './polyline';

import AnnotationDelete from '../annotation-delete';
import PolylineMeasurementToolbar from './polyline-measurement-toolbar';

const PolylineToolbar = () => {
    const dispatch = useDispatch();
    const polylines = useSelector(selectPolylines);
    const selectedAnnotation = useSelector(selectSelectedAnnotation);
    const selectedPolyline = polylines.find((c) => c.id === selectedAnnotation?.id);

    const updatePolyline = (polyline: Polyline) => {
        dispatch(setPolylineUpdateAction(polyline));
    };

    if (!selectedPolyline) return <React.Fragment />;

    return (
        <ToolbarContainer>
            <ToolbarText>Line Color:</ToolbarText>

            <LineColor
                selectedColor={selectedPolyline.options.color || '#eed926'}
                onSelectColor={(color) => {
                    updatePolyline({
                        ...selectedPolyline,
                        options: {
                            ...selectedPolyline.options,
                            fillColor: color,
                            color: color,
                        },
                    });
                    dispatch(
                        setPolylinesOptionsAction({
                            ...selectedPolyline.options,
                            fillColor: color,
                            color: color,
                        })
                    );
                }}
            />
            <ToolbarDivider />

            <ToolbarText>Line Style:</ToolbarText>
            <LineStyle
                selectedLineStyle={{
                    dashArray: selectedPolyline.options.dashArray,
                    weight: selectedPolyline.options.weight,
                }}
                onSelectLineStyle={(lineStyle) => {
                    updatePolyline({
                        ...selectedPolyline,
                        options: {
                            ...selectedPolyline.options,
                            dashArray: lineStyle.dashArray ? lineStyle.dashArray : undefined,
                            weight: lineStyle.weight ? lineStyle.weight : undefined,
                        },
                    });
                    dispatch(
                        setPolylinesOptionsAction({
                            ...selectedPolyline.options,
                            dashArray: lineStyle.dashArray ? lineStyle.dashArray : undefined,
                            weight: lineStyle.weight ? lineStyle.weight : undefined,
                        })
                    );
                }}
            />
            <ToolbarDivider />
            <ToolbarText>Measurements:</ToolbarText>
            <PolylineMeasurementToolbar
                polyline={selectedPolyline}
                onChangeShowLength={(showLength) => {
                    updatePolyline({
                        ...selectedPolyline,
                        showLength: showLength,
                    });
                }}
                onChangeUnits={(units) => {
                    updatePolyline({
                        ...selectedPolyline,
                        units: units,
                    });
                }}
                onChangeLabelColor={(labelColor) => {
                    updatePolyline({
                        ...selectedPolyline,
                        labelColor: labelColor,
                    });
                }}
                onChangeLabelBgColor={(labelBgColor) => {
                    updatePolyline({
                        ...selectedPolyline,
                        labelBgColor: labelBgColor,
                    });
                }}
            />

            <ToolbarDivider />
            <ToolbarText>Arrange:</ToolbarText>
            <LayerOrderControl annotation={selectedPolyline} />
            <ToolbarDivider />

            <AnnotationDelete
                onClick={() => {
                    DrawToolAnalytics.delete('line');
                    dispatch(setPolylineDeleteAction(selectedPolyline));
                    dispatch(setSelectedAnnotationAction(undefined));
                }}
            />
        </ToolbarContainer>
    );
};

export default PolylineToolbar;
