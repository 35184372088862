import styled from 'styled-components';
import moment from 'moment';

import { ListingDTO, UserDTO } from '../../../../api/model';

import UserAvatar from '../../../Shared/Avatar/user-avatar';
import { MarkdownViewer } from '../../../Shared/markdown-viewer';
import CollapsibleText from '../Shared/collapsible-text';
import ProfileViewDetailsShare from './mobile-details-share';
import ProfileStats from '../../../Drawer/SideDrawer/ProfileDrawer/Profile/profile-stats';
import PointsUtil from '../../../NavigationBar/Points/points-util';

interface MobileViewDetailsProps {
    profile: UserDTO;
    listings: ListingDTO[];
}

const MobileViewDetails = (props: MobileViewDetailsProps) => {
    return (
        <ProfileDetailsContainer>
            <ProfileAvatarContainer>
                <ProfileAvatar user={props.profile} margin="0px 0px 0px 0px" diameter={150} />
                <ProfilePoints title={`${props.profile.name} has ${props.profile.points?.toLocaleString()} points`}>
                    {PointsUtil.pointsFormatter(props.profile.points)} points
                </ProfilePoints>
            </ProfileAvatarContainer>
            <ProfileDetailsContent>
                <ProfileBio>{props.profile.bio}</ProfileBio>
                <ProfileBioText>
                    <ProfileMarkdownViewer text={props.profile.longDescription || ''} />
                </ProfileBioText>
            </ProfileDetailsContent>
            <ProfileViewDetailsShare userProfile={props.profile} />
            {props.profile.createdAt && (
                <ProfileCreated>
                    Member Since {moment((props.profile.createdAt || 0) * 1000).format('D MMM YYYY')}
                </ProfileCreated>
            )}
            <ProfileStats userProfile={props.profile} />
        </ProfileDetailsContainer>
    );
};

export default MobileViewDetails;

const ProfileDetailsContainer = styled.section`
    padding: 60px 30px 10px;
`;

const ProfileAvatarContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
`;

const ProfileAvatar = styled(UserAvatar)``;

const ProfilePoints = styled.span`
    color: ${(props) => props.theme.color.yellow};
    font-size: ${(props) => props.theme.fontSize.normal};
    font-weight: 700;
    text-align: center;
`;

const ProfileDetailsContent = styled.div`
    padding: 10px 0;

    display: flex;
    flex-direction: column;
`;

const ProfileBio = styled.div`
    color: ${(props) => props.theme.color.white};
    font-size: ${(props) => props.theme.fontSize.large};
    text-align: center;

    margin-bottom: 16px;

    overflow-wrap: break-word;
`;

const ProfileBioText = styled(CollapsibleText)`
    margin-bottom: 16px;

    p {
        display: inline-block;
    }
`;

const ProfileMarkdownViewer = styled(MarkdownViewer)``;

const ProfileCreated = styled.div`
    font-size: ${(props) => props.theme.fontSize.normal};
    color: ${(props) => props.theme.color.opaqueWhite};
    text-align: center;

    border-bottom: solid 1px ${(props) => props.theme.color.gray};
    padding-bottom: 20px;
`;
