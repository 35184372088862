import styled, { keyframes } from 'styled-components';

const shimmer = keyframes`
  0% {
    background-position: -400px 0;
  }
  100% {
    background-position: 400px 0;
  }
`;

const SkeletonLoading = styled.div`
    background: linear-gradient(
        90deg,
        rgba(255, 255, 255, 0.05) 25%,
        rgba(255, 255, 255, 0.1) 50%,
        rgba(255, 255, 255, 0.05) 75%
    );
    background-size: 800px 100%;
    animation: ${shimmer} 1.5s infinite linear;
    border-radius: 6px;
    padding: 5px;
    margin: 0 15px 0 15px;
    width: auto;
    height: 34px;
`;

export default SkeletonLoading;
