import React from 'react';
import styled from 'styled-components';
import moment from 'moment';

import { OrderDetailsDTO } from '../../../../api/model';

import { BottomSheetState } from '../../BottomSheet/bottom-sheet';

interface ActiveOrderBottomSheetProps {
    activeOrder: OrderDetailsDTO;
    bottomSheetState: BottomSheetState;
    onCloseMap: () => void;
}

const ActiveOrderBottomSheet = (props: ActiveOrderBottomSheetProps) => {
    return (
        <React.Fragment>
            <TopContainer bottomSheetClosed={props.bottomSheetState === BottomSheetState.close}>
                <MapDetails>
                    <TitleContainer>
                        <Title singleLine={props.bottomSheetState === BottomSheetState.close}>
                            {props.activeOrder.orderNumber}
                        </Title>

                        <CloseButton onClick={() => props.onCloseMap()}>
                            <CloseIcon className="fa fa-close" />
                        </CloseButton>
                    </TitleContainer>
                    <Supplier>
                        Supplier:&nbsp;<SupplierName>{props.activeOrder.supplierCode}</SupplierName>
                    </Supplier>
                    <DateOrdered>{moment(props.activeOrder.created).fromNow()}</DateOrdered>
                </MapDetails>
            </TopContainer>
        </React.Fragment>
    );
};

export default ActiveOrderBottomSheet;

const TopContainer = styled.div<{ bottomSheetClosed: boolean }>`
    height: ${(props) => (props.bottomSheetClosed ? '100px' : 'auto')};
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;

const MapDetails = styled.div`
    width: 100%;
`;

const TitleContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: start;
`;

const Title = styled.h1<{ singleLine: boolean }>`
    display: inline-block; /* for inline elements e.g. span, strong, em etc */
    width: calc(100%); /* The trick is here! */
    color: white;
    text-align: left;
    font-size: 22px;
    line-height: 3rem;
    margin: 0 0 4px 12px;

    text-overflow: ellipsis;
    overflow: hidden;
    white-space: ${(props) => (props.singleLine ? 'nowrap' : 'unset')};
    overflow-wrap: ${(props) => (props.singleLine ? 'unset' : 'break-word')};
`;

const CloseButton = styled.button`
    margin: 4px 0 0 8px;
    background: transparent;
    border: none;
    outline: none !important;
    text-align: center;
    pointer-events: all;
    align-items: top;
`;

const CloseIcon = styled.i`
    color: rgba(255, 255, 255, 0.8);
    font-size: 18px;
    margin-right: 9px;
`;

const Supplier = styled.div`
    display: flex;
    text-overflow: ellipsis;
    width: auto;
    text-align: left;
    font-size: ${(props) => props.theme.fontSize.large} !important;
    color: white;
    margin: 0 0 16px 12px;
    line-height: 21px;
`;

const SupplierName = styled.span`
    color: ${(props) => props.theme.color.yellow};
    font-size: ${(props) => props.theme.fontSize.large} !important;
`;

const DateOrdered = styled.div`
    margin: -6px 0 6px 12px;
    color: rgba(255, 255, 255, 0.6);
    font-size: 1rem;
`;
