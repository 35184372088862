import styled from 'styled-components';
import { Input } from 'reactstrap';

interface PasswordInputProps {
    togglePasswordVisibility?: boolean;
}

export const PasswordInput = styled(Input)<PasswordInputProps>`
    &::-ms-reveal {
        display: none;
    }

    padding-right: ${(props) => props.togglePasswordVisibility && '35px'};
`;
