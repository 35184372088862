import React from 'react';
import { PulseLoader } from '../pulse-loader';
import SoarModal, { StyledModalBody, StyledModalFooter } from '../soar-modal';
import { StyledDarkButton, StyledButton } from '../styled-button';

interface ConfirmModalProps {
    title?: string | JSX.Element;
    message?: string;
    isOpen: boolean;
    onToggle: () => void;
    onConfirm: () => void;
    isConfirming?: boolean;
    confirmText?: string;
    cancelText?: string;
}

const ConfirmModal = (props: ConfirmModalProps) => {
    return (
        <SoarModal title={props.title || 'Confirm'} isOpen={props.isOpen} toggle={props.onToggle} width="600px">
            <StyledModalBody>{props.message || 'Are you sure you want to continue?'}</StyledModalBody>
            <StyledModalFooter>
                {!props.isConfirming ? (
                    <React.Fragment>
                        <StyledDarkButton onClick={props.onToggle}>{props.cancelText ?? 'CANCEL'}</StyledDarkButton>
                        <StyledButton onClick={props.onConfirm}>{props.confirmText ?? 'CONFIRM'}</StyledButton>
                    </React.Fragment>
                ) : (
                    <PulseLoader />
                )}
            </StyledModalFooter>
        </SoarModal>
    );
};

export default ConfirmModal;
