import React, { useState } from 'react';
import AnnotationControl, { AnnotationControlProps } from '../annotation-control';
import Circle from './circle';
import CircleAnnotation from './circle-annotation';
import CircleBuilder from './circle-builder';
import { useDispatch, useSelector } from 'react-redux';
import { selectSelectedAnnotation, selectCurrentZIndex } from '../../../../store/Annotations/selectors';
import { setSelectedAnnotationAction, incrementZIndexAction } from '../../../../store/Annotations/actions';
import useAnnotationBuildLock from '../use-annotation-build-lock';
import { setCircleAddAction, setCircleUpdateAction } from '../../../../store/Annotations/Circle/actions';
import { selectCircles } from '../../../../store/Annotations/Circle/selectors';
import { useDrawToolUpdated } from '../use-draw-tool-updated';
import DrawToolAnalytics from '../Analytics/draw-tool-analytics';

const CircleControl = (props: AnnotationControlProps) => {
    const [isCreatingCircle, setIsCreatingCircle] = useState(false);

    const dispatch = useDispatch();
    const circles = useSelector(selectCircles);

    const selectedAnnotation = useSelector(selectSelectedAnnotation);
    const currentZIndex = useSelector(selectCurrentZIndex);

    const { lockAnnotationsEvent, unlockAnnotationsEvent } = useAnnotationBuildLock();

    const onUpdateCircle = useDrawToolUpdated((circle: Circle) => {
        dispatch(setCircleUpdateAction(circle));
    });

    const isInteractive = !props.locked && props.visible;

    return (
        <React.Fragment>
            <AnnotationControl
                active={isCreatingCircle}
                disabled={props.disabled}
                layout="middle"
                visible={props.visible}
                iconDark="/assets/annotations/black-annotations/icon-circle-black.svg"
                iconLight="/assets/annotations/icon-circle.svg"
                name="Circle"
                onClick={() => {
                    setIsCreatingCircle(true);
                    lockAnnotationsEvent();
                }}
                dataTestId="annotation-control-circle"
            />

            {isCreatingCircle && (
                <CircleBuilder
                    zIndex={currentZIndex}
                    onCreateCircle={(circle: Circle) => {
                        setIsCreatingCircle(false);
                        dispatch(setCircleAddAction(circle));
                        dispatch(incrementZIndexAction());
                        unlockAnnotationsEvent();
                        requestAnimationFrame(() => {
                            dispatch(setSelectedAnnotationAction(circle));
                        });
                        DrawToolAnalytics.add('circle');
                    }}
                    onCancel={() => {
                        setIsCreatingCircle(false);
                        unlockAnnotationsEvent();
                    }}
                />
            )}

            {!isInteractive &&
                circles.map((circle) => {
                    return (
                        <CircleAnnotation
                            isSelected={false}
                            key={`
                                ${circle.id}-locked-
                                ${selectedAnnotation ? selectedAnnotation.id : ''}-
                                ${circle.options.color}-
                                ${circle.options.fillOpacity}-
                                ${circle.options.dashArray}-
                                ${circle.options.dashOffset}-
                                ${circle.options.weight}-
                                ${circle.fillPattern ? circle.fillPattern : ''}
                                ${circle.center.lat}-
                                ${circle.center.lng}-
                                ${circle.radius}-
                                ${circle.showRadius}-
                                ${circle.units}-
                                ${circle.labelColor}-
                                ${circle.labelBgColor}-
                                ${circle.zIndex}
                            `}
                            radius={circle.radius}
                            circle={circle}
                            isDisabled={true}
                        />
                    );
                })}

            {isInteractive &&
                circles.map((circle) => {
                    return (
                        <CircleAnnotation
                            key={`
                                ${circle.id}-
                                ${selectedAnnotation ? selectedAnnotation.id : ''}-
                                ${circle.options.color}-
                                ${circle.options.fillOpacity}-
                                ${circle.options.dashArray}-
                                ${circle.options.dashOffset}-
                                ${circle.options.weight}-
                                ${circle.fillPattern ? circle.fillPattern : ''}
                                ${circle.center.lat}-
                                ${circle.center.lng}-
                                ${circle.radius}-
                                ${circle.showRadius}-
                                ${circle.units}-
                                ${circle.labelColor}-
                                ${circle.labelBgColor}-
                                ${circle.zIndex}
                            `}
                            isSelected={selectedAnnotation ? selectedAnnotation.id === circle.id : false}
                            circle={circle}
                            radius={circle.radius}
                            onUpdateCircle={onUpdateCircle}
                        />
                    );
                })}
        </React.Fragment>
    );
};

export default CircleControl;
