import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    setCircleDeleteAction,
    setCircleOptionsAction,
    setCircleUpdateAction,
} from '../../../../store/Annotations/Circle/actions';
import { selectCircles } from '../../../../store/Annotations/Circle/selectors';
import { setDeleteFillPatternAction, setSelectedAnnotationAction } from '../../../../store/Annotations/actions';
import { selectSelectedAnnotation } from '../../../../store/Annotations/selectors';
import DrawToolAnalytics from '../Analytics/draw-tool-analytics';
import LineStyle from '../Popup/line-style';
import FillColor from '../ToolBar/fill-color';
import AnnotationDelete from '../annotation-delete';
import { ToolbarContainer, ToolbarDivider, ToolbarMenuItem, ToolbarText } from '../annotation-toolbar';
import LayerOrderControl from '../layer-order-control';
import Circle from './circle';

import { findAndUpdateAvailablePattern, Pattern } from '../FillPattern/availablePatterns';
import { handlePattternIdFromPatternFillUrl, handlePatternFillUrl } from '../FillPattern/pattern-util';
import CircleMeasurementToolbar from './circle-measurement-toolbar';

const CircleToolbar = () => {
    const dispatch = useDispatch();
    const circles = useSelector(selectCircles);
    const selectedAnnotation = useSelector(selectSelectedAnnotation);

    const selectedCircle = circles.find((c) => c.id === selectedAnnotation?.id);

    const updateCircle = (circle: Circle) => {
        dispatch(setCircleUpdateAction(circle));
    };

    if (!selectedCircle) return <React.Fragment />;

    return (
        <ToolbarContainer>
            <ToolbarMenuItem>
                <ToolbarText>Fill:</ToolbarText>
                <FillColor
                    onSelectColor={(color) => {
                        let selectedCircleFillColor = selectedCircle.options.fillColor as string;

                        const patternId = handlePattternIdFromPatternFillUrl(selectedCircleFillColor);

                        if (patternId !== 'none') {
                            const updatedPattern = findAndUpdateAvailablePattern(selectedCircle.id, patternId, color);

                            selectedCircleFillColor = handlePatternFillUrl(updatedPattern as Pattern);
                        } else {
                            selectedCircleFillColor = color;
                        }

                        updateCircle({
                            ...selectedCircle,
                            options: {
                                ...selectedCircle.options,
                                fillColor: selectedCircleFillColor,
                                color: color,
                            },
                        });
                        dispatch(
                            setCircleOptionsAction({
                                ...selectedCircle.options,
                                fillColor: selectedCircleFillColor,
                                color: color,
                            })
                        );
                    }}
                    selectedColor={selectedCircle.options.color || '#eed926'}
                    opacity={selectedCircle.options.fillOpacity || 0.2}
                    onSetOpacity={(opacity) => {
                        updateCircle({
                            ...selectedCircle,
                            options: {
                                ...selectedCircle.options,
                                fillOpacity: opacity,
                            },
                        });
                        dispatch(
                            setCircleOptionsAction({
                                ...selectedCircle.options,
                                fillOpacity: opacity,
                            })
                        );
                    }}
                    showFillPattern={true}
                    fillPattern={handlePattternIdFromPatternFillUrl(selectedCircle?.options?.fillColor as string)}
                    onSetFillPatternUrl={(fillPattern: string) => {
                        if (fillPattern.includes('none')) {
                            updateCircle({
                                ...selectedCircle,
                                fillPattern: undefined,
                                options: {
                                    ...selectedCircle.options,
                                    fillColor: selectedCircle.options.color,
                                    fillOpacity: 0.2,
                                },
                            });
                            dispatch(
                                setCircleOptionsAction({
                                    ...selectedCircle.options,
                                    fillColor: selectedCircle.options.color,
                                    fillOpacity: 0.2,
                                })
                            );
                            return;
                        }

                        updateCircle({
                            ...selectedCircle,
                            fillPattern: fillPattern,
                            options: {
                                ...selectedCircle.options,
                                fillColor: fillPattern,
                                fillOpacity: 1,
                            },
                        });
                        dispatch(
                            setCircleOptionsAction({
                                ...selectedCircle.options,
                                fillColor: fillPattern,
                                fillOpacity: 1,
                            })
                        );
                    }}
                />
                <ToolbarDivider />
            </ToolbarMenuItem>

            <ToolbarText>Line Style:</ToolbarText>
            <LineStyle
                selectedLineStyle={{
                    dashArray: selectedCircle.options.dashArray,
                    weight: selectedCircle.options.weight,
                }}
                onSelectLineStyle={(lineStyle) => {
                    updateCircle({
                        ...selectedCircle,
                        options: {
                            ...selectedCircle.options,
                            dashArray: lineStyle.dashArray ? lineStyle.dashArray : undefined,
                            weight: lineStyle.weight ? lineStyle.weight : undefined,
                        },
                    });
                    dispatch(
                        setCircleOptionsAction({
                            ...selectedCircle.options,
                            dashArray: lineStyle.dashArray ? lineStyle.dashArray : undefined,
                            weight: lineStyle.weight ? lineStyle.weight : undefined,
                        })
                    );
                }}
            />

            <ToolbarDivider />
            <ToolbarText>Measurements:</ToolbarText>
            <CircleMeasurementToolbar
                circle={selectedCircle}
                onChangeShowRadius={(showRadius) => {
                    updateCircle({
                        ...selectedCircle,
                        showRadius: showRadius,
                    });
                }}
                onChangeUnits={(units) => {
                    updateCircle({
                        ...selectedCircle,
                        units: units,
                    });
                }}
                onChangeLabelColor={(labelColor) => {
                    updateCircle({
                        ...selectedCircle,
                        labelColor: labelColor,
                    });
                }}
                onChangeBackgroundColor={(labelBgColor) => {
                    updateCircle({
                        ...selectedCircle,
                        labelBgColor: labelBgColor,
                    });
                }}
            />

            <ToolbarDivider />
            <ToolbarText>Arrange:</ToolbarText>
            <LayerOrderControl annotation={selectedCircle} />

            <ToolbarDivider />

            <AnnotationDelete
                onClick={() => {
                    dispatch(setDeleteFillPatternAction(selectedCircle.id));
                    DrawToolAnalytics.delete('circle');
                    dispatch(setCircleDeleteAction(selectedCircle));
                    dispatch(setSelectedAnnotationAction(undefined));
                }}
            />
        </ToolbarContainer>
    );
};

export default CircleToolbar;
