import L from 'leaflet';
import Rectangle from './rectangle';
import {
    createContainerComponent,
    createElementHook,
    createElementObject,
    createPathHook,
    LeafletContextInterface,
    PathProps,
} from '@react-leaflet/core';
import { defaultRectangleOptions } from './rectangle';
import LayersUtil from '../layers-util';
import { selectedPolygonOutlineOptions } from '../Polygon/polygon';
import { polygonToPaddedPolygon } from '../use-select-annotation-utils';
import { createPolygonDragElement } from '../Polygon/polygon-drag-element';
import { createRectangleResizeElement } from './reactangle-annotation-resize-element';
import { createAreaLabel } from '../Measurement/area-label';
import { createLengthLabel, createTotalLengthLabel } from '../Measurement/length-label';

interface RectangleAnnotationProps extends PathProps {
    isSelected: boolean;
    isDisabled?: boolean;
    rectangle: Rectangle;
    onUpdateRectangle: (rectangle: Rectangle) => void;
    children?: React.ReactNode;
}

const createRectangleElement = (props: RectangleAnnotationProps, context: LeafletContextInterface) => {
    const pane = LayersUtil.getPaneId(context.map, props.rectangle);

    const rectangle = new L.Rectangle(props.rectangle.bounds, {
        ...defaultRectangleOptions,
        ...props.rectangle.options,
        pane: pane,
    });
    const rectangleElement = createElementObject<L.Rectangle, RectangleAnnotationProps>(rectangle, context);

    const selectedOutlineBoundsId = LayersUtil.getSelectedOutlinePaneId(context.map);
    const boundsPositions = [
        rectangleElement.instance.getBounds().getNorthWest(),
        rectangleElement.instance.getBounds().getNorthEast(),
        rectangleElement.instance.getBounds().getSouthEast(),
        rectangleElement.instance.getBounds().getSouthWest(),
    ];
    const outlineBounds = polygonToPaddedPolygon(context.map, boundsPositions);
    const rectangleBounds = new L.Polygon(outlineBounds, {
        ...selectedPolygonOutlineOptions,
        pane: selectedOutlineBoundsId,
    });
    const rectangleBoundsElement = createElementObject<L.Polygon, RectangleAnnotationProps>(rectangleBounds, context);

    const dragRectangleElement = createPolygonDragElement(
        { polygonElement: rectangleElement, positions: boundsPositions, isSelected: props.isSelected },
        context
    );

    const resizeRectangleElement = createRectangleResizeElement(
        { rectangleElement: rectangleElement, context },
        context
    );

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let rectangleAreaLabel;
    const addAreaLabel = () => {
        if (rectangleAreaLabel) {
            context.map.removeLayer(rectangleAreaLabel.instance);
        }

        const bounds = rectangleElement.instance.getBounds();
        const positions = [bounds.getNorthWest(), bounds.getNorthEast(), bounds.getSouthEast(), bounds.getSouthWest()];
        const units = props.rectangle.units;
        const labelColor = props.rectangle.labelColor;
        const labelBgColor = props.rectangle.labelBgColor;

        rectangleAreaLabel = createAreaLabel(positions, units, labelColor, labelBgColor, pane, context);
        context.map.addLayer(rectangleAreaLabel.instance);

        const ne = bounds.getNorthEast();
        const nw = bounds.getNorthWest();
        const screenWidthOfBounds = context.map.latLngToLayerPoint(ne).x - context.map.latLngToLayerPoint(nw).x;

        const svgElement = rectangleAreaLabel.instance.getElement();
        const svgElementWidth = svgElement.getBBox().width;

        if (svgElementWidth > screenWidthOfBounds) {
            context.map.removeLayer(rectangleAreaLabel.instance);
        }
    };

    const removeAreaLabel = () => {
        if (rectangleAreaLabel) {
            context.map.removeLayer(rectangleAreaLabel.instance);
            rectangleAreaLabel = undefined;
        }
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let rectangleLengthLabels: any[] = [];

    const addLengthLabels = () => {
        if (rectangleLengthLabels && rectangleLengthLabels.length > 0) {
            removeLengthLabels();
        }

        if (props.rectangle.showLength === 'sections') {
            addSectionLengthLabels();
        } else if (props.rectangle.showLength === 'total') {
            addTotalLengthLabel();
        } else {
            removeLengthLabels();
        }
    };

    const addTotalLengthLabel = () => {
        const bounds = rectangleElement.instance.getBounds();

        const rectangleBounds: L.LatLng[] = [
            bounds.getNorthWest(),
            bounds.getNorthEast(),
            bounds.getSouthEast(),
            bounds.getSouthWest(),
            bounds.getNorthWest(), // close the rectangle
        ];

        createTotalLengthLabel(
            rectangleBounds,
            props.rectangle.units,
            props.rectangle.labelColor,
            props.rectangle.labelBgColor,
            pane,
            context
        ).then((label) => {
            context.map.addLayer(label.instance);

            rectangleLengthLabels.push(label);
        });
    };

    const addSectionLengthLabels = () => {
        const bounds = rectangleElement.instance.getBounds();
        const units = props.rectangle.units;
        const labelColor = props.rectangle.labelColor;
        const labelBgColor = props.rectangle.labelBgColor;

        const topLabel = createLengthLabel(
            bounds.getNorthWest(),
            bounds.getNorthEast(),
            [],
            units,
            labelColor,
            labelBgColor,
            pane,
            context
        );

        const rightLabel = createLengthLabel(
            bounds.getNorthEast(),
            bounds.getSouthEast(),
            [],
            units,
            labelColor,
            labelBgColor,
            pane,
            context
        );

        const bottomLabel = createLengthLabel(
            bounds.getSouthEast(),
            bounds.getSouthWest(),
            [],
            units,
            labelColor,
            labelBgColor,
            pane,
            context
        );

        const leftLabel = createLengthLabel(
            bounds.getSouthWest(),
            bounds.getNorthWest(),
            [],
            units,
            labelColor,
            labelBgColor,
            pane,
            context
        );

        context.map.addLayer(topLabel.instance);
        context.map.addLayer(rightLabel.instance);
        context.map.addLayer(bottomLabel.instance);
        context.map.addLayer(leftLabel.instance);

        rectangleLengthLabels.push(topLabel);
        rectangleLengthLabels.push(rightLabel);
        rectangleLengthLabels.push(bottomLabel);
        rectangleLengthLabels.push(leftLabel);
    };

    const onZoomEnd = () => {
        if (props.rectangle.showArea) {
            addAreaLabel();
        }
    };

    const removeLengthLabels = () => {
        rectangleLengthLabels.forEach((label) => {
            context.map.removeLayer(label.instance);
        });
        rectangleLengthLabels = [];
    };

    rectangleElement.instance.on('update', () => {
        const boundsPositions = [
            rectangleElement.instance.getBounds().getNorthWest(),
            rectangleElement.instance.getBounds().getNorthEast(),
            rectangleElement.instance.getBounds().getSouthEast(),
            rectangleElement.instance.getBounds().getSouthWest(),
        ];
        const outlineBounds = polygonToPaddedPolygon(context.map, boundsPositions);
        rectangleBoundsElement.instance.setLatLngs(outlineBounds);
        const updatedRectangle = { ...props.rectangle, bounds: rectangleElement.instance.getBounds() };
        props.onUpdateRectangle(updatedRectangle);
        removeLengthLabels();
        if (props.rectangle.showLength) {
            addLengthLabels();
        }

        removeAreaLabel();
        if (props.rectangle.showArea) {
            addAreaLabel();
        }
    });

    rectangleElement.instance.on('path-drag-start', () => {
        context.map.removeLayer(rectangleBoundsElement.instance);
        context.map.removeLayer(resizeRectangleElement.instance);
    });

    rectangleElement.instance.on('path-drag-end', () => {
        const boundsPositions = [
            rectangleElement.instance.getBounds().getNorthWest(),
            rectangleElement.instance.getBounds().getNorthEast(),
            rectangleElement.instance.getBounds().getSouthEast(),
            rectangleElement.instance.getBounds().getSouthWest(),
        ];
        const outlineBounds = polygonToPaddedPolygon(context.map, boundsPositions);
        rectangleBoundsElement.instance.setLatLngs(outlineBounds);
        context.map.addLayer(rectangleBoundsElement.instance);
        context.map.addLayer(resizeRectangleElement.instance);
        resizeRectangleElement.instance.fireEvent('update');
    });

    rectangleElement.instance.on('remove', () => {
        context.map.removeLayer(dragRectangleElement.instance);
        context.map.removeLayer(resizeRectangleElement.instance);
        context.map.removeLayer(rectangleBoundsElement.instance);
        context.map.off('zoomend', onZoomEnd);
        removeLengthLabels();
        removeAreaLabel();
    });

    rectangleElement.instance.on('add', () => {
        if (props.isSelected) {
            context.map.addLayer(dragRectangleElement.instance);
            context.map.addLayer(resizeRectangleElement.instance);
            context.map.addLayer(rectangleBoundsElement.instance);
        } else {
            context.map.removeLayer(dragRectangleElement.instance);
            context.map.removeLayer(resizeRectangleElement.instance);
            context.map.removeLayer(rectangleBoundsElement.instance);
        }
        context.map.on('zoomend', onZoomEnd);
        if (props.rectangle.showLength) {
            addLengthLabels();
        }

        if (props.rectangle.showArea) {
            addAreaLabel();
        }
    });

    // The interactive option adds or removes the leaflet-interactive class which changes the cursor
    // This means we have a third state of interactivity that needs to be handled when the annotation is disabled
    // otherwise hovering when editing will not show the pointer cursor
    if (props.isDisabled) {
        rectangleElement.instance.options.interactive = false;
    } else {
        rectangleElement.instance.options.interactive = true;
    }

    return rectangleElement;
};

const updateRectangleElement = (
    instance: L.Rectangle,
    props: RectangleAnnotationProps,
    _: RectangleAnnotationProps
) => {
    instance.setStyle({
        ...instance.options,
        ...props.rectangle.options,
    });
};

const useRectangleAnnotation = createElementHook<L.Rectangle, RectangleAnnotationProps, LeafletContextInterface>(
    createRectangleElement,
    updateRectangleElement
);
const useRectangle = createPathHook<L.Rectangle, RectangleAnnotationProps>(useRectangleAnnotation);
const RectangleAnnotation = createContainerComponent(useRectangle);

export default RectangleAnnotation;
