import { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { SentinelMetadata } from '../../../../store/Map/Sentinel/model';
import Constants from '../../../../constants';
import Analytics from '../../../../lib/user-analytics';

const moment = require('moment');

export interface DateRange {
    startDate: Date;
    endDate: Date;
    displayText: string;
}

export const last30Days: DateRange = {
    startDate: moment(new Date()).subtract(1, 'months').toDate(),
    endDate: new Date(),
    displayText: 'Last 30 days',
};

interface SentinelFilterProps {
    onSelectLayer: (sensor: SentinelMetadata) => void;
    layer: SentinelMetadata;
    dateRange: DateRange;
    onSelectDateRange: (dateRange: DateRange) => void;
}
const SentinelFilter = (props: SentinelFilterProps) => {
    const [sensor, setLayer] = useState<SentinelMetadata>(props.layer);
    const [dates, setDates] = useState<DateRange[]>([]);
    const [selectedDate, setSelectedDate] = useState<DateRange>(props.dateRange);
    const { onSelectDateRange, onSelectLayer } = props;

    const dateOptions = useCallback(() => {
        Analytics.Event('Satellite - Sentinel', 'Date filter changed');
        const previousMonth = moment(new Date()).subtract(1, 'months');
        const beginning = moment(new Date()).year(2016).month('January').date(1);
        const date = beginning;
        const dates: DateRange[] = [];
        while (date.isBefore(previousMonth)) {
            const endDate = date.clone();
            const startDate = date.clone().subtract(1, 'months');
            dates.push({
                startDate: startDate.toDate(),
                endDate: endDate.toDate(),
                displayText: startDate.format('MMMM YYYY'),
            });
            date.add(1, 'months');
        }
        return dates.reverse();
    }, []);

    useEffect(() => {
        setDates(dateOptions());
    }, [dateOptions]);

    const changeLayer = (layer: SentinelMetadata) => {
        setLayer(layer);
        onSelectLayer(layer);
    };

    const changeDateRange = (date: DateRange) => {
        setSelectedDate(date);
        onSelectDateRange(date);
    };

    const handleChangeLayer = (key: string) => {
        Analytics.Event('Satellite - Landsat', 'Changed layer', key);
        switch (key) {
            case 'TRUE_COLOR':
                changeLayer(Constants.OVERLAY_DATA.SENTINEL.TRUE_COLOR);
                break;
            case 'FALSE_COLOR':
                changeLayer(Constants.OVERLAY_DATA.SENTINEL.FALSE_COLOR);
                break;
            case 'GEOLOGY':
                changeLayer(Constants.OVERLAY_DATA.SENTINEL.GEOLOGY);
                break;
            case 'NDVI':
                changeLayer(Constants.OVERLAY_DATA.SENTINEL.NDVI);
                break;
            case 'SENTINEL_1_GRD':
            case 'SENTINEL_1_RADAR':
                changeLayer(Constants.OVERLAY_DATA.SENTINEL.SENTINEL_1_RADAR);
                break;
            default:
                throw new Error('No overlay key for: ' + key);
        }
    };

    return (
        <Container>
            <SelectIcon>
                <img src="/assets/sentinel-filter/layers.svg" />
            </SelectIcon>
            <Select
                className="first-select-option-list"
                data-testid="satellite-drawer-filter-sensor"
                value={sensor.key}
                onChange={(e) => handleChangeLayer(e.target.value)}
            >
                <option value="TRUE_COLOR">True Color</option>
                <option value="FALSE_COLOR">False Color</option>
                <option value="GEOLOGY">Geology</option>
                <option value="NDVI">Vegetation Health</option>
                <option value="SENTINEL_1_RADAR">Radar</option>
            </Select>

            <SelectIcon>
                <img src="/assets/sentinel-filter/calendar.svg" />
            </SelectIcon>
            <Select
                data-testid="satellite-drawer-filter-date"
                value={selectedDate.displayText}
                onChange={(e) => {
                    const displayText = e.target.value as string;
                    const value = dates.find((t) => t.displayText === displayText);
                    if (value) {
                        changeDateRange(value);
                    } else {
                        changeDateRange(last30Days);
                    }
                }}
            >
                <option value={last30Days.displayText}>{last30Days.displayText}</option>
                {dates.map((t, index) => {
                    return (
                        <option key={index} value={t.displayText}>
                            {t.displayText}
                        </option>
                    );
                })}
            </Select>
        </Container>
    );
};

export default SentinelFilter;

const Container = styled.div`
    pointer-events: all;
    user-select: none;
    min-height: 37px;
    width: 100%;
    display: flex;
    flex-direction: row;
`;

const Select = styled.select`
    width: 100%;
    appearance: none;
    outline-width: 0px;
    border: 1px solid rgba(255, 255, 255, 0.3);
    border-left: none;
    border-image: initial;
    border-radius: 0px 6px 6px 0px;
    display: block;
    cursor: pointer;
    pointer-events: auto;
    background-color: transparent;
    margin: 4px 4px 4px 0px;
    padding: 7px 0px 4px 4px;
    height: auto;
    color: rgba(255, 255, 255, 0.87);
    background-image: url(/assets/arrows/down-arrow.svg);
    background-position: calc(100% - 0px) center;
    background-size: 15px 15px;
    background-repeat: no-repeat;
    overflow-y: auto;
    font-size: 13px;

    &.first-select-option-list {
        margin-right: 0px;
    }

    option {
        color: rgba(255, 255, 255, 0.6);
        background-color: rgba(0, 0, 0, 0.8);
    }

    &::-webkit-scrollbar {
        width: 6px;
        background-color: #343a40;
    }
    &::-webkit-scrollbar-thumb {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: #888;
        border-radius: 6px;
    }
    &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: #343a40;
        margin-top: 2px;
        margin-bottom: 0;
    }

    :focus,
    :active {
        background-color: transparent;
        color: rgba(255, 255, 255, 0.87);
    }

    @media only screen and (max-width: 600px) {
        font-size: 14px;
        padding: 6px;
        background-position: calc(100% - 3px) center;
    }
`;

const SelectIcon = styled.div`
    height: 30px;
    border: 1px solid rgba(255, 255, 255, 0.3);
    border-right: none;
    border-image: initial;
    border-radius: 4px;
    background-repeat: no-repeat;
    background-size: 16px 16px;
    display: inline-block;
    -webkit-box-align: center;
    margin: 4px 0px 4px 4px;
    background-color: transparent;
    padding: 4px 0px 0px 5px;
    height: auto;
    border-radius: 6px 0px 0px 6px;
`;
