import { isMobileVersion } from '../../../../../lib/soar-helper';
import styled, { css, keyframes } from 'styled-components';
import { AnalyticsNote, ListingDTO } from '../../../../../api/model';
import Analytics from '../../../../../lib/user-analytics';
import ProfileAllMaps from './profile-all-maps';
import SideDrawerNested from '../../Shared/side-drawer-nested';
import MapSearchDrawListingCard from '../../ListingsDrawer/map-search-draw-listing-card';
import { useEffect, useRef, useState } from 'react';
import { useWindowHeight } from '@react-hook/window-size';
import MapSearchDrawPreviewDetails from '../../ListingsDrawer/map-search-draw-preview-details';

interface ProfileFeaturedMapsProps {
    listings: ListingDTO[];
}

const ProfileFeaturedMaps = ({ listings }: ProfileFeaturedMapsProps) => {
    const [nestedListingPreview, setNestedListingPreview] = useState<ListingDTO>();
    const [displayCardCount, setDisplayCardCount] = useState<number>(0);

    const containerRef = useRef<HTMLDivElement | null>(null);
    const screenHeight = useWindowHeight();

    useEffect(() => {
        if (containerRef?.current && listings?.length) {
            setDisplayCardCount(Math.floor(containerRef.current.clientHeight / 90));
        }
    }, [containerRef, listings?.length, screenHeight]);

    return (
        <Container ref={containerRef}>
            {listings?.length > 0 ? (
                <ProfileHeader>
                    <Title>Recent Maps</Title>
                    <SideDrawerNested
                        controlText="All Maps"
                        closeDrawer={nestedListingPreview ? true : false}
                        controlComponent={
                            <AllMapsButton onClick={() => setNestedListingPreview(undefined)}>
                                <AllMapsLabel>All Maps</AllMapsLabel>
                                <AllMapsArrow src="/assets/user-profile-logos/arrow.png" />
                            </AllMapsButton>
                        }
                    >
                        <ProfileAllMaps />
                    </SideDrawerNested>
                </ProfileHeader>
            ) : null}

            {displayCardCount > 0 &&
                listings?.slice(0, isMobileVersion ? 8 : displayCardCount).map((listing, index) => {
                    return (
                        <SideDrawerNested
                            key={`${listing.id}-${index}`}
                            closeDrawer={nestedListingPreview?.id !== listing.id}
                            width={'500px'}
                            controlText={`View ${listing.title}`}
                            controlComponent={
                                <ListingContainer
                                    key={`${index}-${listing.id}`}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        Analytics.Event('Side Drawer', 'Clicked To View', listing.id);
                                        setNestedListingPreview(listing);
                                    }}
                                    title={listing.title}
                                    isHighlighted={false}
                                    isExiting={false}
                                    index={index}
                                >
                                    <MapSearchDrawListingCard listing={listing} isUserNameDisabled={true} />
                                </ListingContainer>
                            }
                        >
                            <MapSearchDrawPreviewDetails
                                listing={listing}
                                isUserNameDisabled={true}
                                analyticNote={AnalyticsNote.USER_PROFILE}
                            />
                        </SideDrawerNested>
                    );
                })}
        </Container>
    );
};

export default ProfileFeaturedMaps;

const Container = styled.div`
    color: white;
    padding: 12px 0px 0px 8px;
    height: calc(100vh - (440px + 70px)); // profile details and nav
`;

const ProfileHeader = styled.div`
    width: 100%;
    margin-bottom: 8px;
`;

const Title = styled.span`
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 100%;
    color: #ffffff;
`;

const AllMapsButton = styled.div`
    cursor: pointer;
    float: right;
    margin-top: -22px;
    margin-right: 8px;
`;

const AllMapsLabel = styled.span`
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 100%;
    color: #ffffff;
    opacity: 0.3;
    margin-right: 5px;
`;

const AllMapsArrow = styled.img`
    margin-bottom: 4px;
    margin-left: 5px;
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
    height: 0;
  }
  to {
    opacity: 1;
    height: 48px;
  }
`;

const fadeOut = keyframes`
  from {
    opacity: 1;
    height: 48px;
  }
  to {
    opacity: 0;
    height: 0;
  }
`;

const ListingContainer = styled.a<{ isHighlighted: boolean; isExiting: boolean; index: number }>`
    cursor: pointer;
    height: 70px !important;
    width: 98%;
    min-width: 98%;
    border-radius: 6px;
    overflow: hidden;
    display: block;
    position: relative;
    margin: 0 0 5px 0;
    border: 1px solid rgba(255, 255, 255, 0.1);
    background: rgba(0, 0, 0, 0.15);
    opacity: 0;

    &,
    * {
        text-decoration: none;
    }

    ${({ isExiting, index }) =>
        css`
            animation: ${isExiting ? fadeOut : fadeIn} 0.05s ease-in-out forwards;
            animation-delay: ${index * 0.025}s;
        `}

    &:hover {
        border: 1px solid ${(props) => props.theme.color.yellow};
        text-decoration: none;
    }
`;
