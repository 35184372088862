import React from 'react';
import styled from 'styled-components';
import { ListingDTO, ListingType } from '../../../../api/model';
import ActiveMapBookmarkButton from './active-map-bookmark-button';
import ActiveMapLikeButton from './active-map-like-button';
import ActiveMapShareButton from './active-map-share-button';

interface ActiveMapShareProps {
    listing: ListingDTO;
}

const ActiveMapShare = (props: ActiveMapShareProps) => {
    // Do not show share links for satellite orders, as they are private
    if (props.listing.listingType === ListingType.ORDER) {
        return <React.Fragment />;
    }

    return (
        <DrawShareContainer>
            <DrawShareIcons>
                <ActiveMapShareButton listing={props.listing} />
                <ActiveMapLikeButton listing={props.listing} />
                <ActiveMapBookmarkButton listing={props.listing} />
            </DrawShareIcons>
        </DrawShareContainer>
    );
};

export default ActiveMapShare;

const DrawShareContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;

const DrawShareIcons = styled.div`
    display: flex;
    flex-direction: row;
    margin: 10px 0px;
    user-select: none;
`;
