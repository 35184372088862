import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { selectActiveOrder, selectActiveOrderOpacity } from '../../../../store/Map/Tasks/selectors';
import { actionSetActiveOrderOpacity } from '../../../../store/Map/Tasks/actions';

import MobileOpacityControl from '../../Shared/opacity-control';

interface ActiveOrderOpacityControlProps {
    className?: string;
}

const ActiveOrderOpacityControl = (props: ActiveOrderOpacityControlProps) => {
    const activeOrder = useSelector(selectActiveOrder);
    const activeOrderOpacity = useSelector(selectActiveOrderOpacity);

    const dispatch = useDispatch();
    const setActiveOrderOpacity = (opacity: number): void => {
        if (!activeOrder) {
            return;
        }

        dispatch(actionSetActiveOrderOpacity(opacity));
    };

    if (!activeOrder) {
        return <React.Fragment />;
    }

    return (
        <MobileOpacityControl
            className={props.className}
            opacity={activeOrderOpacity ?? 1}
            onOpacityChange={setActiveOrderOpacity}
            showOpacityLabel={true}
        />
    );
};

export default ActiveOrderOpacityControl;
