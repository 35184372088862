import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import SoarLogo from '../../Shared/soar-logo';
import { MobileButtonPrimary } from '../../Shared/button';
import { Form, FormGroup, Input, Label } from '../../Shared/form';
import { Content, ContentFooter, ScrollableContainer } from '../../Shared/containers';
import OAuthSocialLogins from '../../../Registration/OAuth2/oauth-social-logins';
import { passwordActiveUrl, passwordInActiveUrl } from '../../../Registration/registration-icons';
import { PulseLoader } from '../../../Shared/pulse-loader';
import ApiUser from '../../../../api/api-user';
import Analytics from '../../../../lib/user-analytics';
import CognitoUtil, { CognitoAuthResultType } from '../../../../lib/cognito-util';
import { actionLoginSuccess } from '../../../../store/Account/actions';
import UserHelper from '../../../../lib/user-helper';
import SoarHelper from '../../../../lib/soar-helper';

interface MobileRegisterProps {
    onLogin: () => void;
}

const MobileRegister = (props: MobileRegisterProps) => {
    const dispatch = useDispatch();
    const loginSuccess = (authResponse: CognitoAuthResultType) => dispatch(actionLoginSuccess(authResponse));

    const [email, setEmail] = useState<string>('');
    const [firstName, setFirstName] = useState<string>('');
    const [lastName, setLastName] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [error, setError] = useState<string | undefined>(undefined);
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const [acceptedTerms, setAcceptedTerms] = useState<boolean>(false);
    const [newsletterSignUp, setNewsletterSignUp] = useState<boolean>(false);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

    const validForm = () => {
        setError(undefined);
        if (!firstName || !lastName) {
            setError('Please check your name');
            return false;
        }

        if (!UserHelper.isEmailValid(email)) {
            setError('Please check your email');
            return false;
        }

        if (password.length < 6) {
            setError('Please enter a password that is at least 6 characters long');
            return false;
        }

        if (!acceptedTerms) {
            setError('You must accept the terms and conditions to register');
            return false;
        }

        if (firstName && lastName && email && password && acceptedTerms) {
            return true;
        }

        return false;
    };

    const handleSubmitRegister = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (!validForm()) {
            return;
        }

        setIsSubmitting(true);

        try {
            //@ts-ignore
            window.gtag('config', 'AW-752086192');

            //@ts-ignore
            window.gtag('event', 'conversion', { send_to: 'AW-752086192/e-4PCLXvj_kBELDZz-YC' });
        } catch {
            //do nothing for now, implemented this try catch so the integration testing will work
        }

        try {
            // TODO: Adds newsletter sign-up field to endpoint.
            await ApiUser.signup(email, password, firstName + ' ' + lastName, firstName, lastName, newsletterSignUp);

            await CognitoUtil.login(email, password).then((authResponse) => {
                loginSuccess(authResponse);
                Analytics.Event('Registration', 'Registered', `${firstName} ${lastName}`);
            });

            Analytics.Event('Mobile registration', 'Registered');
        } catch (err) {
            const message: string = err.message;
            if (message) {
                setError(message.substring(4));
            } else {
                setError('Something went wrong');
            }
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <ScrollableContainer>
            <Content>
                <RegisterSoarLogo width="60%" />
                {!SoarHelper.isSoarIOS() && !SoarHelper.isSoarAndroid() ? <OAuthSocialLogins /> : null}
                <Form onSubmit={(e) => handleSubmitRegister(e)}>
                    <FormGroup>
                        <Label>Enter your name</Label>
                        <RegisterFormName>
                            <RegisterInput
                                data-testid="first-name"
                                style={{ marginRight: '5px' }}
                                placeholder="First Name..."
                                value={firstName}
                                onChange={(e) => {
                                    setFirstName(e.target.value);
                                    setError(undefined);
                                }}
                            />
                            <RegisterInput
                                data-testid="last-name"
                                placeholder="Last Name..."
                                value={lastName}
                                onChange={(e) => {
                                    setLastName(e.target.value);
                                    setError(undefined);
                                }}
                            />
                        </RegisterFormName>
                    </FormGroup>
                    <FormGroup>
                        <Label>Email</Label>
                        <RegisterInput
                            data-testid="email"
                            type="email"
                            placeholder="Email Address"
                            value={email}
                            onChange={(e) => {
                                setEmail(e.target.value);
                                setError(undefined);
                            }}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label>Create Password</Label>
                        <RegisterInputPassword
                            data-sentry-block
                            data-testid="password"
                            type={showPassword ? 'text' : 'password'}
                            value={password}
                            placeholder="Password"
                            onChange={(e) => {
                                setPassword(e.target.value);
                                setError(undefined);
                            }}
                        />
                        <RegisterFormPasswordIcon
                            showPassword={showPassword}
                            onClick={() => setShowPassword(!showPassword)}
                        />
                    </FormGroup>
                    <FormGroup>
                        <RegisterAcceptTerms>
                            <input
                                style={{ marginRight: '10px' }}
                                type="checkbox"
                                checked={acceptedTerms}
                                onChange={() => setAcceptedTerms(!acceptedTerms)}
                            />
                            I have read, understood and agree to the
                            <RegisterLink target="_blank" href="https://about.soar.earth/terms">
                                Terms of Use
                            </RegisterLink>{' '}
                            and
                            <RegisterLink target="_blank" href="https://about.soar.earth/privacy">
                                Privacy Policy
                            </RegisterLink>
                        </RegisterAcceptTerms>

                        <RegisterAcceptTerms>
                            <input
                                style={{ marginRight: '10px' }}
                                type="checkbox"
                                checked={newsletterSignUp}
                                onChange={() => setNewsletterSignUp(!newsletterSignUp)}
                            />
                            I would like to sign up to the newsletter to receive regular updates.
                        </RegisterAcceptTerms>
                    </FormGroup>
                    {error && (
                        <RegisterError>
                            <RegisterErrorIcon />
                            {error}
                        </RegisterError>
                    )}
                    {!isSubmitting ? (
                        <RegisterSubmitButton type="submit" error={error ? true : false}>
                            CREATE ACCOUNT
                        </RegisterSubmitButton>
                    ) : (
                        <PulseLoader />
                    )}
                </Form>
            </Content>
            <MobileRegisterFooter>
                Already have an account?
                <br />
                <MobileLoginButton onClick={() => props.onLogin()}>Sign in here</MobileLoginButton>
            </MobileRegisterFooter>
        </ScrollableContainer>
    );
};

export default MobileRegister;

const MobileRegisterFooter = styled(ContentFooter)`
    text-align: center;
    margin-bottom: 64px;
`;

const MobileLoginButton = styled(MobileButtonPrimary)`
    margin: 8px 0;
    width: 100%;
`;

const RegisterSoarLogo = styled(SoarLogo)`
    margin: 0 auto 32px;
`;

const RegisterFormName = styled.div`
    display: inline-flex;
`;
const RegisterAcceptTerms = styled.div`
    color: ${(props) => props.theme.color.white};
    font-size: ${(props) => props.theme.fontSize.normal};
    text-align: left;
    margin: 0 0 12px 12px;
`;

const RegisterInput = styled(Input)``;
const RegisterInputPassword = styled(RegisterInput)`
    padding-right: 35px;
`;

interface PasswordIconProps {
    showPassword: boolean;
}

const RegisterFormPasswordIcon = styled.div<PasswordIconProps>`
    background-image: url(${(props) => (props.showPassword ? passwordActiveUrl : passwordInActiveUrl)});
    background-repeat: no-repeat;
    width: 25px;
    height: 20px;
    float: right;
    margin-top: -30px;
    margin-right: 5px;
    opacity: 0.75;
`;

const RegisterError = styled.div`
    color: red !important;
    -webkit-text-fill-color: red;
    padding: 10px;
    padding-top: 0px;
    display: flex;
`;

const RegisterErrorIcon = styled.div`
    background-image: url("data:image/svg+xml,%3Csvg width='15' height='15' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M20 17V29' stroke='%23FF0000' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M20 11V13' stroke='%23FF0000' stroke-linecap='round' stroke-linejoin='round'/%3E%3Ccircle cx='20' cy='20' r='19.5' stroke='%23FF0000'/%3E%3C/svg%3E ");
    background-position: center;
    background-repeat: no-repeat;
    width: 20px;
    height: 20px;
    margin-right: 10px;
`;

interface ErrorProps {
    error: boolean;
}

const RegisterSubmitButton = styled(MobileButtonPrimary)<ErrorProps>`
    margin: 0 auto;
    margin-top: ${(props) => (props.error ? '0px' : '20px')};
    background-color: gray;
    width: 100%;

    :not(:disabled) {
        cursor: pointer;
        background-color: #eed926 !important;
        -webkit-text-fill-color: black;
        color: black;
    }
`;

const RegisterLink = styled.a`
    color: #eed926 !important;
    -webkit-text-fill-color: #eed926 !important;
    margin-left: 3px;
    text-decoration: underline !important;
    cursor: pointer;
`;
