import { LatLngBounds } from 'leaflet';
import { SentinelImageDimension } from '../../../api/model';
import SatelliteUtil from '../../../lib/satellite-util';
import { SideDrawerMode } from '../../../store/SideDrawer/model';

export interface AoiTextProps {
    color: string;
    text: string;
}

export const satelliteAOIText = (aoi: LatLngBounds, sideDrawerMode: SideDrawerMode): AoiTextProps => {
    const isLandsat = sideDrawerMode === SideDrawerMode.SATELLITE_LANDSAT;
    const isSentinel = sideDrawerMode === SideDrawerMode.SATELLITE_SENTINEL;

    // Implements the same current workflow we have but could be improved
    if (isLandsat) {
        const quality: SentinelImageDimension[] = SatelliteUtil.landsatQualityEvaluation(aoi);
        return landsatAOIText(quality);
    } else if (isSentinel) {
        const quality: SentinelImageDimension[] = SatelliteUtil.sentinelQualityEvaluation(aoi);
        return sentinelAOIText(quality);
    } else {
        return {
            color: 'green',
            text: '',
        };
    }
};

const sentinelAOIText = (quality: SentinelImageDimension[]): AoiTextProps => {
    if (quality[0].isAvailable) {
        //10m Resolution
        return {
            color: 'green',
            text: 'Best quality',
        };
    } else if (quality[1].isAvailable) {
        //20m Resolution
        return {
            color: 'yellow',
            text: 'Medium quality',
        };
    } else if (quality[2].isAvailable) {
        //50m Resolution
        return {
            color: 'orange',
            text: 'Low quality',
        };
    } else if (quality[3].isAvailable) {
        //100m Resolution
        return {
            color: 'red',
            text: 'Worst quality',
        };
    } else {
        return {
            color: 'red',
            text: 'Area of Interest is too large',
        };
    }
};

const landsatAOIText = (quality: SentinelImageDimension[]): AoiTextProps => {
    // Landsat based on - Constants.OVERLAY_DATA.LANDSAT.LANDSAT_8.resolution
    const landsatResMeters = quality[0];
    if (
        landsatResMeters.heightPixels < landsatResMeters.maxPixels &&
        landsatResMeters.widthPixels < landsatResMeters.maxPixels &&
        landsatResMeters.isAvailable
    ) {
        return {
            color: 'green',
            text: '',
        };
    } else {
        return {
            color: 'red',
            text: 'Area of Interest is too large',
        };
    }
};

export const isValidAOI = (aoi: LatLngBounds): boolean => {
    if (
        aoi.getNorthEast().lat !== 0 &&
        aoi.getNorthEast().lng !== 0 &&
        aoi.getSouthWest().lat !== 0 &&
        aoi.getSouthWest().lng !== 0
    ) {
        return true;
    }
    return false;
};
