import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    selectSentinelOpacity,
    selectSentinelSelectedFeature,
    selectSentinelSelectedFeatureOpacity,
} from '../../../store/Map/Sentinel/selectors';
import MobileOpacityControl from '../Shared/opacity-control';
import { actionSentinelChangeOpacity, actionSentinelSelectFeatureOpacity } from '../../../store/Map/Sentinel/actions';

interface SatelliteLayerOpacityControlProps {
    className?: string;
}

const SatelliteLayerOpacityControl = (props: SatelliteLayerOpacityControlProps) => {
    const selectedSentinelFeature = useSelector(selectSentinelSelectedFeature);
    const sentinelSelectedFeatureOpacity = useSelector(selectSentinelSelectedFeatureOpacity);
    const sentinelOpacity = useSelector(selectSentinelOpacity);

    const dispatch = useDispatch();

    const opacity = (selectedSentinelFeature && sentinelOpacity[selectedSentinelFeature?.id]) ?? 1.0;

    useEffect(() => {
        if (selectedSentinelFeature && sentinelSelectedFeatureOpacity && !sentinelOpacity[selectedSentinelFeature.id]) {
            dispatch(
                actionSentinelChangeOpacity({
                    id: selectedSentinelFeature.id,
                    opacity: sentinelSelectedFeatureOpacity,
                })
            );
        }
    }, [dispatch, sentinelSelectedFeatureOpacity, selectedSentinelFeature, sentinelOpacity]);

    const setSatelliteLayerOpacity = (opacity: number): void => {
        if (!selectedSentinelFeature) {
            return;
        }

        dispatch(actionSentinelChangeOpacity({ id: selectedSentinelFeature.id, opacity }));
        dispatch(actionSentinelSelectFeatureOpacity(opacity));
    };

    if (!selectedSentinelFeature) {
        return <React.Fragment />;
    }

    return (
        <MobileOpacityControl
            className={props.className}
            opacity={opacity ?? 1}
            onOpacityChange={setSatelliteLayerOpacity}
        />
    );
};

export default SatelliteLayerOpacityControl;
