import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { selectLoggedIn, selectMyProfile } from '../../../store/Account/selectors';
import UserAvatar from '../../Shared/Avatar/user-avatar';

interface TabBarProfileItemProps {
    isActive: boolean;
    onClick: () => void;
}

const TabBarProfileItem = (props: TabBarProfileItemProps) => {
    const userLoggedIn = useSelector(selectLoggedIn);
    const myProfile = useSelector(selectMyProfile);

    return (
        <TabBarIconContainer onClick={() => props.onClick()}>
            {userLoggedIn && myProfile ? (
                <React.Fragment>
                    <UserAvatar user={myProfile} margin="0 auto" diameter={35} />
                    <TabBarIconText style={{ color: props.isActive ? '#eed926' : 'white' }}>Profile</TabBarIconText>
                </React.Fragment>
            ) : (
                <React.Fragment>
                    <UserAvatar user={myProfile} margin="0 auto" diameter={35} />
                    <TabBarIconText style={{ color: props.isActive ? '#eed926' : 'white' }}>Profile</TabBarIconText>
                </React.Fragment>
            )}
        </TabBarIconContainer>
    );
};

export default TabBarProfileItem;

const TabBarIconContainer = styled.div`
    margin-top: 6px;
    flex: 1;
    display: flex;
    flex-direction: column;
`;

const TabBarIconText = styled.span`
    text-align: center;
    font-size: ${(props) => props.theme.fontSize.normal};
`;
