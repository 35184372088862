import styled from 'styled-components';

interface ImageAnnotationOpacitySliderProps {
    opacity: number;
    handleOpacity: (opacity: number) => void;
}

const ImageAnnotationOpacitySlider = (props: ImageAnnotationOpacitySliderProps) => {
    return (
        <SliderContainer>
            <SliderInputContainer>
                <Slider
                    type="range"
                    value={props.opacity * 100}
                    step={0.01}
                    onChange={(e) => {
                        const opacity = parseInt(e.target.value) / 100;
                        props.handleOpacity(opacity);
                    }}
                />
            </SliderInputContainer>
            <Percentage>{`${(props.opacity * 100).toFixed()}%`}</Percentage>
        </SliderContainer>
    );
};

export default ImageAnnotationOpacitySlider;

const SliderContainer = styled.div`
    display: flex;
    flex-direction: row;
`;

const SliderInputContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
`;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const Slider = styled.input<any>`
    margin: 22px 0 0 12px;

    width: 100%;
    background: linear-gradient(
        to left,
        rgba(255, 255, 255, 0.3) ${(props) => 100 - props.value}%,
        #eed926 ${(props) => 100 - props.value}%
    );
    height: 2px;
    padding: 0;
    box-shadow: none !important;
    border: none !important;
    appearance: none;
    outline: none;
    border-radius: 5px;

    &::-webkit-slider-thumb {
        appearance: none;
        -webkit-appearance: none;
        background: #eed926;
        height: 12px;
        width: 12px;
        border-radius: 50%;
        cursor: pointer;
    }

    &:focus {
        background: linear-gradient(
            to left,
            rgba(255, 255, 255, 0.3) ${(props) => 100 - props.value}%,
            #eed926 ${(props) => 100 - props.value}%
        );
    }
`;

const Percentage = styled.span`
    font-size: 12px;
    text-align: center;
    border: 1px solid rgba(255, 255, 255, 0.2);
    color: white;
    padding: 1px 3px;
    margin: 12px 2px 2px 8px;
    height: auto;
    width: 38px;
    height: 22px;
`;
