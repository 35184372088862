import React, { ReactNode, useEffect, useState } from 'react';
import styled from 'styled-components';
import ApiListings, { GetListingsParams } from '../../../../api/api-listings';
import { ListingDTO } from '../../../../api/model';
import { PulseLoader } from '../../../Shared/pulse-loader';
import SearchNoResults from './search-no-results';
import SearchResultsMasonary from './search-results-masonary';

const SMALL_WIDTH = 600;

const INITIAL_LIMIT_SMALL = 20;
const INITIAL_LIMIT = 40;
const LIMIT = 20;

interface SearchResultsProps {
    selectedSearchTerm?: string;
    userId?: string;
    children?: ReactNode;
    fromProfile?: boolean;
    resultsContainer?: string;
}

const SearchResults = (props: SearchResultsProps) => {
    const [isNoItems, setIsNoItems] = useState(false);
    const [items, setItems] = useState<ListingDTO[]>([]);
    const [isLoading, setIsLoading] = useState(true);

    const fetchListings = (limit: number, offset: number) => {
        setIsLoading(true);
        const searchParams: GetListingsParams = {
            keywords: props.selectedSearchTerm ? props.selectedSearchTerm : undefined,
            userId: props.userId ? props.userId : undefined,
            offset: offset,
            limit: limit,
        };

        ApiListings.cancelGetListings('Cancel search results changed');
        ApiListings.getListings(searchParams)
            .then((res) => {
                // Filter duplicates
                const newItems = res.listings.filter((t) => !items.includes(t));
                if (res.listings.length === 0 && newItems.length === 0) {
                    setIsNoItems(true);
                    setIsLoading(false);
                    return;
                }

                setIsNoItems(false);
                setItems((current) => [...current, ...newItems]);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    useEffect(() => {
        const isWindowSmall = window.innerWidth < SMALL_WIDTH;
        setItems([]);
        const limit = isWindowSmall ? INITIAL_LIMIT_SMALL : INITIAL_LIMIT;
        fetchListings(limit, 0);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.selectedSearchTerm]);

    const maybeLoadMore = () => {
        fetchListings(items.length + LIMIT, items.length);
    };
    return (
        <React.Fragment>
            {isLoading && !items.length && (
                <MobileSearchLoading>
                    <PulseLoader />
                </MobileSearchLoading>
            )}
            {items.length === 0 && !isLoading && isNoItems ? props.fromProfile ? null : <SearchNoResults /> : null}
            {items.length > 0 ? (
                <SearchResultsMasonary
                    items={items}
                    maybeLoadMore={maybeLoadMore}
                    isNoItems={isNoItems}
                    resultsContainer={props.resultsContainer}
                >
                    {props.children}
                </SearchResultsMasonary>
            ) : null}
        </React.Fragment>
    );
};

export default SearchResults;

export const MobileSearchResultTitle = styled.div`
    font-size: 16px;
    color: white;
    margin: 10px 0px;

    span {
        color: #eed926;
    }
`;

interface MobileFilterItemProps {
    borderColor?: string;
    backgroundColor?: string;
    fontColor?: string;
}

export const MobileFilterItem = styled.span<MobileFilterItemProps>`
    border-radius: 6px;
    margin: 4px;
    padding: 2px 6px 4px 6px;
    text-align: center;
    font-size: 1rem;
    cursor: pointer;
    border: 1px solid ${(props) => (props.borderColor ? props.borderColor : '#00a2ff')};
    background: ${(props) => (props.backgroundColor ? props.backgroundColor : '#0f0f0f96')};
    color: ${(props) => (props.fontColor ? props.fontColor : 'white')};
    display: block;
    min-width: fit-content;
`;

const MobileSearchLoading = styled.div`
    position: absolute;
    width: 100%;
    margin: 0 auto;
    top: 50dvh;
`;
