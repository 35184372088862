import { useCallback, useEffect, useState } from 'react';
import L from 'leaflet';
import styled from 'styled-components';
import Register from '../Registration/register';
import { OAuthButtonThemes } from '../Registration/OAuth2/Shared/oauth-button';
import ApiAnalytics from '../../api/api-analytics';
import UriHelper from '../../lib/uri-helper';
import { useDispatch, useSelector } from 'react-redux';
import { selectLoggedIn } from '../../store/Account/selectors';
import SoarHelper from '../../lib/soar-helper';
import JoinCarousel from './Join/join-carousel';
import JoinContent, { ContentHeader, ContentFeature, ContentFeatures } from './Join/join-content';
import JoinTrustList, { ContentBelievers } from './Join/join-trust-list';
import { actionFlyTo } from '../../store/App/actions';

export const JoinPageParameters = [
    { value: '1', label: 'Terrain' },
    { value: '2', label: 'Climate' },
    {
        value: 'au-geology',
        label: 'AU Geology',
        flyTo: new L.LatLng(-27.5545931734138, 125.59783752708246),
        flyToZoom: 5.15,
        search: 'Australian Geology',
    },
    {
        value: 'us-aerial',
        label: 'US Aerial',
        flyTo: new L.LatLng(38.711173177351284, -106.49833972000289),
        flyToZoom: 5.15,
        search: 'US Aerial',
    },
];

const Connect = () => {
    const dispatch = useDispatch();
    const isLoggedIn = useSelector(selectLoggedIn);
    const [hasPageJustLoaded, setHasPageJustLoaded] = useState(true);
    const [email, setEmail] = useState<string>('');
    const [label, setLabel] = useState<string | undefined>(undefined);
    const [hasLabelRead, setHasLabelRead] = useState(false);

    const flyTo = useCallback(
        (latlng: L.LatLng, zoom?: number) => dispatch(actionFlyTo(latlng, zoom, true)),
        [dispatch]
    );

    const handlePageLoadedWhileAlreadyLoggedIn = () => {
        ApiAnalytics.postAnalyticsEvent('JoinPage', 'User already logged in', label);

        if (label) {
            handleFlyTo(label);
        }
        handleRedirect(label);
    };

    const handleSuccessfulRegistration = () => {
        ApiAnalytics.postAnalyticsEvent('JoinPage', 'Registered', label);

        if (label) {
            handleFlyTo(label);
        }
        handleRedirect(label);
    };

    const handleFlyTo = (label: string) => {
        const joinPageParameter = JoinPageParameters.find((param) => param.label === label);

        if (joinPageParameter?.flyTo) {
            flyTo(joinPageParameter.flyTo, joinPageParameter?.flyToZoom);
        }
    };

    const handleRedirect = (label?: string) => {
        if (label) {
            const joinPageParameter = JoinPageParameters.find((param) => param.label === label);

            if (joinPageParameter?.search) {
                UriHelper.navigateToPath('/maps', { q: joinPageParameter?.search });
                return;
            }
        }

        UriHelper.navigateToPath('/maps');
    };

    useEffect(() => {
        setTimeout(() => {
            setHasPageJustLoaded(false);
        }, 1000);
    }, []);

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const parameter = urlParams.get('q');
        const label = JoinPageParameters.find((param) => param.value === parameter)?.label;

        setLabel(label);
        setHasLabelRead(true);
        ApiAnalytics.postAnalyticsEvent('JoinPage', 'View', label);
    }, []);

    useEffect(() => {
        if (isLoggedIn && hasLabelRead) {
            if (hasPageJustLoaded) {
                handlePageLoadedWhileAlreadyLoggedIn();
            } else {
                handleSuccessfulRegistration();
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoggedIn, hasLabelRead]);

    const isMobile = SoarHelper && SoarHelper.isMobile();

    return (
        <ConnectPage isMobile={isMobile}>
            <ConnectContainer>
                <ConnectContent>
                    <JoinContent type={label ?? ''} />
                    <JoinTrustList type={label ?? ''} />
                    <JoinCarousel type={label ?? ''} />
                </ConnectContent>
                <ConnectAccess>
                    <ConnectAccessHeader>Instant Access To Online Maps 👇</ConnectAccessHeader>
                    <ConnectAccessContent>
                        <ConnectAccessFormContainer>
                            <Register
                                email={email}
                                onEmailChange={(email) => setEmail(email)}
                                onClose={() => ''}
                                handleClickLogin={() => ''}
                                onRegisterSuccess={() => ''}
                                hideFooter={true}
                                theme={OAuthButtonThemes.LIGHT}
                                submitText="Start Exploring Now!"
                            />
                        </ConnectAccessFormContainer>
                    </ConnectAccessContent>
                </ConnectAccess>
            </ConnectContainer>
        </ConnectPage>
    );
};

export default Connect;

const ConnectContent = styled.div`
    flex: 1 1 65%;
    padding: 0 20px;
`;

const ConnectAccess = styled.div`
    flex: 1 0 35%;
    display: flex;
    flex-direction: column;
    align-self: flex-start;

    border: 1px solid ${(props) => props.theme.color.lightGray};
    border-radius: 4px;
    overflow: hidden;
`;

const ConnectAccessHeader = styled.h2`
    padding: 20px 0;

    font-size: 1.65rem;
    font-weight: 700;

    color: ${(props) => props.theme.color.white};

    background-color: ${(props) => props.theme.color.black};
`;

const ConnectAccessContent = styled.div`
    padding: 10px 25px;
`;

const ConnectAccessFormContainer = styled.div``;

const ConnectContainer = styled.div`
    display: flex;
    flex-direction: row;

    margin: 16px auto;
    width: 98vw;
    max-width: 1200px;
    gap: 10px;
`;

const ConnectPage = styled.div<{ isMobile: boolean }>`
    margin-top: ${(props) => (!props.isMobile ? `70px` : null)};
    height: ${(props) => (!props.isMobile ? `calc(100vh - 70px)` : `100vh`)};
    padding-top: 35px;
    background-color: ${(props) => props.theme.color.white};

    position: relative;

    overflow-y: auto;

    &::-webkit-scrollbar-track {
        background-color: transparent;
    }
    &::-webkit-scrollbar {
        width: 6px;
        background-color: transparent;
    }
    &::-webkit-scrollbar-thumb {
        background-color: ${(props) => props.theme.color.yellow};
        border-radius: 4px;
    }

    ${(props) => {
        if (props.isMobile) {
            return `
            ${ConnectContainer} {
                flex-direction: column;

                ${ConnectContent} {
                    padding: 0 10px;

                    ${ContentHeader} {
                        font-size: 2.5rem;
                    }

                    ${ContentFeatures} {
                        margin: 30px 10px 0;

                        ${ContentFeature} {
                            font-size:1rem;
                        }
                    }

                    ${ContentBelievers} {
                        flex-direction: column;
                        gap: 8px;
                        align-items: center;
                    }
                }
            }`;
        }
        return;
    }}
`;
