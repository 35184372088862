import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Analytics from '../../../lib/user-analytics';
import { deleteAllAnnotationsAction, setSelectedAnnotationAction } from '../../../store/Annotations/actions';
import { lightDropShadow } from '../../../styles/style';
import styled from 'styled-components';
import { actionClearStoary, actionSetStoaryBuilderActive } from '../../../store/Map/Stoaries/actions';
import UriHelper from '../../../lib/uri-helper';
import { SideDrawerMode } from '../../../store/SideDrawer/model';
import { selectAnnotationCount } from '../../../store/Annotations/selectors';
import DeleteConfirmModal from './DeleteAll/delete-confirm-modal';
import { selectSentinelSelectedFeature } from '../../../store/Map/Sentinel/selectors';
import { selectStoaryId } from '../../../store/Map/Stoaries/selectors';

interface AnnotationsExitProps {
    editMode: boolean;
    setEditMode: (editMode: boolean) => void;
}

const AnnotationsExit = ({ editMode, setEditMode }: AnnotationsExitProps) => {
    const [showConfirmExit, setShowConfirmExit] = useState(false);
    const annotationCount = useSelector(selectAnnotationCount);

    const activeSentinelFeature = useSelector(selectSentinelSelectedFeature);
    const stoaryId = useSelector(selectStoaryId);

    const dispatch = useDispatch();

    const handleExit = () => {
        dispatch(setSelectedAnnotationAction(undefined));
        dispatch(actionSetStoaryBuilderActive(false));
        dispatch(actionClearStoary());

        dispatch(deleteAllAnnotationsAction());

        if (!stoaryId && activeSentinelFeature) {
            UriHelper.navigateToSatellite(activeSentinelFeature);
        } else {
            UriHelper.navigateToDrawer(SideDrawerMode.MAPS);
        }

        setEditMode(false);
        Analytics.Event('Draw Tools - Project', `Clicked exit draw tools without project`);
    };

    useEffect(() => {
        return () => {
            dispatch(actionSetStoaryBuilderActive(false));
        };
    }, [dispatch]);

    return (
        <React.Fragment>
            {editMode ? (
                <ExitButton
                    data-tooltip-id={`control-tooltip`}
                    data-tooltip-content={`Exit`}
                    onClick={() => {
                        if (annotationCount > 0) {
                            setShowConfirmExit(true);
                        } else {
                            handleExit();
                        }
                    }}
                    className="annotation-control-icon"
                    data-testid="annotation-control-exit"
                >
                    Exit
                </ExitButton>
            ) : null}

            <DeleteConfirmModal
                isOpen={showConfirmExit}
                title={'Close Stoary'}
                message={`Are you sure you want to close this Stoary? Any unsaved changes will be lost.`}
                confirmText="CLOSE"
                onToggle={() => setShowConfirmExit(!showConfirmExit)}
                onConfirm={handleExit}
            />
        </React.Fragment>
    );
};

export default AnnotationsExit;

const ExitButton = styled.button`
    width: 45px;
    border-radius: 6px;
    width: 60px !important;
    text-align: center;
    text-transform: uppercase;
    color: white;

    margin: 0 auto;
    border: none;
    background: ${(props) => props.theme.color.transparentDark};
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    outline: none;

    user-select: ${(props) => (props.disabled ? 'none' : 'auto')};
    cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};

    ${lightDropShadow};

    &:hover {
        background: ${(props) => props.theme.color.gray};
    }
`;
