import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled, { keyframes } from 'styled-components';
import ApiSocial from '../../../../api/api-social';
import { ListingDTO } from '../../../../api/model';
import MathUtil from '../../../../lib/math-util';
import Analytics from '../../../../lib/user-analytics';
import { selectLoggedIn } from '../../../../store/Account/selectors';
import { actionShowLoginDialog } from '../../../../store/App/actions';
import { actionFetchLikedMaps } from '../../../../store/Map/Favourites/actions';
import { selectFavouriteMaps } from '../../../../store/Map/Favourites/selectors';

interface ActiveMapLikeButtonProps {
    listing: ListingDTO;
}

const ActiveMapLikeButton = (props: ActiveMapLikeButtonProps) => {
    const likedMaps = useSelector(selectFavouriteMaps);
    const isLoggedIn = useSelector(selectLoggedIn);
    const dispatch = useDispatch();

    const [likes, setLikes] = useState(props.listing.totalLikes ?? 0);
    const [isLikedByUser, setIsLikedByUser] = useState(false);

    useEffect(() => {
        setIsLikedByUser(likedMaps.find((t) => t.id === props.listing.id) ? true : false);
    }, [likedMaps, props.listing.id]);

    const handleClickedLike = async () => {
        if (!isLoggedIn) {
            dispatch(actionShowLoginDialog(true));
            return;
        }

        try {
            let newLikes: number;
            if (isLikedByUser) {
                newLikes = (await ApiSocial.unlikeListing(props.listing.id)).totalLikes;
                Analytics.Event('Mobile', `Clicked Like', ${props.listing.id}`);
            } else {
                newLikes = (await ApiSocial.likeListing(props.listing.id)).totalLikes;
                Analytics.Event('Mobile', `Clicked Remove Like', ${props.listing.id}`);
            }
            setLikes(newLikes);

            setIsLikedByUser(!isLikedByUser);
            dispatch(actionFetchLikedMaps());
        } catch (err) {
            // TODO: Prevent the user from hitting the 'like' api too quickly, which throws a 500
            console.log(err.message);
        }
    };

    const formattedLikes = MathUtil.formatNumber(likes);

    return (
        <HeartContainer textLength={formattedLikes.length} onClick={handleClickedLike}>
            <HeartIcon
                onClick={handleClickedLike}
                isLiked={isLikedByUser}
                className={isLikedByUser ? 'fas fa-heart liked' : 'far fa-heart'}
                title="Like this map"
            />
            <HeartValue
                data-testid="map-likes"
                title={`${likes.toLocaleString()} likes`}
                onClick={handleClickedLike}
                textLength={formattedLikes.length}
            >
                {formattedLikes}
            </HeartValue>
        </HeartContainer>
    );
};

export default ActiveMapLikeButton;

interface HeartIconProps {
    isLiked: boolean;
}

const HeartPulseAnimation = keyframes`
    0% {
        transform: scale( 1 );
    } 25% {
        transform: scale( 1.25 );
    } 50% {
        transform: scale( 1 );
    } 75% {
        transform: scale( 1.25 );
    } 100% {
        transform: scale( 1 );
    }
`;

const HeartContainer = styled.div<{ textLength: number }>`
    background: rgba(0, 0, 0, 0.4);
    backdrop-filter: blur(5px);
    border: 1px solid rgba(142, 145, 143, 0.3);
    border-radius: 6px;
    height: 40px;
    letter-spacing: 0.25px;
    min-width: 40px;
    margin: 5px;
    outline: none !important;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    max-width: 100%;
    pointer-events: auto;

    display: flex;
    flex-direction: row;
    align-items: center;
    padding-right: ${(props) => {
        switch (props.textLength) {
            case 6:
                return `28px`;
            case 5:
                return `23px`;
            case 4:
                return `18px`;
            case 3:
                return `16px`;
            case 2:
                return `11px`;
            default:
                return `7px`;
        }
    }};
`;

const HeartIcon = styled.i<HeartIconProps>`
    cursor: pointer;
    color: ${(props) => (props.isLiked ? '#FF5A5A' : 'white')};
    font-size: 20px;
    pointer-events: all;
    height: 20px;
    margin: 0px 0px 0px 5px;

    &.liked {
        animation: ${HeartPulseAnimation} 1s ease;
        animation-iteration-count: 1;
    }
`;

const HeartValue = styled.sup<{ textLength: number }>`
    cursor: pointer;
    color: white;
    top: 0;
    right: ${(props) => (props.textLength > 3 ? `-3px` : `-1px`)};
    text-shadow: 1px 1px 1px black;
    margin: 0 0 0 6px;
    font-size: 18px;
`;
