import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Col, FormGroup, Label, Row } from 'reactstrap';
import styled from 'styled-components';
import Analytics from '../../../lib/user-analytics';
import UserHelper from '../../../lib/user-helper';
import CognitoUtil from '../../../lib/cognito-util';
import { selectMyProfile } from '../../../store/Account/selectors';
import ProfileDeleteModal from '../Profile/profile-delete-modal';
import NavigationPanel from '../Shared/navigation-panel';
import { StyledButton } from '../../Shared/styled-button';
import SuccessResetPasswordModal from '../Profile/success-reset-password-modal';
import { PulseLoader } from '../../Shared/pulse-loader';
import { passwordActiveUrlDark, passwordInActiveUrlDark } from '../../Registration/registration-icons';
import { PasswordInput } from '../../Shared/form';

const AccountSettings = () => {
    const [oldPassword, setOldPassword] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [confirmPassword, setConfirmPassword] = useState<string>('');

    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [hasSubmitted, setHasSubmitted] = useState<boolean>(false);

    const handleSubmit = () => {
        setIsSubmitting(true);

        if (oldPassword === '') {
            setIsSubmitting(false);
            setError('Original password is empty');
            setPassword('');
            setConfirmPassword('');
            return;
        }

        if (!UserHelper.isPasswordValid(password)) {
            setIsSubmitting(false);
            setError('Password must be at least 6 characters');
            setPassword('');
            setConfirmPassword('');
            return;
        }

        if (oldPassword === confirmPassword) {
            setIsSubmitting(false);
            setError('Your new password is not valid');
            setPassword('');
            setOldPassword('');
            setConfirmPassword('');
            return;
        }

        if (password !== confirmPassword) {
            setIsSubmitting(false);
            setError('Confirm password does not match');
            setPassword('');
            setConfirmPassword('');
            return;
        }

        const accessToken = UserHelper.getAccessToken();
        if (accessToken) {
            CognitoUtil.updatePassword(oldPassword, confirmPassword, accessToken)
                .then(() => {
                    setIsSubmitting(false);
                    setHasSubmitted(true);
                    setPassword('');
                    setOldPassword('');
                    setConfirmPassword('');
                    Analytics.Event('My Dashboard', 'Clicked to reset password');
                })
                .catch((err) => {
                    let errorMessage = err.message;
                    if (err.name === 'NotAuthorizedException') {
                        errorMessage = 'Original password is incorrect';
                    }
                    setError(errorMessage);
                    setOldPassword('');
                    setPassword('');
                    setConfirmPassword('');
                    setIsSubmitting(false);
                });
        }
    };

    const [showOriginalPassword, setShowOriginalPassword] = useState<boolean>(false);
    const [showNewPassword, setShowNewPassword] = useState<boolean>(false);
    const [showRetryPassword, setShowRetryPassword] = useState<boolean>(false);
    const [error, setError] = useState<string | undefined>(undefined);
    const [openDeleteAccount, setOpenDeleteAccount] = useState<boolean>(false);
    const toggleDeleteAccount = () => setOpenDeleteAccount(!openDeleteAccount);
    const myProfile = useSelector(selectMyProfile);

    return (
        <React.Fragment>
            <NavigationPanel label="Account Settings"></NavigationPanel>
            <Container>
                <h3>Reset Password</h3>
                <ResetPasswordBody>
                    <FormGroup row>
                        <Label for="oldPassword" sm={3}>
                            <p>Original Password</p>
                        </Label>
                        <PasswordCol sm={9}>
                            <AccountPasswordInput
                                data-sentry-block
                                autoComplete="current-password"
                                type={showOriginalPassword ? 'text' : 'password'}
                                name={oldPassword}
                                placeholder="Enter your original password..."
                                value={oldPassword}
                                data-testid="original-password"
                                onChange={(e) => {
                                    setOldPassword(e.target.value);
                                    setError(undefined);
                                }}
                            />
                            <PasswordInputIcon
                                showPassword={showOriginalPassword}
                                onClick={() => setShowOriginalPassword(!showOriginalPassword)}
                                title={showOriginalPassword ? 'Hide password' : 'Show password'}
                            />
                        </PasswordCol>
                    </FormGroup>
                    <FormGroup row>
                        <Label for="password" sm={3}>
                            <p>New Password</p>
                        </Label>
                        <PasswordCol sm={9}>
                            <AccountPasswordInput
                                data-sentry-block
                                autoComplete="new-password"
                                type={showNewPassword ? 'text' : 'password'}
                                name={password}
                                placeholder="Enter your new password..."
                                data-testid="new-password"
                                value={password}
                                onChange={(e) => {
                                    setPassword(e.target.value);
                                    setError(undefined);
                                }}
                            />
                            <PasswordInputIcon
                                showPassword={showNewPassword}
                                onClick={() => setShowNewPassword(!showNewPassword)}
                                title={showNewPassword ? 'Hide password' : 'Show password'}
                            />
                            <PasswordNote>
                                <p>Password must be at least 6 characters</p>
                            </PasswordNote>
                        </PasswordCol>
                    </FormGroup>
                    <FormGroup row>
                        <Label for="confirmPassword" sm={3}>
                            <p>Confirm Password</p>
                        </Label>
                        <PasswordCol sm={9}>
                            <AccountPasswordInput
                                data-sentry-block
                                autoComplete="new-password"
                                type={showRetryPassword ? 'text' : 'password'}
                                name={confirmPassword}
                                placeholder="Confirm your new password"
                                data-testid="confirm-password"
                                value={confirmPassword}
                                onChange={(e) => {
                                    setConfirmPassword(e.target.value);
                                    setError(undefined);
                                }}
                            />
                            <PasswordInputIcon
                                showPassword={showRetryPassword}
                                onClick={() => setShowRetryPassword(!showRetryPassword)}
                                title={showRetryPassword ? 'Hide password' : 'Show password'}
                            />
                            {error && (
                                <Row>
                                    <PasswordModalError>
                                        <PasswordModalErrorIcon />
                                        {error}
                                    </PasswordModalError>
                                </Row>
                            )}
                        </PasswordCol>
                    </FormGroup>
                    <Row>
                        <Col sm={3} />
                        <Col sm={9} className="d-flex justify-content-end">
                            {isSubmitting && !hasSubmitted && (
                                <ResetPasswordSubmittingContainer>
                                    <PulseLoader />
                                </ResetPasswordSubmittingContainer>
                            )}
                            {!isSubmitting && !hasSubmitted && (
                                <ResetSubmitButton onClick={() => handleSubmit()}>CHANGE PASSWORD</ResetSubmitButton>
                            )}
                        </Col>
                    </Row>
                </ResetPasswordBody>
                {hasSubmitted && !isSubmitting && (
                    <SuccessResetPasswordModal
                        openSuccessModal={hasSubmitted}
                        toggle={() => setHasSubmitted(false)}
                        email={myProfile?.email}
                        onClose={() => {
                            setHasSubmitted(false);
                        }}
                    />
                )}
                <DeleteAccountContainer>
                    <h3>Delete Account</h3>
                    <DeleteNotification>
                        <p>Once you delete your account, there is no going back. Are you sure?</p>
                        <DeleteAccountButton onClick={toggleDeleteAccount}>DELETE</DeleteAccountButton>
                        {openDeleteAccount && (
                            <ProfileDeleteModal userId={myProfile?.userId} toggle={toggleDeleteAccount} />
                        )}
                    </DeleteNotification>
                </DeleteAccountContainer>
            </Container>
        </React.Fragment>
    );
};

export default AccountSettings;

const Container = styled(Col)`
    margin-top: 15px;
    margin-bottom: 15px;
    padding: 20px;
    border-radius: 6px;
    color: white;

    h3 {
        color: white;
        -webkit-text-fill-color: white;
        text-align: left;
    }
    p {
        color: rgba(255, 255, 255, 0.3);
        text-decoration: italic;
        padding: 5px 0px 0px 22px;
    }

    @media only screen and (max-width: 600px) {
        width: 100vw;
        height: 100vh;
        overflow-y: auto;
    }
`;

const ResetPasswordBody = styled.div`
    display: block;
    max-width: 800px;
    align-self: center;
    justify-content: center;
    overflow: hidden;
    margin-top: 20px;
    margin-left: 5rem;
    text-align: left;

    p {
        color: white;
    }

    @media only screen and (max-width: 600px) {
        width: 100vw;
        margin-left: -1.5rem;
        text-align: center;

        p {
            margin-left: -1.5rem;
        }
    }
`;

const ResetSubmitButton = styled(StyledButton)`
    margin: 0px 0px 0px 18px;
    display: inline-block;
    padding: 10px;

    &:hover {
        opacity: 0.6;
    }

    @media screen and (max-width: 600px) {
        margin: 0 auto;
    }
`;

const PasswordCol = styled(Col)`
    position: relative;
`;

const AccountPasswordInput = styled(PasswordInput)`
    background-color: transparent;
    max-width: 92%;
    margin: 0px 15px 0px 24px;
    padding-left: 24px;
    padding-right: 60px;
    display: inline-block;
    border: 1px solid rgba(255, 255, 255, 0.3);
    color: rgba(255, 255, 255, 0.6) !important;
    -webkit-text-fill-color: rgba(255, 255, 255, 0.6) !important;

    @media only screen and (max-width: 600px) {
        width: 80vw;
        margin: 0 auto;
    }

    :hover,
    :focus {
        background-color: transparent;
        border: 1px solid rgba(238, 217, 38, 0.6);
        box-shadow: 0 0 0 0.2rem rgba(238, 217, 38, 0.3);
        -webkit-text-fill-color: rgba(255, 255, 255, 0.6) !important;
    }

    @media only screen and (max-width: 600px) {
        max-width: 300px;
        margin: 0px 50px 0px 40px;
        display: block;
    }
`;

interface PasswordInputIconProps {
    showPassword: boolean;
}

const PasswordInputIcon = styled.span<PasswordInputIconProps>`
    background-image: url('${(props) => (props.showPassword ? passwordActiveUrlDark : passwordInActiveUrlDark)}');
    background-repeat: no-repeat;
    width: 25px;
    height: 20px;
    position: absolute;
    top: 7px;
    right: 40px;
    opacity: 0.8;
    cursor: pointer;
`;

const ResetPasswordSubmittingContainer = styled.div`
    width: 100%;
    text-align: center;
`;

const PasswordModalError = styled.div`
    color: red !important;
    -webkit-text-fill-color: red;
    display: inline-flex;
    align-items: flex-start;
    margin: 10px 0px 10px 40px;

    @media only screen and (min-width: 601px) and (max-width: 1024px) {
        margin: 0px;
    }
`;

const PasswordModalErrorIcon = styled.div`
    background-image: url("data:image/svg+xml,%3Csvg width='15' height='15' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M20 17V29' stroke='%23FF0000' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M20 11V13' stroke='%23FF0000' stroke-linecap='round' stroke-linejoin='round'/%3E%3Ccircle cx='20' cy='20' r='19.5' stroke='%23FF0000'/%3E%3C/svg%3E ");
    background-position: center;
    background-repeat: no-repeat;
    width: 20px;
    height: 20px;
    float: left;
    margin: 2px 10px 20px 10px;

    @media only screen and (max-width: 600px) {
        margin: 2px 10px 0px 10px;
    }
`;

const DeleteAccountContainer = styled.div`
    margin-top: 50px;
    align-items: flex-end;

    h3 {
        color: red;
        -webkit-text-fill-color: red;
        text-align: left;
        padding: 24px 0px 0px 10px;
    }

    @media screen and (max-width: 600px) {
        align-items: center;
        padding: 6px;

        h3 {
            margin-left: -14px;
        }
    }
`;

const DeleteNotification = styled.div`
    display: block;
    align-self: center;
    justify-content: center;
    text-align: left;
    overflow: hidden;
    margin-top: 20px;
    margin-left: 5rem;
    max-width: 800px;
    color: white;

    p {
        color: white;
    }

    @media only screen and (max-width: 600px) {
        height: 40vh;
        padding-bottom: 10%;
        margin: 0px;
    }
`;

const DeleteAccountButton = styled.button`
    background-color: #272b33;
    border: 1px solid red;
    border-radius: 4px;
    padding: 8px 25px;
    float: right;
    color: red;
    font-weight: 500;
    display: flex;

    &:hover {
        opacity: 0.6;
    }

    @media only screen and (max-width: 600px) {
        overflow-x: none;
        float: none;
        margin: 0 auto;
        text-align: center;
        display: block;
    }
`;

const PasswordNote = styled.div`
    p {
        font-style: italic;
        font-size: 14px;
        margin-bottom: 5px;
        color: white;
    }
`;
