import styled from 'styled-components';

interface TabBarItemProps {
    isActive: boolean;
    text: string;
    iconUrl: string;
    activeIconUrl: string;
    onClick: () => void;
}
const TabBarItem = (props: TabBarItemProps) => {
    return (
        <TabBarIconContainer onClick={() => props.onClick()}>
            <TabBarIcon src={props.isActive ? props.activeIconUrl : props.iconUrl} />
            <TabBarIconText style={{ color: props.isActive ? '#eed926' : 'white' }}>{props.text}</TabBarIconText>
        </TabBarIconContainer>
    );
};

export default TabBarItem;

const TabBarIconContainer = styled.div`
    margin-top: 6px;
    flex: 1;
    display: flex;
    flex-direction: column;
`;

const TabBarIcon = styled.img`
    margin: 0 auto;
    display: block;
`;

const TabBarIconText = styled.span`
    text-align: center;
    font-size: ${(props) => props.theme.fontSize.normal};
`;
