import React, { useState } from 'react';
import styled from 'styled-components';
import { ListingDTO } from '../../../../api/model';
import ListingHelper from '../../../../lib/listing-helper';
import { isMobileVersion } from '../../../../lib/soar-helper';
import SoarModal from '../../../Shared/soar-modal';
import Analytics from '../../../../lib/user-analytics';

interface ActiveMapLegendProps {
    listing: ListingDTO;
}

const ActiveMapLegend = (props: ActiveMapLegendProps) => {
    const [showModal, setShowModal] = useState(false);

    if (!props.listing.files || props.listing.files.length === 0) {
        return <React.Fragment />;
    }

    return (
        <React.Fragment>
            <SoarModal isOpen={showModal} onClose={() => setShowModal(false)} width="50vw">
                <ModalLegendContainer>
                    <ModalLegend
                        src={ListingHelper.getUrlForListingFile(
                            props.listing.files[0].listingId,
                            props.listing.files[0].id
                        )}
                    />
                </ModalLegendContainer>
            </SoarModal>

            {props.listing.files.map((attachedFile) => {
                if (attachedFile.type !== 'LEGEND') {
                    return <React.Fragment key={attachedFile.id} />;
                } else {
                    return (
                        <React.Fragment key={attachedFile.id}>
                            {isMobileVersion ? <Divider /> : null}
                            <LegendTitle>Legend</LegendTitle>
                            <LegendContainer>
                                <Legend
                                    onClick={() => {
                                        Analytics.Event('Side Drawer', `Clicked View Legend ${props.listing.id}`);
                                        setShowModal(true);
                                    }}
                                    key={attachedFile.id}
                                    src={ListingHelper.getUrlForListingFile(attachedFile.listingId, attachedFile.id)}
                                />
                            </LegendContainer>
                        </React.Fragment>
                    );
                }
            })}
        </React.Fragment>
    );
};

export default ActiveMapLegend;

const LegendContainer = styled.div`
    margin-bottom: 10px;

    @media only screen and (max-width: 600px) {
        width: 100%;
        display: flex;
        justify-content: center;
        margin-bottom: 0px;
    }
`;

const ModalLegendContainer = styled.div`
    width: 100%;
    object-fit: contain;
    text-align: center;
    padding: 10px;
`;

const ModalLegend = styled.img`
    width: calc(45vw - 10px);
    height: auto;
`;

const Legend = styled.img`
    max-width: 100%;
    cursor: pointer;

    @media only screen and (max-width: 600px) {
        padding: 8px;
    }
`;

const LegendTitle = styled.span`
    color: white;
    font-size: 1rem;
    margin: 0px 0px 10px 0px;
    display: block;

    @media only screen and (max-width: 600px) {
        font-size: 1rem;
        margin: 6px 0px 0px 8px;
    }
`;

const Divider = styled.div`
    height: 1px;
    width: 100vw;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
`;
