import React from 'react';
import { PulseLoader } from '../pulse-loader';
import SoarModal, { StyledModalBody, StyledModalFooter } from '../soar-modal';
import { StyledDarkButton, StyledDeleteButton } from '../styled-button';

interface DeleteModalProps {
    title?: string | JSX.Element;
    message?: string;
    isOpen: boolean;
    onToggle: () => void;
    onDelete: () => void;
    isDeleting?: boolean;
}

const DeleteModal = (props: DeleteModalProps) => {
    return (
        <SoarModal title={props.title || 'Delete'} isOpen={props.isOpen} toggle={props.onToggle} width="600px">
            <StyledModalBody>{props.message || 'Are you sure you want to delete this item?'}</StyledModalBody>
            <StyledModalFooter>
                {!props.isDeleting ? (
                    <React.Fragment>
                        <StyledDarkButton onClick={props.onToggle}>CANCEL</StyledDarkButton>
                        <StyledDeleteButton onClick={props.onDelete}>DELETE</StyledDeleteButton>
                    </React.Fragment>
                ) : (
                    <PulseLoader />
                )}
            </StyledModalFooter>
        </SoarModal>
    );
};

export default DeleteModal;
