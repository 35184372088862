import { useState } from 'react';
import styled from 'styled-components';
import ListingHelper from '../../../../lib/listing-helper';
import { ViewedMapSessionObject } from '../../../../lib/map-view-history-helper';
import UriHelper from '../../../../lib/uri-helper';
import Analytics from '../../../../lib/user-analytics';
import ImagePreviewFailed from '../../../Shared/image-preview-failed';
import ApiAnalytics from '../../../../api/api-analytics';
import { AnalyticsAction, AnalyticsNote } from '../../../../api/model';

interface DiscoverRecentlyViewedMapsCard {
    listing: ViewedMapSessionObject;
}

const DiscoverRecentlyViewedMapsCard = ({ listing }: DiscoverRecentlyViewedMapsCard) => {
    const [previewImageLoaded, setPreviewImageLoaded] = useState<boolean>(false);
    const [previewImageFailed, setPreviewImageFailed] = useState<boolean>(false);

    const handleSelectedListing = (listingId: number) => {
        UriHelper.navigateToPath(`/maps/${listingId}`);
        Analytics.Event('Discover', 'Selected Recently View Listing', listingId);
        ApiAnalytics.postAnalyticsListing(AnalyticsAction.VIEW, AnalyticsNote.DISCOVER, listingId);
    };

    return (
        <DiscoverCardContainer className={previewImageLoaded || previewImageFailed ? 'fade-in' : ''}>
            <ImageContainer onClick={(e) => e.preventDefault()}>
                <ImgContainer onClick={() => handleSelectedListing(listing.id)}>
                    {previewImageFailed ? (
                        <ImagePreviewFailed />
                    ) : (
                        <DiscoverCardImage
                            src={ListingHelper.getPreviewUrlForListing(listing.id, 'small')}
                            onLoad={() => setPreviewImageLoaded(true)}
                            loading="lazy"
                            onError={() => setPreviewImageFailed(true)}
                        />
                    )}
                </ImgContainer>
                <ImgDetails onClick={() => handleSelectedListing(listing.id)} data-testid="handle-image-select">
                    <ImgTitle>{listing.title}</ImgTitle>
                    <ImgAuthor>{listing.author || 'Soar User'}</ImgAuthor>
                </ImgDetails>
            </ImageContainer>
        </DiscoverCardContainer>
    );
};

export default DiscoverRecentlyViewedMapsCard;

const ImageContainer = styled.a`
    display: block;
    height: 100%;
    position: relative;
    cursor: pointer;
    overflow: hidden;

    text-decoration: none;
`;

const ImgContainer = styled.div`
    margin: 0;
    position: relative;
    vertical-align: middle;
    border-radius: 16px;
    overflow: hidden;
    max-height: 150px;
    height: 100%;
    width: 100%;

    > img {
        cursor: pointer;
        width: 100%;
        display: flex;
        overflow: hidden;
        height: 100%;
        object-fit: cover;
    }
`;

const ImgDetails = styled.div`
    color: ${(props) => props.theme.color.white};
    display: flex;
    flex-direction: column;
`;

const ImgTitle = styled.h3`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: clamp(1rem, 1.2rem, 1.2rem);
    color: ${(props) => props.theme.color.yellow};
    text-align: left;
    word-break: break-word;
    max-width: 100%;
    margin: 8px 0;
    color: ${(props) => props.theme.color.white};

    ${ImageContainer}:hover & {
        transition: all 0.3s ease-in-out;
        opacity: 1;
    }
`;

const ImgAuthor = styled.p`
    color: ${(props) => props.theme.color.yellow};
    font-weight: bold;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &:before {
        content: 'by ';
        color: ${(props) => props.theme.color.white};
        font-size: 0.8rem;
        font-weight: normal;
        margin-right: 4px;
    }
`;

const DiscoverCardContainer = styled.div`
    display: flex;
    flex-direction: column;
    opacity: 0;
    height: 240px;
    overflow: hidden;
    transition: opacity 0.5s, height 0.5s;
    padding: 8px;
    cursor: pointer;

    &.fade-in {
        ${ImgContainer} {
            box-shadow: 0px 6px 10px 0px hsla(0, 0%, 0%, 0.14), 0px 1px 18px 0px hsla(0, 0%, 0%, 0.12),
                0px 3px 5px -1px hsla(0, 0%, 0%, 0.2);
        }
        margin: 6px 3px;
        opacity: 1;
    }

    &:hover {
        img {
            opacity: 1;
            transform: scale(1.03);
            transition: 0.5s ease;
        }
    }
`;

const DiscoverCardImage = styled.img`
    max-width: 100%;
    object-fit: contain;
    background-color: hsla(0, 100%, 100%, 0.02);
    box-shadow: 0px 6px 10px 0px hsla(0, 0%, 0%, 0.14), 0px 1px 18px 0px hsla(0, 0%, 0%, 0.12),
        0px 3px 5px -1px hsla(0, 0%, 0%, 0.2);
`;
