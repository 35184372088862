import React from 'react';
import styled from 'styled-components';
import DrawToolAnalytics from './Analytics/draw-tool-analytics';

interface AnnotationButtonProps {
    id?: string;
    active: boolean;
    disabled: boolean;
    visible: boolean;
    onClick: () => void;
    iconDark: string;
    iconLight: string;
    name: string;
    layout: 'start' | 'middle' | 'end' | 'isolated';
    iconClassName?: string;
    width?: string;
    height?: string;
    iconPadding?: string;
    border?: string;
    dataTestId?: string;
}

export interface AnnotationControlProps {
    visible: boolean;
    locked: boolean;
    disabled: boolean;
}

const AnnotationControl = (props: AnnotationButtonProps) => {
    if (!props.visible) return <React.Fragment />;

    return (
        <AnnotationButton
            id={props.id}
            active={props.active}
            disabled={props.disabled}
            onClick={() => {
                DrawToolAnalytics.clickedIcon(props.name.toLowerCase());
                props.onClick();
            }}
            layout={props.layout}
            data-tooltip-id="control-tooltip"
            data-tooltip-content={props.name}
            width={props.width}
            height={props.height}
            border={props.border}
            data-testid={props.dataTestId}
        >
            <AnnotationButtonIcon
                src={props.active ? props.iconDark : props.iconLight}
                iconPadding={props.iconPadding}
                className={`${props.iconClassName} annotation-control-icon`}
            />
        </AnnotationButton>
    );
};

export default AnnotationControl;

interface AnnotationButtonStyleProps {
    layout: 'start' | 'middle' | 'end' | 'isolated';
    active: boolean;
    disabled: boolean;
    width?: string;
    height?: string;
    border?: string;
}

const AnnotationButton = styled.button<AnnotationButtonStyleProps>`
    width: ${(props) => (props.width ? props.width : '45px')};
    height: ${(props) => (props.height ? props.height : '45px')};
    border-top-left-radius: ${(props) => (props.layout === 'start' || props.layout === 'isolated' ? '6px' : '0px')};
    border-bottom-left-radius: ${(props) => (props.layout === 'start' || props.layout === 'isolated' ? '6px' : '0px')};
    border-top-right-radius: ${(props) => (props.layout === 'end' || props.layout === 'isolated' ? '6px' : '0px')};
    border-bottom-right-radius: ${(props) => (props.layout === 'end' || props.layout === 'isolated' ? '6px' : '0px')};
    margin: 0 auto;
    margin-right: ${(props) => (props.layout === 'end' || props.layout === 'isolated' ? '10px' : '0')};

    border: none;
    background: ${(props) => (props.active ? props.theme.color.yellow : props.theme.color.transparentDark)};
    display: flex;
    flex-direction: column;
    justify-content: center;
    outline: none;

    user-select: ${(props) => (props.disabled ? 'none' : 'auto')};
    cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};

    border-top: 1px solid #515151;
    border-bottom: 1px solid #515151;
    border-left: ${(props) => (props.layout === 'start' || props.layout === 'isolated' ? '1px solid #515151' : 'none')};
    border-right: ${(props) => (props.layout === 'end' || props.layout === 'isolated' ? '1px solid #515151' : 'none')};
    box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.3), 0px 23px 36px 3px rgba(0, 0, 0, 0.24),
        0px 9px 44px 8px rgba(0, 0, 0, 0.22);

    // This is to override the annotation control border button without interfering with the above border styles
    border: ${(props) => (props.border ? props.border : '')};

    &:hover {
        background: ${(props) => (props.active ? props.theme.color.yellow : props.theme.color.gray)};
    }
`;

const AnnotationButtonIcon = styled.img<{ iconPadding?: string }>`
    margin: 0 auto;
    padding: ${(props) => (props.iconPadding ? props.iconPadding : '4px')};
    display: block;
    width: 100%;
    height: 100%;

    &.text-icon {
        width: 39px;
    }
`;
