import React from 'react';
import { useSelector } from 'react-redux';
import ArrowAnnotation from '../../../MapView/Annotations/Arrow/arrow-annotation';
import { selectArrows } from '../../../../store/Annotations/Arrow/selectors';

const MobileArrows = () => {
    const arrows = useSelector(selectArrows);
    return (
        <React.Fragment>
            {arrows.map((arrow) => {
                return (
                    <ArrowAnnotation
                        key={`
                            ${arrow.id}-
                            ${arrow.options.color}-
                            ${arrow.options.dashArray}-
                            ${arrow.options.dashOffset}-
                            ${arrow.options.weight}-
                            ${arrow.endLatLng.lat}-
                            ${arrow.endLatLng.lng}-
                            ${arrow.startLatLng.lat}-
                            ${arrow.startLatLng.lng}-
                            ${arrow.zIndex}
                        `}
                        arrow={arrow}
                        isSelected={false}
                        onDeselect={() => null}
                    />
                );
            })}
        </React.Fragment>
    );
};

export default MobileArrows;
