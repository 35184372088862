import React from 'react';
import { useSelector } from 'react-redux';
import { selectRectangles } from '../../../../store/Annotations/Rectangle/selectors';
import RectangleAnnotation from '../../../MapView/Annotations/Rectangle/rectangle-annotation';

const MobileRectangle = () => {
    const rectangles = useSelector(selectRectangles);

    return (
        <React.Fragment>
            {rectangles.map((rectangle) => {
                return (
                    <RectangleAnnotation
                        key={`
                            ${rectangle.id}-
                            ${rectangle.options.color}-
                            ${rectangle.options.fillOpacity}-
                            ${rectangle.options.dashArray}-
                            ${rectangle.options.dashOffset}-
                            ${rectangle.options.weight}-
                            ${rectangle.fillPattern ? rectangle.fillPattern : ''}
                            ${rectangle.showArea}-
                            ${rectangle.showLength}-
                            ${rectangle.labelColor}-
                            ${rectangle.zIndex}-
                            ${rectangle.units}-
                            ${rectangle.bounds ? JSON.stringify(rectangle.bounds) : ''}
                        `}
                        rectangle={rectangle}
                        isSelected={false}
                        onUpdateRectangle={() => null}
                    />
                );
            })}
        </React.Fragment>
    );
};

export default MobileRectangle;
