import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Form, FormGroup, Input, Label, ModalBody, ModalFooter } from 'reactstrap';
import styled from 'styled-components';
import ApiUser from '../../api/api-user';
import Analytics from '../../lib/user-analytics';
import UserHelper from '../../lib/user-helper';
import { actionLoginSuccess } from '../../store/Account/actions';
import { StyledButton } from '../Shared/styled-button';
import CognitoUtil, { CognitoAuthResultType } from '../../lib/cognito-util';
import OAuthSocialLogins from './OAuth2/oauth-social-logins';
import { OAuthButtonThemes } from './OAuth2/Shared/oauth-button';
import { PulseLoader } from '../Shared/pulse-loader';
import { passwordActiveUrlDark, passwordInActiveUrlDark } from './registration-icons';
import { PasswordInput } from '../Shared/form';

interface RegisterProps {
    email: string;
    onEmailChange: (email: string) => void;
    onClose: () => void;
    handleClickLogin: () => void;
    onRegisterSuccess: () => void;
    hideFooter?: boolean;
    theme?: OAuthButtonThemes;
    submitText?: string;
}

const Register = (props: RegisterProps) => {
    const dispatch = useDispatch();
    const loginSuccess = (authResponse: CognitoAuthResultType) => dispatch(actionLoginSuccess(authResponse));

    const [firstName, setFirstName] = useState<string>('');
    const [lastName, setLastName] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [acceptedTerms, setAcceptedTerms] = useState<boolean>(false);
    const [newsletterSignUp, setNewsletterSignUp] = useState<boolean>(false);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [error, setError] = useState<string | undefined>(undefined);
    const [showPassword, setShowPassword] = useState<boolean>(false);

    const submitRegister = async () => {
        setIsSubmitting(true);

        try {
            //@ts-ignore
            window.gtag('config', 'AW-752086192');

            //@ts-ignore
            window.gtag('event', 'conversion', { send_to: 'AW-752086192/e-4PCLXvj_kBELDZz-YC' });
        } catch {
            //do nothing for now, implemented this try catch so the integration testing will work
        }

        try {
            // TODO: Adds newsletter sign-up field to endpoint.
            await ApiUser.signup(
                props.email,
                password,
                firstName + ' ' + lastName,
                firstName,
                lastName,
                newsletterSignUp
            );

            CognitoUtil.login(props.email, password).then((authResponse) => {
                loginSuccess(authResponse);
                Analytics.Event('Registration', 'Registered', `${firstName} ${lastName}`);
                props.onRegisterSuccess();
            });
        } catch (err) {
            const message: string = err.message;
            if (message) {
                setError(message.substring(4));
            } else {
                setError('Something went wrong');
            }
        } finally {
            setIsSubmitting(false);
        }
    };

    const handleSubmitRegister = (e) => {
        e.preventDefault();
        setError(undefined);
        if (!firstName || !lastName) {
            setError('Please check your name');
            return;
        }

        if (!UserHelper.isEmailValid(props.email)) {
            setError('Please check your email');
            return;
        }

        if (password.length < 6) {
            setError('Please enter a password that is at least 6 characters long');
            return;
        }

        if (!acceptedTerms) {
            setError('You must accept the terms and conditions to register');
            return;
        }

        if (firstName && lastName && props.email && password && acceptedTerms) {
            submitRegister();
        }
    };

    return (
        <React.Fragment>
            <RegisterModalBody selectedTheme={props.theme}>
                <OAuthSocialLogins theme={props.theme} />
                <Form onSubmit={(e) => handleSubmitRegister(e)}>
                    <FormGroup>
                        <Label for="firstName">Enter your name</Label>
                        <RegisterFormName>
                            <Input
                                data-testid="first-name"
                                style={{ marginRight: '5px' }}
                                placeholder="First Name..."
                                value={firstName}
                                onChange={(e) => {
                                    setFirstName(e.target.value);
                                    setError(undefined);
                                }}
                            />
                            <Input
                                data-testid="last-name"
                                placeholder="Last Name..."
                                value={lastName}
                                onChange={(e) => {
                                    setLastName(e.target.value);
                                    setError(undefined);
                                }}
                            />
                        </RegisterFormName>
                    </FormGroup>
                    <FormGroup>
                        <Label for="email">Email</Label>
                        <Input
                            data-testid="email"
                            type="email"
                            placeholder="Email Address"
                            value={props.email}
                            onChange={(e) => {
                                props.onEmailChange(e.target.value);
                                setError(undefined);
                            }}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label for="password">Create Password</Label>
                        <PasswordInput
                            data-sentry-block
                            data-testid="password"
                            type={showPassword ? 'text' : 'password'}
                            value={password}
                            placeholder="Password"
                            onChange={(e) => {
                                setPassword(e.target.value);
                                setError(undefined);
                            }}
                            togglePasswordVisibility={true}
                        />
                        <RegisterFormPasswordIcon
                            showPassword={showPassword}
                            onClick={() => setShowPassword(!showPassword)}
                        />
                    </FormGroup>
                    <FormGroup>
                        <RegisterAcceptTerms>
                            <input
                                style={{ marginRight: '10px' }}
                                type="checkbox"
                                checked={acceptedTerms}
                                onChange={() => setAcceptedTerms(!acceptedTerms)}
                            />
                            I have read, understood and agree to the
                            <RegisterLink target="_blank" href="https://about.soar.earth/terms">
                                Terms of Use
                            </RegisterLink>{' '}
                            and
                            <RegisterLink target="_blank" href="https://about.soar.earth/privacy">
                                Privacy Policy
                            </RegisterLink>
                        </RegisterAcceptTerms>

                        <RegisterAcceptTerms>
                            <input
                                style={{ marginRight: '10px' }}
                                type="checkbox"
                                checked={newsletterSignUp}
                                onChange={() => setNewsletterSignUp(!newsletterSignUp)}
                            />
                            I would like to sign up to the newsletter to receive regular updates.
                        </RegisterAcceptTerms>
                    </FormGroup>
                    {error && (
                        <RegisterModalError>
                            <RegisterModalErrorIcon />
                            {error}
                        </RegisterModalError>
                    )}
                    {!isSubmitting ? (
                        <RegisterSubmitButton onClick={(e) => handleSubmitRegister(e)} error={error ? true : false}>
                            {props.submitText ?? `CREATE ACCOUNT`}
                        </RegisterSubmitButton>
                    ) : (
                        <PulseLoader />
                    )}
                </Form>
            </RegisterModalBody>
            {!props.hideFooter ? (
                <RegisterModalFooter>
                    Already have an account?
                    <RegisterLink onClick={props.handleClickLogin}>Sign in here</RegisterLink>
                </RegisterModalFooter>
            ) : null}
        </React.Fragment>
    );
};

export default Register;

const RegisterModalError = styled.div`
    color: red !important;
    -webkit-text-fill-color: red;
    padding: 10px;
    padding-top: 0px;
    display: flex;
`;

const RegisterModalErrorIcon = styled.div`
    background-image: url("data:image/svg+xml,%3Csvg width='15' height='15' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M20 17V29' stroke='%23FF0000' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M20 11V13' stroke='%23FF0000' stroke-linecap='round' stroke-linejoin='round'/%3E%3Ccircle cx='20' cy='20' r='19.5' stroke='%23FF0000'/%3E%3C/svg%3E ");
    background-position: center;
    background-repeat: no-repeat;
    width: 20px;
    height: 20px;
    margin-right: 10px;
`;

const RegisterModalBody = styled(ModalBody)<{ selectedTheme?: OAuthButtonThemes }>`
    -webkit-text-fill-color: ${(props) =>
        props.selectedTheme === OAuthButtonThemes.LIGHT ? props.theme.color.black : props.theme.color.white};

    label {
        color: ${(props) =>
            props.selectedTheme === OAuthButtonThemes.LIGHT ? props.theme.color.black : `rgba(255, 255, 255, 0.87)`};
        width: 100%;
    }

    input {
        background-color: transparent;
        color: ${(props) =>
            props.selectedTheme === OAuthButtonThemes.LIGHT ? `rgba(0, 0, 0, 0.8)` : `rgba(255, 255, 255, 0.6)`};
        -webkit-text-fill-color: ${(props) =>
            props.selectedTheme === OAuthButtonThemes.LIGHT ? `rgba(0, 0, 0, 0.8)` : `rgba(255, 255, 255, 0.6)`};
        border: 1px solid
            ${(props) =>
                props.selectedTheme === OAuthButtonThemes.LIGHT
                    ? `rgb(0 0 0 / 0.3)`
                    : `rgb(255 255 255 / 0.3)`} !important;

        :hover {
            border: 1px solid rgba(238 227 8 / 0.6) !important;
            border-color: rgba(238 227 8 / 0.6) !important;
            box-shadow: 0 0 0 0.2rem rgba(238 227 8 / 0.1) !important;
            -webkit-text-fill-color: ${(props) =>
                props.selectedTheme === OAuthButtonThemes.LIGHT ? `rgba(0, 0, 0, 0.8)` : `rgba(255, 255, 255, 0.6)`};
        }

        :focus {
            background-color: transparent;
            border: 1px solid rgba(238 227 8 / 0.6) !important;
            border-color: rgba(238 227 8 / 0.6) !important;
            box-shadow: 0 0 0 0.2rem rgba(238 227 8 / 0.1) !important;
            -webkit-text-fill-color: ${(props) =>
                props.selectedTheme === OAuthButtonThemes.LIGHT ? `rgba(0, 0, 0, 0.8)` : `rgba(255, 255, 255, 0.6)`};
        }

        ::placeholder {
            color: ${(props) =>
                props.selectedTheme === OAuthButtonThemes.LIGHT ? `rgba(33, 33, 33, 0.6)` : `rgba(255, 255, 255, 0.6)`};
            -webkit-text-fill-color: ${(props) =>
                props.selectedTheme === OAuthButtonThemes.LIGHT ? `rgba(33, 33, 33, 0.6)` : `rgba(255, 255, 255, 0.6)`};
        }
    }
`;

const RegisterFormName = styled.div`
    display: inline-flex;
`;

interface PasswordIconProps {
    showPassword: boolean;
}

const RegisterFormPasswordIcon = styled.div<PasswordIconProps>`
    background-image: url('${(props) => (props.showPassword ? passwordActiveUrlDark : passwordInActiveUrlDark)}');
    background-repeat: no-repeat;
    width: 25px;
    height: 20px;
    float: right;
    margin-top: -26px;
    margin-right: 5px;
`;

const RegisterAcceptTerms = styled.div`
    color: rgba(255, 255, 255, 0.87);
    font-size: 12px;
    text-align: left;
    margin: 0 0 12px 12px;
`;

interface ErrorProps {
    error: boolean;
}

const RegisterSubmitButton = styled(StyledButton)<ErrorProps>`
    margin: 0 auto;
    margin-top: ${(props) => (props.error ? '0px' : '20px')};
    margin-bottom: 10px;
    background-color: gray;
    width: 100%;

    :not(:disabled) {
        cursor: pointer;
        background-color: #eed926 !important;
        -webkit-text-fill-color: black;
    }
`;

const RegisterModalFooter = styled(ModalFooter)`
    color: rgba(255, 255, 255, 0.87);
    border-top: 1px solid rgb(255 255 255 / 0.3);
    display: block;
    text-align: center;
`;

const RegisterLink = styled.a`
    color: #eed926 !important;
    -webkit-text-fill-color: #eed926 !important;
    margin-left: 3px;
    text-decoration: underline !important;
    cursor: pointer;
`;
