import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { NavLink as RRNavLink } from 'react-router-dom';
import {
    Dropdown as BootstrapDropdown,
    DropdownItem as BootstrapDropdownItem,
    DropdownMenu as BootstrapDropdownMenu,
    DropdownToggle as BootstrapDropdownToggle,
    NavItem as BootstrapNavItem,
    NavLink as BootstrapNavLink,
} from 'reactstrap';
import styled from 'styled-components';

import { actionLogoutUser } from '../../store/Account/actions';

import { UserDTO } from '../../api/model';
import Analytics from '../../lib/user-analytics';
import SignOutModal from '../Shared/Modal/sign-out';

import UriHelper from '../../lib/uri-helper';
import { lightDropShadow } from '../../styles/style';
import UserAvatar from '../Shared/Avatar/user-avatar';
import SoarHelper from '../../lib/soar-helper';

interface NavbarProfileProps {
    userProfile: UserDTO;
}

const NavbarProfile = (props: NavbarProfileProps) => {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [isUserLogout, setIsUserLogout] = useState(false);
    const isSoar = SoarHelper.isSoar();

    const dispatch = useDispatch();

    // I want to be able to click either the toggle or the profile to toggle.
    // We need this timeout latch to prevent a close triggering twice.
    // Placing the toggle only on the NavItem breaks dismiss when clicking outside the dropdown
    const [disableToggle, setDisableToggle] = useState(false);

    const toggleDropdown = () => {
        if (!disableToggle) {
            setDropdownOpen(!dropdownOpen);
            setDisableToggle(true);
            setTimeout(() => {
                setDisableToggle(false);
            }, 100);
        }
    };

    const profileName = props.userProfile.name ? props.userProfile.name : 'Soar User';

    const handleViewDashboard = () => {
        UriHelper.navigateToPath('/dashboard');
        Analytics.Event('Navigation', 'Clicked Dashboard');
    };

    const handleViewDashboardDrawProjects = () => {
        UriHelper.navigateToPath('/dashboard/my-stoaries');
        Analytics.Event('Navigation', 'Clicked Draw Stoaries');
    };

    const handleViewImagery = () => {
        UriHelper.navigateToPath(isSoar ? '/dashboard/my-imagery' : '/dashboard/data');
        Analytics.Event('Navigation', 'Clicked Imagery');
    };

    const handleLogout = () => {
        setIsUserLogout(true);
    };

    const onClickLogout = () => {
        Analytics.Event('Navigation', 'Clicked sign out');
        dispatch(actionLogoutUser());
        window.location.href = `${window.location.protocol}//${window.location.host}`;
    };

    return (
        <NavContainer isOpen={dropdownOpen}>
            <NavItem onClick={toggleDropdown} data-testid="navbar-profile-dropdown-toggle">
                <NavLink isSoar={isSoar}>
                    <AvatarNameLink title={profileName}>{profileName}</AvatarNameLink>

                    <UserAvatar user={props.userProfile} diameter={30} margin="0" />
                </NavLink>

                <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown} data-testid="navbar-profile-dropdown">
                    <DropdownToggle color="link" />

                    <DropdownMenu end>
                        <DropdownHeader header>
                            Signed in as
                            <br />
                            <Strong>{profileName}</Strong>
                        </DropdownHeader>
                        <DropdownItem divider />
                        <DropdownItemLink>
                            <NavLinkDropdown
                                onClick={handleViewDashboard}
                                to="/dashboard"
                                tag={RRNavLinkStyled}
                                data-testid="navbar-profile-dropdown-view-profile"
                            >
                                View my Dashboard
                            </NavLinkDropdown>
                        </DropdownItemLink>
                        <DropdownItemLink>
                            <NavLinkDropdown
                                onClick={handleViewImagery}
                                to={isSoar ? '/dashboard/my-imagery' : '/dashboard/data'}
                                tag={RRNavLinkStyled}
                                style={{ display: isSoar ? 'block' : 'none' }}
                                data-testid="navbar-profile-dropdown-view-my-maps"
                            >
                                View my Maps
                            </NavLinkDropdown>
                        </DropdownItemLink>
                        <DropdownItemLink>
                            <NavLinkDropdown
                                onClick={handleViewDashboardDrawProjects}
                                to="/dashboard/my-stoaries"
                                tag={RRNavLinkStyled}
                                data-testid="navbar-profile-dropdown-view-my-draw-peojects"
                            >
                                View my Stoaries
                            </NavLinkDropdown>
                        </DropdownItemLink>
                        <DropdownItem divider />
                        <DropdownItem onClick={handleLogout} data-testid="navbar-profile-dropdown-sign-out">
                            Sign Out
                        </DropdownItem>
                    </DropdownMenu>
                </Dropdown>
            </NavItem>

            <SignOutModal
                isOpen={isUserLogout}
                onToggle={() => setIsUserLogout(false)}
                onSignOut={() => onClickLogout()}
            />
        </NavContainer>
    );
};

export default NavbarProfile;

const NavContainer = styled.div<{ isOpen: boolean }>`
    margin: 0px 0px 0px 10px;
    padding: 0 5px;
    min-width: 100px;
    border-radius: ${(props) => props.theme.borderRadius};
    ${lightDropShadow};

    border: ${(props) => (props.isOpen ? `1px solid ${props.theme.color.yellow}` : '1px solid #515151;')};

    &:hover {
        border: 1px solid ${(props) => props.theme.color.yellow};
    }
`;

const Dropdown = styled(BootstrapDropdown)`
    width: 0px;
    cursor: default;
`;

const DropdownToggle = styled(BootstrapDropdownToggle)`
    width: 0px;
`;

const DropdownMenu = styled(BootstrapDropdownMenu)`
    width: 320px;
    background: rgba(0, 0, 0, 1);
    color: #fff;
    margin: 45px 0px 0px -314px;
    transform: none !important;
    border-radius: 6px;
    z-index: 1005;
    ${lightDropShadow};
`;

const DropdownHeader = styled(BootstrapDropdownItem)`
    color: #fff;
    font-weight: bolder;
    font-size: 1.2rem;
    cursor: default;
`;

const DropdownItem = styled(BootstrapDropdownItem)`
    font-size: 1.1rem;
    color: white;

    &:hover {
        background: #eed926;
        color: #000;
    }

    &.dropdown-divider {
        border-top: 1px solid rgba(255, 255, 255, 0.3);
        margin: 5px 0px;
    }
`;

const DropdownItemLink = styled(BootstrapDropdownItem)`
    padding: 0;
`;

const AvatarNameLink = styled.span`
    color: white;
    margin: 3px 8px 0 4px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: ${(props) => props.theme.fontSize.normal};
    max-width: 150px;
`;

// Exclude the isSoar prop from been forwarded to the NavLink or we see the errors in the console..
const NavLink = styled(BootstrapNavLink).withConfig({
    shouldForwardProp: (prop) => prop !== 'isSoar',
})<{ isSoar?: boolean }>`
    color: white;
    cursor: pointer;
    padding: 4px;
    display: flex;

    ${(props) => !props.isSoar && `align-items: center;`}
`;

const NavLinkDropdown = styled(BootstrapNavLink)<{ to?: string }>`
    font-size: 1.1rem;
    color: white;
    padding: 0.25rem 1.5rem !important;

    &:hover {
        background: #eed926;
        color: #000;
    }
`;

const RRNavLinkStyled = styled(RRNavLink)`
    padding: 0;
`;

const NavItem = styled(BootstrapNavItem)`
    color: white;
    cursor: pointer;
    display: flex;
    user-select: none;
    margin: 0px;
`;

const Strong = styled.strong`
    display: block;
    margin-top: 3px;
    color: #eed926;
    overflow: hidden;
    text-overflow: ellipsis;
`;
