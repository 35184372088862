import styled from 'styled-components';

export const Label = styled.label`
    font-size: ${(props) => props.theme.fontSize.normal};
    color: ${(props) => props.theme.color.white};
`;

export const Input = styled.input`
    font-size: ${(props) => props.theme.fontSize.normal};
    color: ${(props) => props.theme.color.black};

    display: block;
    width: 100%;
    padding: 8px 16px;

    border-radius: 4px;

    &:hover {
        outline: 1px solid ${(props) => props.theme.color.yellow};
    }
`;

export const InputHint = styled.span`
    font-size: ${(props) => props.theme.fontSize.normal};
    font-style: italic;
    color: ${(props) => props.theme.color.opaqueWhite};
`;

export const FormGroup = styled.div`
    margin-bottom: 1rem;

    &.inline {
        display: flex;
        flex-direction: row;

        align-items: center;

        margin: 1rem 0 1.5rem;

        ${Label} {
            margin-bottom: 0;
        }
    }
`;

export const Form = styled.form``;
