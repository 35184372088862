import {
    MeasurementToolbarOptionContainer,
    MeasurementToolbarOptionLabel,
    MeasurementToolbarSelect,
    MeasurementToolbarSelectOption,
} from './measurement-toolbar';

export type MeasurementBackgroundColor =
    | 'rgba(255,255,255,0.8)'
    | 'rgba(0,0,0,0.8)'
    | 'rgba(255,255,0,0.8)'
    | 'rgba(255,0,0,0.8)'
    | 'rgb(34,177,76,0.8)'
    | 'rgb(12,136,248,0.8)'
    | 'transparent';
export const defaultBackgroundColor: MeasurementBackgroundColor = 'transparent';
interface MeasurementToolbarBackgroundColorProps {
    backgroundColor: MeasurementBackgroundColor;
    onChangeBackgroundColor: (color: MeasurementBackgroundColor) => void;
}

const MeasurementToolbarBackgroundColor = (props: MeasurementToolbarBackgroundColorProps) => {
    return (
        <MeasurementToolbarOptionContainer>
            <MeasurementToolbarOptionLabel>BG Color:</MeasurementToolbarOptionLabel>
            <MeasurementToolbarSelect
                onChange={(e) => {
                    props.onChangeBackgroundColor(e.target.value as MeasurementBackgroundColor);
                }}
                value={props.backgroundColor}
            >
                <MeasurementToolbarSelectOption value="transparent">None</MeasurementToolbarSelectOption>
                <MeasurementToolbarSelectOption value="rgba(255,255,255,0.8)">White</MeasurementToolbarSelectOption>
                <MeasurementToolbarSelectOption value="rgba(0,0,0,0.8)">Black</MeasurementToolbarSelectOption>
                <MeasurementToolbarSelectOption value="rgba(255,255,0,0.8)">Yellow</MeasurementToolbarSelectOption>
                <MeasurementToolbarSelectOption value="rgba(255,0,0,0.8)">Red</MeasurementToolbarSelectOption>
                <MeasurementToolbarSelectOption value="rgba(34,177,76,0.8)">Green</MeasurementToolbarSelectOption>
                <MeasurementToolbarSelectOption value="rgba(12,136,248,0.8)">Blue</MeasurementToolbarSelectOption>
            </MeasurementToolbarSelect>
        </MeasurementToolbarOptionContainer>
    );
};

export default MeasurementToolbarBackgroundColor;
