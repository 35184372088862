import styled from 'styled-components';

import { PulseLoader } from '../../../Shared/pulse-loader';

export const ListingTabPanelLoading = () => {
    return (
        <ListingTabPanelLoadingContainer>
            <PulseLoader />
        </ListingTabPanelLoadingContainer>
    );
};

interface ListingTabPanelEmptyProps {
    iconUrl: string;
    message?: string;
}

export const ListingTabPanelEmpty = (props: ListingTabPanelEmptyProps) => {
    return (
        <ListingTabPanelEmptyContainer>
            <EmptyListingsIcon src={props.iconUrl} alt="Empty List Icon" />
            <EmptyListingsMessage>{props.message ?? 'Nothing to see here&hellip;'}</EmptyListingsMessage>
        </ListingTabPanelEmptyContainer>
    );
};

const ListingTabPanelEmptyContainer = styled.div`
    padding: 75px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const EmptyListingsIcon = styled.img`
    width: 150px;
    height: 150px;

    opacity: 0.75;

    margin-bottom: 1rem;
`;

const EmptyListingsMessage = styled.p`
    color: ${(props) => props.theme.color.white};
    font-size: ${(props) => props.theme.fontSize.large};
    text-align: center;

    width: 200px;
    margin: 0 auto;
`;

const ListingTabPanelLoadingContainer = styled.div`
    padding: 150px 0;
`;

export const ContentLoading = () => {
    return (
        <ContentLoadingContainer>
            <PulseLoader />
        </ContentLoadingContainer>
    );
};

const ContentLoadingContainer = styled.div`
    width: 100%;
    height: 80vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;
