import React from 'react';
import { useSelector } from 'react-redux';
import CircleAnnotation from '../../../MapView/Annotations/Circle/circle-annotation';
import { selectCircles } from '../../../../store/Annotations/Circle/selectors';

const MobileCircle = () => {
    const circles = useSelector(selectCircles);
    return (
        <React.Fragment>
            {circles.map((circle) => {
                return (
                    <CircleAnnotation
                        key={`
                            ${circle.id}-
                            ${circle.options.color}-
                            ${circle.options.fillOpacity}-
                            ${circle.options.dashArray}-
                            ${circle.options.dashOffset}-
                            ${circle.options.weight}-
                            ${circle.fillPattern ? circle.fillPattern : ''}
                            ${circle.center.lat}-
                            ${circle.center.lng}-
                            ${circle.radius}-
                            ${circle.showRadius}-
                            ${circle.units}-
                            ${circle.labelColor}-
                            ${circle.zIndex}
                        `}
                        radius={circle.radius}
                        circle={circle}
                        isSelected={false}
                    />
                );
            })}
        </React.Fragment>
    );
};

export default MobileCircle;
