import { useSelector } from 'react-redux';
import {
    selectActiveAllMap,
    selectActiveAllMapIsFetching,
    selectActiveAllMapIsLoading,
} from '../../../../store/Map/SuperMap/selectors';
import ActiveMap from '../../Maps/ActiveMap/active-map';
import SideDrawerLoader from '../Shared/side-drawer-loader';
import MapSearchDraw from './map-search-draw';

const SideDrawerListings = () => {
    const activeMap = useSelector(selectActiveAllMap);

    const isFetching = useSelector(selectActiveAllMapIsFetching);
    const isLoading = useSelector(selectActiveAllMapIsLoading);

    if (!activeMap && (isFetching || isLoading)) {
        return <SideDrawerLoader className="list" />;
    }

    if (activeMap) {
        return <ActiveMap listing={activeMap} />;
    }

    return <MapSearchDraw />;
};

export default SideDrawerListings;
