import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { selectActiveAllMap, selectActiveAllMapIsFetching } from '../../../store/Map/SuperMap/selectors';
import { actionActiveMapCleared } from '../../../store/Map/SuperMap/actions';
import {
    actionSentinelClearFeatures,
    actionSentinelResetSelectedAOI,
    actionSentinelResetSelectedFeature,
} from '../../../store/Map/Sentinel/actions';
import { selectActiveOrder } from '../../../store/Map/Tasks/selectors';
import { actionSetActiveOrder } from '../../../store/Map/Tasks/actions';

import BottomSheet, { BottomSheetState } from '../BottomSheet/bottom-sheet';
import MobileSoarLogo from '../mobile-soar-logo';
import MobileBasemapMenu from '../BaseMap/mobile-basemap-menu';
import MobileMap from './mobile-map';
import { TabBarState } from '../TabBar/tab-bar';
import MapsRibbon from '../Maps/maps-ribbon';
import MobileSatelliteRibbon from '../Satellite/mobile-satellite-ribbon';
import SentinelShareModal from '../../Drawer/Share/sentinel-share-modal';
import ActiveMapBottomSheet from './ActiveMap/active-map-bottom-sheet';
import ActiveOrderBottomSheet from './ActiveOrder/active-order-bottom-sheet';
import SkeletonLoading from '../Shared/skeleton-loading';
import UriHelper from '../../../lib/uri-helper';
import { ListingDTOWithChip } from '../../Drawer/SideDrawer/ListingsDrawer/listing-card-chip';
import ActiveMapOpacityControl from './ActiveMap/active-map-opacity-control';
import SatelliteLayerOpacityControl from '../Satellite/mobile-satellite-opacity-control';
import ActiveOrderOpacityControl from './ActiveOrder/active-order-opacity-control';
import MobileSearchBar from '../Search/mobile-search-bar';
import { selectSelectedSearchTerm } from '../../../store/Map/MapSelection/selectors';

interface MobileMapContainerProps {
    tabBarState: TabBarState;
    navigateToTab?: (tabBarState: TabBarState) => void;
}

const MobileMapContainer = (props: MobileMapContainerProps) => {
    const [bottomSheetState, setBottomSheetState] = useState(BottomSheetState.close);
    const [scrollEvent, setScrollEvent] = useState<React.UIEvent<HTMLDivElement> | undefined>(undefined);
    const [showSearchBar, setShowSearchBar] = useState(false);

    const dispatch = useDispatch();
    const activeOrder = useSelector(selectActiveOrder);
    const activeMap = useSelector(selectActiveAllMap);
    const activeMapIsFetching = useSelector(selectActiveAllMapIsFetching);
    const searchTerm = useSelector(selectSelectedSearchTerm);

    const clearActiveMap = useCallback(() => {
        dispatch(actionActiveMapCleared());
    }, [dispatch]);

    const clearActiveOrder = useCallback(() => {
        dispatch(actionSetActiveOrder(undefined));
    }, [dispatch]);

    const clearSentinelFeatures = useCallback(() => {
        dispatch(actionSentinelResetSelectedFeature());
        dispatch(actionSentinelClearFeatures());
        dispatch(actionSentinelResetSelectedAOI());
    }, [dispatch]);

    useEffect(() => {
        switch (props.tabBarState) {
            case 'maps':
                setShowSearchBar(true);
                clearSentinelFeatures();
                clearActiveOrder();
                break;
            case 'satellites':
            case 'satellite_view':
                setShowSearchBar(false);
                clearActiveMap();
                clearActiveOrder();
                break;
            case 'satellite_order_view':
                setShowSearchBar(true);
                clearActiveMap();
                clearSentinelFeatures();
                break;
            default:
                break;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.tabBarState]);

    useEffect(() => {
        if (activeOrder === 'FETCHING' || activeMapIsFetching || activeMap) {
            setBottomSheetState(BottomSheetState.close);
        }
    }, [activeOrder, activeMap, activeMapIsFetching]);

    useEffect(() => {
        if (searchTerm && searchTerm.length > 0 && props.tabBarState === 'maps') {
            setBottomSheetState(BottomSheetState.open);
        }
    }, [searchTerm, props.tabBarState]);

    const handleCloseMap = () => {
        clearActiveMap();

        switch (props.tabBarState) {
            case 'discover':
                UriHelper.navigateToPath('/discover');
                break;
            case 'maps':
            default:
                UriHelper.addPositionToPath('/maps');
                break;
        }

        setBottomSheetState(BottomSheetState.close);
    };

    const handleCloseOrder = () => {
        clearActiveOrder();

        UriHelper.navigateToPath('/satellites');
        setBottomSheetState(BottomSheetState.close);
    };

    const handleSelectListing = (listing: ListingDTOWithChip) => {
        // TODO: Analytics
        UriHelper.navigateToPath(`/maps/${listing.id}`);
        setBottomSheetState(BottomSheetState.close);
    };

    return (
        <React.Fragment>
            <MobileMap />
            <MobileSoarLogo />
            <MobileBasemapMenu />
            {showSearchBar && <MobileSearchBar />}

            <BottomSheet
                onScroll={(e) => setScrollEvent(e)}
                setOpenState={bottomSheetState}
                onOpenStateChanged={(openState) => setBottomSheetState(openState)}
            >
                {(props.tabBarState === 'maps' ||
                    props.tabBarState === 'discover' ||
                    props.tabBarState === 'satellite_order_view') &&
                (activeMapIsFetching || activeOrder === 'FETCHING') ? (
                    <SkeletonLoading />
                ) : null}

                {props.tabBarState === 'satellite_order_view' &&
                activeOrder !== 'FETCHING' &&
                activeOrder !== 'ERROR' &&
                activeOrder ? (
                    <ActiveOrderBottomSheet
                        bottomSheetState={bottomSheetState}
                        activeOrder={activeOrder}
                        onCloseMap={() => handleCloseOrder()}
                    />
                ) : null}

                {(props.tabBarState === 'maps' || props.tabBarState === 'discover') &&
                !activeMapIsFetching &&
                activeMap ? (
                    <ActiveMapBottomSheet
                        bottomSheetState={bottomSheetState}
                        listing={activeMap}
                        isClosing={false}
                        onCloseMap={() => handleCloseMap()}
                    />
                ) : null}

                {props.tabBarState === 'satellite_order_view' && !activeMapIsFetching && activeMap ? (
                    <ActiveMapBottomSheet
                        bottomSheetState={bottomSheetState}
                        listing={activeMap}
                        isClosing={false}
                        onCloseMap={() => handleCloseMap()}
                    />
                ) : null}

                {props.tabBarState === 'maps' && !activeMapIsFetching && !activeMap ? (
                    <MapsRibbon
                        onOpenRibbon={() => setBottomSheetState(BottomSheetState.open)}
                        onCloseRibbon={() => setBottomSheetState(BottomSheetState.close)}
                        onSelectListing={handleSelectListing}
                        scrollEvent={scrollEvent}
                    />
                ) : null}

                {props.tabBarState === 'satellites' || props.tabBarState === 'satellite_view' ? (
                    <React.Fragment>
                        <MobileSatelliteRibbon
                            satelliteBottomSheetState={bottomSheetState}
                            setSatelliteBottomSheetState={setBottomSheetState}
                            navigateToTab={props.navigateToTab}
                        />
                        <SentinelShareModal />
                    </React.Fragment>
                ) : null}

                {
                    // TODO: View someones profile
                }
            </BottomSheet>

            <ActiveMapOpacityControl />
            <ActiveOrderOpacityControl />
            <SatelliteLayerOpacityControl />
        </React.Fragment>
    );
};

export default MobileMapContainer;
