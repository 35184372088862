const passwordActiveUrl =
    'data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20640%20512%22%3E%3C!--!Font%20Awesome%20Free%206.7.1%20by%20%40fontawesome%20-%20https%3A%2F%2Ffontawesome.com%20License%20-%20https%3A%2F%2Ffontawesome.com%2Flicense%2Ffree%20Copyright%202024%20Fonticons%2C%20Inc.--%3E%3Cpath%20d%3D%22M634%20471L36%203.5A16%2016%200%200%200%2013.5%206l-10%2012.5A16%2016%200%200%200%206%2041l598%20467.5a16%2016%200%200%200%2022.5-2.5l10-12.5A16%2016%200%200%200%20634%20471zM296.8%20146.5l134.8%20105.4C429.4%20191.9%20380.5%20144%20320%20144a112.3%20112.3%200%200%200%20-23.2%202.5zm46.4%20219.1L208.4%20260.2C210.7%20320.1%20259.5%20368%20320%20368a113%20113%200%200%200%2023.2-2.5zM320%20112c98.7%200%20189.1%2055%20237.9%20144a285.5%20285.5%200%200%201%20-44%2060.2l37.7%2029.5a333.7%20333.7%200%200%200%2052.9-75.1%2032.4%2032.4%200%200%200%200-29.2C550.3%20135.6%20442.9%2064%20320%2064c-36.7%200-71.7%207-104.6%2018.8l46.4%2036.3c18.9-4.3%2038.3-7.1%2058.2-7.1zm0%20288c-98.7%200-189.1-55-237.9-144a285.5%20285.5%200%200%201%2044.1-60.2l-37.7-29.5a333.6%20333.6%200%200%200%20-52.9%2075.1%2032.4%2032.4%200%200%200%200%2029.2C89.7%20376.4%20197.1%20448%20320%20448c36.7%200%2071.7-7.1%20104.6-18.8l-46.4-36.3C359.3%20397.2%20339.9%20400%20320%20400z%22%2F%3E%3C%2Fsvg%3E';
const passwordInActiveUrl =
    'data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20576%20512%22%3E%3C!--!Font%20Awesome%20Free%206.7.1%20by%20%40fontawesome%20-%20https%3A%2F%2Ffontawesome.com%20License%20-%20https%3A%2F%2Ffontawesome.com%2Flicense%2Ffree%20Copyright%202024%20Fonticons%2C%20Inc.--%3E%3Cpath%20d%3D%22M288%20144a110.9%20110.9%200%200%200%20-31.2%205%2055.4%2055.4%200%200%201%207.2%2027%2056%2056%200%200%201%20-56%2056%2055.4%2055.4%200%200%201%20-27-7.2A111.7%20111.7%200%201%200%20288%20144zm284.5%2097.4C518.3%20135.6%20410.9%2064%20288%2064S57.7%20135.6%203.5%20241.4a32.4%2032.4%200%200%200%200%2029.2C57.7%20376.4%20165.1%20448%20288%20448s230.3-71.6%20284.5-177.4a32.4%2032.4%200%200%200%200-29.2zM288%20400c-98.7%200-189.1-55-237.9-144C98.9%20167%20189.3%20112%20288%20112s189.1%2055%20237.9%20144C477.1%20345%20386.7%20400%20288%20400z%22%2F%3E%3C%2Fsvg%3E';
const passwordActiveUrlDark =
    'data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20640%20512%22%3E%3C!--!Font%20Awesome%20Free%206.7.2%20by%20%40fontawesome%20-%20https%3A%2F%2Ffontawesome.com%20License%20-%20https%3A%2F%2Ffontawesome.com%2Flicense%2Ffree%20Copyright%202025%20Fonticons%2C%20Inc.--%3E%3Cpath%20fill%3D%22%23d3d3d3%22%20d%3D%22M38.8%205.1C28.4-3.1%2013.3-1.2%205.1%209.2S-1.2%2034.7%209.2%2042.9l592%20464c10.4%208.2%2025.5%206.3%2033.7-4.1s6.3-25.5-4.1-33.7L525.6%20386.7c39.6-40.6%2066.4-86.1%2079.9-118.4c3.3-7.9%203.3-16.7%200-24.6c-14.9-35.7-46.2-87.7-93-131.1C465.5%2068.8%20400.8%2032%20320%2032c-68.2%200-125%2026.3-169.3%2060.8L38.8%205.1zM223.1%20149.5C248.6%20126.2%20282.7%20112%20320%20112c79.5%200%20144%2064.5%20144%20144c0%2024.9-6.3%2048.3-17.4%2068.7L408%20294.5c8.4-19.3%2010.6-41.4%204.8-63.3c-11.1-41.5-47.8-69.4-88.6-71.1c-5.8-.2-9.2%206.1-7.4%2011.7c2.1%206.4%203.3%2013.2%203.3%2020.3c0%2010.2-2.4%2019.8-6.6%2028.3l-90.3-70.8zM373%20389.9c-16.4%206.5-34.3%2010.1-53%2010.1c-79.5%200-144-64.5-144-144c0-6.9%20.5-13.6%201.4-20.2L83.1%20161.5C60.3%20191.2%2044%20220.8%2034.5%20243.7c-3.3%207.9-3.3%2016.7%200%2024.6c14.9%2035.7%2046.2%2087.7%2093%20131.1C174.5%20443.2%20239.2%20480%20320%20480c47.8%200%2089.9-12.9%20126.2-32.5L373%20389.9z%22%2F%3E%3C%2Fsvg%3E';
const passwordInActiveUrlDark =
    'data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20576%20512%22%3E%3C!--!Font%20Awesome%20Free%206.7.2%20by%20%40fontawesome%20-%20https%3A%2F%2Ffontawesome.com%20License%20-%20https%3A%2F%2Ffontawesome.com%2Flicense%2Ffree%20Copyright%202025%20Fonticons%2C%20Inc.--%3E%3Cpath%20fill%3D%22%23d3d3d3%22%20d%3D%22M288%2032c-80.8%200-145.5%2036.8-192.6%2080.6C48.6%20156%2017.3%20208%202.5%20243.7c-3.3%207.9-3.3%2016.7%200%2024.6C17.3%20304%2048.6%20356%2095.4%20399.4C142.5%20443.2%20207.2%20480%20288%20480s145.5-36.8%20192.6-80.6c46.8-43.5%2078.1-95.4%2093-131.1c3.3-7.9%203.3-16.7%200-24.6c-14.9-35.7-46.2-87.7-93-131.1C433.5%2068.8%20368.8%2032%20288%2032zM144%20256a144%20144%200%201%201%20288%200%20144%20144%200%201%201%20-288%200zm144-64c0%2035.3-28.7%2064-64%2064c-7.1%200-13.9-1.2-20.3-3.3c-5.5-1.8-11.9%201.6-11.7%207.4c.3%206.9%201.3%2013.8%203.2%2020.7c13.7%2051.2%2066.4%2081.6%20117.6%2067.9s81.6-66.4%2067.9-117.6c-11.1-41.5-47.8-69.4-88.6-71.1c-5.8-.2-9.2%206.1-7.4%2011.7c2.1%206.4%203.3%2013.2%203.3%2020.3z%22%2F%3E%3C%2Fsvg%3E';

export { passwordActiveUrl, passwordInActiveUrl, passwordActiveUrlDark, passwordInActiveUrlDark };
