import * as React from 'react';
import Cropper from 'react-cropper';
import Dropzone from 'react-dropzone';
import { Container, Row } from 'reactstrap';
import 'cropperjs/dist/cropper.css';
import FileUtil from '../../../lib/file-util';
import { UserDTO } from '../../../api/model';
import Analytics from '../../../lib/user-analytics';
import styled from 'styled-components';
import profileIconUrl from '../profile-icon-url';
import { toast } from 'react-toastify';
import { StyledButton } from '../../Shared/styled-button';
import { isMobile } from 'react-device-detect';

interface MyProfileEditAvatarProps {
    myProfile: UserDTO;
    onAvatarUnchanged: () => void;
    onAvatarUpdated: (newAvatar?: File) => void;
    setHasChangedAvatar: (changed: boolean) => void;
}

interface MyProfileEditAvatarState {
    updatedAvatarUrl?: string;
    croppedAvatarUrl?: string;
    needsUpdatingAvatar: boolean;
    error?: string;
}

class MyProfileEditAvatar extends React.Component<MyProfileEditAvatarProps, MyProfileEditAvatarState> {
    cropper: Cropper;

    constructor(props: MyProfileEditAvatarProps) {
        super(props);

        this.state = {
            updatedAvatarUrl: undefined,
            croppedAvatarUrl: undefined,
            error: undefined,
            needsUpdatingAvatar: false,
        };

        this.handleFileDropped = this.handleFileDropped.bind(this);
        this.handleFileCropped = this.handleFileCropped.bind(this);
        this.handleChangeAvatarClicked = this.handleChangeAvatarClicked.bind(this);
        this.renderCropAvatar = this.renderCropAvatar.bind(this);
    }

    handleFileDropped(files: [File]) {
        const error = 'Could not load file. We currently only support .JPG and .PNG files';
        if (files.length < 1 && isMobile) {
            toast.error(error);
            this.setState({ error });
            return;
        }

        const file = files[0];
        FileUtil.fileToBase64Image(file)
            .then((base64Image) => {
                this.setState({
                    updatedAvatarUrl: base64Image,
                    error: undefined,
                });
            })
            .catch(() => {
                this.setState({ error });
                toast.error(error);
            });
    }

    handleFileCropped() {
        const avatarData = this.cropper.getCroppedCanvas().toDataURL();

        FileUtil.base64ImageToFile(avatarData).then((avatarFile) => {
            this.props.onAvatarUpdated(avatarFile);
        });

        this.setState({
            croppedAvatarUrl: avatarData,
        });
    }

    handleChangeAvatarClicked() {
        this.setState({
            updatedAvatarUrl: undefined,
            croppedAvatarUrl: undefined,
            needsUpdatingAvatar: true,
        });
    }

    renderExistingAvatar(): React.ReactElement {
        return (
            <Container>
                <Row>
                    <AvatarPreviewContainer onClick={this.handleChangeAvatarClicked}>
                        <AvatarPreviewImage src={this.props.myProfile.avatarUrl} />
                    </AvatarPreviewContainer>
                </Row>
                <AvatarPreviewButtonContainer>
                    <AvatarPreviewButton
                        onClick={() => {
                            this.props.onAvatarUnchanged();
                            Analytics.Event('My Dashboard', 'Clicked cancel avatar edit');
                        }}
                    >
                        CANCEL EDIT
                    </AvatarPreviewButton>
                </AvatarPreviewButtonContainer>
            </Container>
        );
    }

    renderNewAvatar(): React.ReactElement {
        return (
            <Container>
                <Row>
                    <AvatarPreviewContainer
                        onClick={() => {
                            this.handleChangeAvatarClicked();
                            Analytics.Event('My Dashboard', 'Clicked to change avatar edit');
                        }}
                    >
                        <AvatarPreviewImage className="avatar-preview" src={this.state.croppedAvatarUrl} />
                    </AvatarPreviewContainer>
                </Row>
                <AvatarPreviewButtonContainer>
                    <AvatarPreviewButton
                        onClick={() => {
                            this.props.onAvatarUnchanged();
                            this.props.setHasChangedAvatar(true);
                            Analytics.Event('My Dashboard', 'Clicked to save avatar edit');
                        }}
                    >
                        SAVE EDIT
                    </AvatarPreviewButton>
                </AvatarPreviewButtonContainer>
            </Container>
        );
    }

    renderNoAvatar(): React.ReactElement {
        const activeStyle = {
            backgroundColor: 'rgba(255, 255, 255, 0.1)',
            boxShadow: '0px 0px 0px 9px rgba(255,255,255,0.1)',
        };
        return (
            <Container>
                <AvatarEmptyContainer>
                    <ProfileAvatarDropZoneContainer
                        onDrop={this.handleFileDropped}
                        accept="image/jpeg, image/png"
                        className="choose-avatar"
                        activeStyle={activeStyle}
                    >
                        <ProfileAvatarChooseFile>
                            <DefaultDownloadImage src={profileIconUrl.chooseFileUrl} />
                            <ProfileAvatarChooseFileText>
                                <span>Choose File</span> <p>or drag it here</p>
                            </ProfileAvatarChooseFileText>
                        </ProfileAvatarChooseFile>
                        <AvatarTextWrapper></AvatarTextWrapper>
                    </ProfileAvatarDropZoneContainer>
                </AvatarEmptyContainer>
            </Container>
        );
    }

    renderCropAvatar(): React.ReactElement {
        return (
            <Container>
                <AvatarPreviewCropperContainer>
                    <AvatarCropper
                        ref={(ref) => (this.cropper = ref)}
                        src={this.state.updatedAvatarUrl}
                        style={{ width: '100%', height: '285px', margin: '0 auto', padding: '0' }}
                        aspectRatio={1}
                        guides={true}
                        viewMode={1}
                        background={false}
                        autoCropArea={1.0}
                    />
                </AvatarPreviewCropperContainer>

                <AvatarPreviewButtonContainer>
                    <AvatarPreviewButton
                        onClick={() => {
                            this.handleFileCropped();
                            Analytics.Event('My Dashboard', 'Clicked to finish cropping');
                        }}
                    >
                        FINISH CROPPING
                    </AvatarPreviewButton>
                </AvatarPreviewButtonContainer>
            </Container>
        );
    }

    render(): React.ReactElement {
        if (this.state.croppedAvatarUrl !== undefined) {
            return this.renderNewAvatar();
        }

        if (this.state.updatedAvatarUrl !== undefined) {
            return this.renderCropAvatar();
        }

        if (this.props.myProfile.avatarUrl === undefined || this.state.needsUpdatingAvatar) {
            return this.renderNoAvatar();
        }

        if (this.props.myProfile.avatarUrl !== undefined) {
            return this.renderExistingAvatar();
        }

        return this.renderCropAvatar();
    }
}

export default MyProfileEditAvatar;

const AvatarPreviewContainer = styled.div`
    padding-top: 20px;
    padding-bottom: 25px;
    margin: auto;

    width: 285px;
    height: 285px;

    position: relative;

    :after {
        content: 'Click to update profile picture';
        color: #fff;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        background: rgba(0, 0, 0, 0.6);
        border-radius: 120px;
        width: 285px;
        height: 285px;
        opacity: 0;
        transition: all 0.5s;
        -webkit-transition: all 0.5s;

        display: flex;
        justify-content: center;
        align-items: center;
    }

    :hover {
        cursor: pointer;
    }

    :hover:after {
        opacity: 1;
    }
`;

const AvatarPreviewImage = styled.img`
    width: 240px;
    height: 240px;
    border-radius: 120px;
    display: block;
    margin: 0 auto;
    border: 2px solid #eed926;
`;

const AvatarPreviewButtonContainer = styled.div`
    text-align: center;
`;

const AvatarPreviewButton = styled(StyledButton)`
    padding: 3px 15px;
    width: 100%;
`;

const AvatarPreviewCropperContainer = styled(Row)`
    color: white;
    margin: 0px;
    padding: 0;
    position: relative;
`;

const AvatarEmptyContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin-right: 5px;
    margin-left: -15px;
`;

const AvatarCropper = styled(Cropper)`
    .cropper-crop {
        cursor: grabbing;
    }

    .cropper-line {
        background-color: #eed926 !important;
    }

    .cropper-point {
        background-color: #eed926 !important;
        border-radius: 20px;
        height: 8px;
        opacity: 1;
        width: 8px;
    }

    .cropper-view-box {
        display: block;
        height: 100%;
        outline: 1px solid #eed926;
        outline-color: rgba(238, 218, 38, 1);
        overflow: hidden;
        width: 100%;
    }
`;

const ProfileAvatarDropZoneContainer = styled(Dropzone)`
    justify-content: center;
    display: flex;
    cursor: pointer;
    background-color: rgba(255, 255, 255, 0.05);
    border: 1px dashed rgba(255, 255, 255, 0.5);
    border-radius: 6px;
    width: 100%;
    height: 250px;
    margin: 10px;
    -webkit-box-shadow: 0px 0px 0px 9px rgba(255, 255, 255, 0.05);
    -moz-box-shadow: 0px 0px 0px 9px rgba(255, 255, 255, 0.05);
    box-shadow: 0px 0px 0px 9px rgba(255, 255, 255, 0.05);

    :hover {
        background-color: rgba(255, 255, 255, 0.1);
        -webkit-box-shadow: 0px 0px 0px 9px rgba(255, 255, 255, 0.1);
        -moz-box-shadow: 0px 0px 0px 9px rgba(255, 255, 255, 0.1);
        box-shadow: 0px 0px 0px 9px rgba(255, 255, 255, 0.1);
    }
`;

const ProfileAvatarChooseFile = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    margin-top: 70px;
    width: 300px;
    justify-content: center;
    align-items: center;
`;

const DefaultDownloadImage = styled.img`
    width: 64px;
    height: 48px;
    margin-top: -150px;
    align-self: center;
    margin-left: 2vw;
`;

const ProfileAvatarChooseFileText = styled.div`
    display: flex;
    flex-direction: row;
    position: absolute;
    top: 65px;
    margin: 0 auto;
    font-size: 16px;
    text-align: right;

    span {
        color: #eed926;
        -webkit-text-fill-color: #eed926;
        text-decoration: underline;
        padding: 0px 5px 0 5px;
        margin-left: 16px;
    }

    p {
        color: white;
        padding: 0px -8px 0 5px;
    }
`;

const AvatarTextWrapper = styled.div`
    display: block;
    flex-direction: column;
    height: 6vh;
    width: 18vw;
    margin: 20vh 0 0 -14vw;
    padding: 4px;
    @media only screen and (max-width: 600px) {
        display: none;
    }
`;
