import React from 'react';
import { ReactNode } from 'react';
import { createPortal } from 'react-dom';
import styled, { keyframes } from 'styled-components';
import SoarHelper from '../../../lib/soar-helper';

interface ReportMapMenuProps {
    children: ReactNode;
    toggleRef: HTMLInputElement;
}

const ReportMapMenu = (props: ReportMapMenuProps) => {
    const isSubdomain = !SoarHelper.isSoar();
    return props.toggleRef
        ? createPortal(
              <React.Fragment>
                  <ReportArrow fadeIn={props.toggleRef ? true : false} bottomDisplay={isSubdomain} />
                  <ReportOptions fadeIn={props.toggleRef ? true : false} bottomDisplay={isSubdomain}>
                      {props.children}
                  </ReportOptions>
              </React.Fragment>,
              props.toggleRef
          )
        : null;
};

export default ReportMapMenu;

interface ReportOptionsProps {
    fadeIn: boolean;
    bottomDisplay: boolean;
}

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const ReportArrow = styled.div<ReportOptionsProps>`
    position: fixed;
    margin-left: ${(props) => (props.bottomDisplay ? '0px' : '35px')};
    margin-top: ${(props) => (props.bottomDisplay ? '0px' : '-22px')};
    width: 20px;
    height: 20px;
    animation: ${fadeIn} 200ms ease-in-out;
    border-width: 16px 0px 16px 16px;
    border-style: solid;
    border-color: transparent transparent transparent rgba(0, 0, 0, 0.8);
    transform: ${(props) => (props.bottomDisplay ? 'rotate(-90deg)' : 'rotate(180deg)')};
`;

const ReportOptions = styled.div<ReportOptionsProps>`
    background: rgba(0, 0, 0, 0.8);
    color: white;
    position: fixed;
    margin-left: ${(props) => (props.bottomDisplay ? '-15px' : '55px')};
    margin-top: ${(props) => (props.bottomDisplay ? '25px' : '-40px')};
    width: 315px;
    height: 230px;
    border-radius: 6px;
    animation: ${fadeIn} 200ms ease-in-out;
`;
