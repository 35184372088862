import React from 'react';
import styled from 'styled-components';
import { ListingDTO, ListingType } from '../../../../api/model';
import ActiveMapSpeedIcon from './active-map-speed-icon';

interface ActiveMapExternalSourceNotificationProps {
    listing: ListingDTO;
}

const ActiveMapExternalSourceNotification = (props: ActiveMapExternalSourceNotificationProps) => {
    const allowedTypes = [ListingType.WMS, ListingType.WMTS, ListingType.EXTERNAL_TILE_LAYER, ListingType.COG];

    if (!allowedTypes.includes(props.listing.listingType)) {
        return <React.Fragment />;
    }

    return (
        <Container>
            <Text>This data layer is from an external source.</Text>
            {props.listing.layerSpeedKbps !== undefined ? (
                <SpeedIconContainer>
                    <ActiveMapSpeedIcon speed={props.listing.layerSpeedKbps} />
                </SpeedIconContainer>
            ) : null}
        </Container>
    );
};

export default ActiveMapExternalSourceNotification;

const Container = styled.div`
    width: 100%;
    padding: 11px 10px;
    display: flex;
    align-items: self-end;
`;

const Text = styled.span`
    color: #00a2ff;
    flex: 1;
`;

const SpeedIconContainer = styled.div`
    display: inline-block;
    justify-content: flex-end;
    width: 20px;

    img {
        width: 23px;
        height: 20px;
    }
`;
