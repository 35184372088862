import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { ListingType } from '../../../../../api/model';
import ListingHelper from '../../../../../lib/listing-helper';
import { selectActiveAllMap } from '../../../../../store/Map/SuperMap/selectors';
import ImagePreview from '../../../../Shared/image-preview';
import ReportMap from '../../../../Shared/ReportMap/report-map';
import EmbeddedMapDialog from '../../EmbedMap/embedded-map-dialog';
import SideDrawerSocial from './side-drawer-social';

interface ShareComponentProps {
    listingPage?: boolean;
}

const MapOptions = (props: ShareComponentProps) => {
    const selectedMap = useSelector(selectActiveAllMap);

    if (!selectedMap) {
        return <React.Fragment />;
    }

    // Do not show share links for satellite orders, as they are private
    if (selectedMap.listingType === ListingType.ORDER) {
        return <React.Fragment />;
    }

    return (
        <DrawShareContainer>
            <DrawShareImage
                src={ListingHelper.getPreviewUrlForListing(selectedMap.id)}
                width={378}
                height={70}
                alt={selectedMap.title || 'Share Image Preview'}
            />
            <DrawShareOptions>
                <React.Fragment>
                    <DrawShareIcons>
                        <SideDrawerSocial />
                    </DrawShareIcons>
                    <DrawShareIcons>
                        {!props.listingPage && <EmbeddedMapDialog tileLayer={selectedMap} />}
                        <ReportMap />
                    </DrawShareIcons>
                </React.Fragment>
            </DrawShareOptions>
        </DrawShareContainer>
    );
};

export default MapOptions;

const DrawShareContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;

const DrawShareImage = styled(ImagePreview)`
    width: 378px;
    height: 70px;
    object-fit: cover;
    margin: 15px 5px 0px !important;
    border-radius: 6px;
    max-width: 97%;
`;

const DrawShareOptions = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;

const DrawShareIcons = styled.div`
    display: flex;
    flex-direction: row;
    margin: 10px 0px;
    user-select: none;

    .embed-map-icon {
        height: 20px;
        margin: 0px;
        margin-top: 3px;
        font-size: 16px;
    }

    .report-map-icon {
        height: 20px;
        margin: -12px 0 0;
        font-size: 16px;
    }
`;
