import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import ApiUser from '../../../../api/api-user';
import { Content, ContentFooter, ScrollableContainer } from '../../Shared/containers';
import { Form, FormGroup, Input, Label } from '../../Shared/form';
import { MobileButtonPrimary } from '../../Shared/button';
import SoarLogo from '../../Shared/soar-logo';
import { passwordActiveUrl, passwordInActiveUrl } from '../../../Registration/registration-icons';
import { PulseLoader } from '../../../Shared/pulse-loader';
import { useQuery } from '../../../../lib/uri-helper';
import UserHelper from '../../../../lib/user-helper';
import Analytics from '../../../../lib/user-analytics';

interface MobilePasswordResetProps {
    onLogin: () => void;
}

const MobilePasswordReset = (props: MobilePasswordResetProps) => {
    const query = useQuery();

    const [id, setId] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [confirmPassword, setConfirmPassword] = useState<string>('');
    const [error, setError] = useState<string | undefined>(undefined);
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState<boolean>(false);
    const [passResetSubmitting, setPassResetSubmitting] = useState<boolean>(false);
    const [submitted, setSubmitted] = useState<boolean>(false);

    useEffect(() => {
        if (query) {
            setEmail(query.get('email') ?? '');
            setId(query.get('id') ?? '');
        }
    }, [query]);

    const handleGoToLogin = () => {
        props.onLogin();
        window.history.pushState(null, '', '/profile');
    };

    const handleSubmitPasswordReset = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (!UserHelper.isEmailValid(email)) {
            setError('Something has gone wrong, please open from the email link sent to you');
            return;
        }

        if (!password && !confirmPassword) {
            setError('Please enter a password and confirmation password');
            return;
        }

        if (password !== confirmPassword) {
            setError('Please make sure the password and confirm password are the same.');
            setConfirmPassword('');
            return;
        }

        if (password.length < 6) {
            setError('Please ensure the password is 6 or more characters long');
            setPassword('');
            setConfirmPassword('');
            return;
        }

        if (!error && password && email && id) {
            Analytics.Event('Registration', 'Reset password');

            setPassResetSubmitting(true);
            ApiUser.resetPasswordPromise(id, email, password)
                .then(() => {
                    setPassResetSubmitting(false);
                    setSubmitted(true);
                    setTimeout(() => {
                        handleGoToLogin();
                        setSubmitted(false);
                    }, 3000);
                })
                .catch((error) => {
                    if (error.toString().includes('400')) {
                        setError(
                            'It looks like you may have used this reset token or something has gone wrong, please request another reset email'
                        );
                    } else {
                        setError(error.toString().substring(11, error.length));
                    }
                    setPassResetSubmitting(false);
                });
        }
    };

    if (submitted) {
        return (
            <ScrollableContainer>
                <MobilePassResetPasswordContent>
                    <PassResetPasswordSoarLogo width="60%" />
                    <PassResetSubmittedNotification>
                        <strong>Your password has been reset</strong>
                        <br />
                        Redirecting to login&hellip;
                        <br />
                        <br />
                        <PulseLoader />
                    </PassResetSubmittedNotification>
                </MobilePassResetPasswordContent>
                <MobilePassResetPasswordFooter>
                    Not redirected?
                    <br />
                    <MobileLoginButton onClick={() => handleGoToLogin()}>GO TO LOGIN</MobileLoginButton>
                </MobilePassResetPasswordFooter>
            </ScrollableContainer>
        );
    }

    return (
        <ScrollableContainer>
            <MobilePassResetPasswordContent>
                <PassResetPasswordSoarLogo width="60%" />
                <Form onSubmit={(e) => handleSubmitPasswordReset(e)}>
                    <PassResetFormGroup>
                        <Label>Please enter a new password and confirm</Label>
                        <PasswordInput
                            data-sentry-block
                            type={showPassword ? 'text' : 'password'}
                            placeholder="Password"
                            value={password}
                            onChange={(e) => {
                                setPassword(e.target.value);
                                setError(undefined);
                            }}
                        />
                        <PassResetFormPasswordIcon
                            showPassword={showPassword}
                            onClick={() => setShowPassword(!showPassword)}
                        />
                    </PassResetFormGroup>
                    <PassResetFormGroup>
                        <PasswordInput
                            data-sentry-block
                            type={showConfirmPassword ? 'text' : 'password'}
                            placeholder="Confirm Password"
                            value={confirmPassword}
                            onChange={(e) => {
                                setConfirmPassword(e.target.value);
                                setError(undefined);
                            }}
                        />
                        <PassResetFormPasswordIcon
                            showPassword={showConfirmPassword}
                            onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                        />
                    </PassResetFormGroup>
                    {error && (
                        <PassResetError>
                            <PassResetErrorIcon />
                            {error}
                        </PassResetError>
                    )}
                    {passResetSubmitting ? (
                        <PulseLoader />
                    ) : (
                        <PassResetSubmitButton type="submit" error={error ? true : false}>
                            RESET PASSWORD
                        </PassResetSubmitButton>
                    )}
                </Form>
            </MobilePassResetPasswordContent>
            <MobilePassResetPasswordFooter>
                Got here by accident?
                <br />
                <MobileLoginButton onClick={() => handleGoToLogin()}>Go back to sign in</MobileLoginButton>
            </MobilePassResetPasswordFooter>
        </ScrollableContainer>
    );
};

export default MobilePasswordReset;

const MobilePassResetPasswordContent = styled(Content)`
    align-self: center;
`;

const MobilePassResetPasswordFooter = styled(ContentFooter)`
    text-align: center;
`;

const MobileLoginButton = styled(MobileButtonPrimary)`
    margin: 8px 0;
    width: 100%;
`;

const PassResetFormGroup = styled(FormGroup)`
    margin-bottom: 24px;

    ${Label} {
        margin-bottom: 24px;
    }
`;

const PassResetPasswordSoarLogo = styled(SoarLogo)`
    margin: 0 auto 32px;
`;

const PasswordInput = styled(Input)`
    padding-right: 35px;
`;

interface PasswordIconProps {
    showPassword: boolean;
}

const PassResetFormPasswordIcon = styled.div<PasswordIconProps>`
    background-image: url(${(props) => (props.showPassword ? passwordActiveUrl : passwordInActiveUrl)});
    background-repeat: no-repeat;
    width: 25px;
    height: 20px;
    float: right;
    margin-top: -30px;
    margin-right: 5px;
    opacity: 0.75;
`;

interface ErrorProps {
    error?: boolean;
}

const PassResetSubmitButton = styled(MobileButtonPrimary)<ErrorProps>`
    margin: 0 auto;
    margin-top: ${(props) => (props.error ? '0px' : '20px')};
    background-color: gray;
    width: 100%;

    :not(:disabled) {
        cursor: pointer;
        background-color: #eed926 !important;
        -webkit-text-fill-color: black;
    }
`;

const PassResetError = styled.div`
    color: red !important;
    -webkit-text-fill-color: red !important;
    padding: 10px;
    padding-top: 0px;
    display: flex;
`;

const PassResetErrorIcon = styled.div`
    background-image: url("data:image/svg+xml,%3Csvg width='15' height='15' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M20 17V29' stroke='%23FF0000' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M20 11V13' stroke='%23FF0000' stroke-linecap='round' stroke-linejoin='round'/%3E%3Ccircle cx='20' cy='20' r='19.5' stroke='%23FF0000'/%3E%3C/svg%3E ");
    background-position: center;
    background-repeat: no-repeat;
    width: 20px;
    height: 20px;
    margin-right: 10px;
`;

const PassResetSubmittedNotification = styled.p`
    color: ${(props) => props.theme.color.white};
    font-size: ${(props) => props.theme.fontSize.large};

    text-align: center;

    strong {
        color: ${(props) => props.theme.color.white};
        margin-bottom: 1rem;
        display: block;
    }
`;
