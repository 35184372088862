import React from 'react';
import styled from 'styled-components';
import InviteUserEmailInput from './invite-user-email-input';
import InviteUserEmailList from './invite-user-email-list';

interface ProjectPermissionsSelectProps {
    projectPermission: ProjectPermission;
    onPermissionChange: (permission: ProjectPermission) => void;
    invitedUserEmails: string[];
    onInviteUser: (email: string) => void;
    onInviteUsers: (email: string[]) => void;
    onRemoveUser: (email: string) => void;
}

export type ProjectPermission = 'public' | 'private' | 'inviteOnly';

const ProjectPermissionsSelect = (props: ProjectPermissionsSelectProps) => {
    return (
        <Container>
            <Text>Permissions</Text>
            <Select
                defaultValue={props.projectPermission}
                onChange={(e) => props.onPermissionChange(e.currentTarget.value as ProjectPermission)}
            >
                <Option value="public">Public (anyone can see this Stoary)</Option>
                <Option value="private">Private (only I can see this Stoary)</Option>
                <Option value="inviteOnly">Invite only</Option>
            </Select>

            {props.projectPermission === 'inviteOnly' ? (
                <React.Fragment>
                    <InviteUserEmailInput
                        invitedUserEmails={props.invitedUserEmails}
                        onAddUser={(email) => {
                            props.onInviteUser(email);
                        }}
                        onAddUsers={(emails) => {
                            props.onInviteUsers(emails);
                        }}
                    />
                    <InviteUserEmailList
                        invitedUserEmails={props.invitedUserEmails}
                        onRemoveUser={props.onRemoveUser}
                    />
                </React.Fragment>
            ) : null}
        </Container>
    );
};

export default ProjectPermissionsSelect;

const Container = styled.div`
    width: 100%;
    display: block;
`;

const Text = styled.p`
    color: white;
    margin: 12px 0 4px 4px;
`;

const Select = styled.select`
    border-radius: 4px;
    border: 1px solid rgba(255, 255, 255, 0.8) !important;
    background-color: transparent !important;
    font-size: 1rem;
    outline: none;
    color: rgba(255, 255, 255, 0.6);
    width: 275px;
    height: 32px;
    padding: 5px;
    margin: 0 0 0 4px;
    cursor: pointer;

    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;

    background: url(/assets/arrows/dropdown-arrow_white.svg) no-repeat right 8px center / 12px;
`;

const Option = styled.option`
    color: rgba(255, 255, 255, 0.6);
    background-color: rgba(0, 0, 0, 0.8);
`;
