import React, { useState } from 'react';
import turfDistance from '@turf/distance';

import Rectangle from './rectangle';
import {
    MeasurementToolbarCheckbox,
    MeasurementToolbarOptionContainer,
    MeasurementToolbarOptionLabel,
} from '../Measurement/measurement-toolbar';
import { MeasurementLengthType } from '../Measurement/measurement-toolbar-polygon-length';

interface RectangleMeasurementLengthToolbarProps {
    rectangle: Rectangle;
    onChangeShowLength: (showLength: MeasurementLengthType) => void;
}

const MAX_LENGTH_FOR_SHOWING_LINE_SEGMENT_LENGTH = 1200000; // 1,200 km

const RectangleMeasurementLengthToolbar = (props: RectangleMeasurementLengthToolbarProps) => {
    const [toggleLength, setToggleLength] = useState(props.rectangle.showLength === 'sections');
    const [toggleLengthTotal, setToggleLengthTotal] = useState(props.rectangle.showLength === 'total');

    const isRectangleTooLarge = (): boolean => {
        const north = props.rectangle.bounds.getNorthEast().lat;
        const south = props.rectangle.bounds.getSouthWest().lat;
        const east = props.rectangle.bounds.getNorthEast().lng;
        const west = props.rectangle.bounds.getSouthWest().lng;

        const eastWestDistance = turfDistance([north, east], [north, west], { units: 'meters' });
        const northSouthDistance = turfDistance([north, east], [south, east], { units: 'meters' });

        if (
            eastWestDistance > MAX_LENGTH_FOR_SHOWING_LINE_SEGMENT_LENGTH ||
            northSouthDistance > MAX_LENGTH_FOR_SHOWING_LINE_SEGMENT_LENGTH
        ) {
            return true;
        }
        return false;
    };

    const disabled = isRectangleTooLarge();
    return (
        <React.Fragment>
            <MeasurementToolbarOptionContainer>
                <MeasurementToolbarOptionLabel htmlFor="rect-edge-length-tick">
                    Show section Length:
                </MeasurementToolbarOptionLabel>
                <MeasurementToolbarCheckbox
                    id="rect-edge-length-tick"
                    disabled={disabled}
                    title={disabled ? 'Length(s) too long to show length labels' : 'Show section Length'}
                    type="checkbox"
                    checked={toggleLength}
                    onClick={() => {
                        setToggleLength(!toggleLength);
                        setToggleLengthTotal(false);

                        props.onChangeShowLength(!toggleLength ? 'sections' : false);
                    }}
                />
            </MeasurementToolbarOptionContainer>
            <MeasurementToolbarOptionContainer>
                <MeasurementToolbarOptionLabel htmlFor="rect-edge-total-length-tick">
                    Show total Length:
                </MeasurementToolbarOptionLabel>
                <MeasurementToolbarCheckbox
                    id="rect-edge-total-length-tick"
                    disabled={disabled}
                    title={disabled ? 'Length(s) too long to show length labels' : 'Show section Length'}
                    type="checkbox"
                    checked={toggleLengthTotal}
                    onClick={() => {
                        setToggleLength(false);
                        setToggleLengthTotal(!toggleLengthTotal);

                        props.onChangeShowLength(!toggleLengthTotal ? 'total' : false);
                    }}
                />
            </MeasurementToolbarOptionContainer>
        </React.Fragment>
    );
};

export default RectangleMeasurementLengthToolbar;
