import L from 'leaflet';
import { createControlComponent } from '@react-leaflet/core';

import {
    convertDDToDMS,
    convertDDtoGDA2020,
    convertDDtoMGRS,
    convertDDtoUTM,
    displayDMS,
    wrapLongitudeCoordinates,
} from './coordinates-util';

import './coordinates-control.css';

import StorageHelper from '../../../lib/storage-helper';

const CoordinatesView = {
    WGS84DD: 'WGS84 DD',
    WGS84MD: 'WGS84 MD',
    MGRS: 'MGRS (Military)',
    WGS84UTM: 'WGS84 UTM',
    GDA94MGA: 'GDA94 MGA (Australia)',
};

const DefaultView = 'WGS84DD';

const StorageKey = 'coordinates-view';

const createCoordinatesControl = (props: L.ControlOptions) => {
    let lastLatLng: L.LatLng = new L.LatLng(0, 0);
    const coordinateControl = new L.Control({ position: props.position ?? 'bottomright' });

    const updateControl = (coordinates: L.LatLng) => {
        const selectedView = StorageHelper.get(StorageKey) ?? DefaultView;

        const controlData: { label: string; value: string }[] = [];

        switch (selectedView) {
            case 'WGS84DD':
                {
                    controlData.push({
                        label: 'Latitude',
                        value: coordinates.lat.toLocaleString('en-US', {
                            minimumFractionDigits: 8,
                            useGrouping: false,
                        }),
                    });
                    controlData.push({
                        label: 'Longitude',
                        value: coordinates.lng.toLocaleString('en-US', {
                            minimumFractionDigits: 8,
                            useGrouping: false,
                        }),
                    });
                }
                break;
            case 'WGS84MD':
                {
                    controlData.push({
                        label: 'Latitude',
                        value: displayDMS(convertDDToDMS(coordinates.lat, false)),
                    });
                    controlData.push({
                        label: 'Longitude',
                        value: displayDMS(convertDDToDMS(coordinates.lng, true)),
                    });
                }
                break;
            case 'MGRS':
                {
                    controlData.push({
                        label: 'MGRS',
                        value: convertDDtoMGRS(wrapLongitudeCoordinates(coordinates.lng), coordinates.lat),
                    });
                }
                break;
            case 'WGS84UTM':
                {
                    controlData.push({
                        label: 'Easting',
                        value: convertDDtoUTM(
                            coordinates.lat,
                            wrapLongitudeCoordinates(coordinates.lng)
                        ).Easting.toLocaleString('en-US', { useGrouping: false }),
                    });
                    controlData.push({
                        label: 'Northing',
                        value: convertDDtoUTM(
                            coordinates.lat,
                            wrapLongitudeCoordinates(coordinates.lng)
                        ).Northing.toLocaleString('en-US', { useGrouping: false }),
                    });
                    controlData.push({
                        label: 'Zone',
                        value: `${
                            convertDDtoUTM(coordinates.lat, wrapLongitudeCoordinates(coordinates.lng)).ZoneNumber
                        }${convertDDtoUTM(coordinates.lat, wrapLongitudeCoordinates(coordinates.lng)).ZoneLetter}`,
                    });
                }
                break;
            case 'GDA94MGA':
                {
                    controlData.push({
                        label: 'X',
                        value: convertDDtoGDA2020(
                            coordinates.lat,
                            wrapLongitudeCoordinates(coordinates.lng)
                        ).x.toLocaleString('en-US', { minimumFractionDigits: 8, useGrouping: false }),
                    });
                    controlData.push({
                        label: 'Y',
                        value: convertDDtoGDA2020(
                            coordinates.lat,
                            wrapLongitudeCoordinates(coordinates.lng)
                        ).y.toLocaleString('en-US', { minimumFractionDigits: 8, useGrouping: false }),
                    });
                }
                break;

            default:
                return '';
        }

        return controlData
            .map(
                (data) => `<div class="coordinates-data"><strong>${data.label}</strong><span>${data.value}</span></div>`
            )
            .join('');
    };

    const handleSelectView = () => {
        const selectedView = StorageHelper.get(StorageKey) ?? DefaultView;

        const selectedViewIndex = Object.keys(CoordinatesView).indexOf(selectedView);
        const nextViewIndex = (selectedViewIndex + 1) % Object.keys(CoordinatesView).length;

        const nextView = Object.keys(CoordinatesView)[nextViewIndex];

        StorageHelper.set(StorageKey, nextView);

        updateView(lastLatLng);
    };

    const handleMouseMove = (e: L.LeafletMouseEvent) => {
        updateView(e.latlng);
        lastLatLng = e.latlng;
    };

    const updateView = (latlng: L.LatLng) => {
        const selectedView = StorageHelper.get(StorageKey) ?? DefaultView;
        const coordinateControlElement = document.getElementsByClassName('leaflet-control-coordinates-control')[0];

        if (coordinateControlElement) {
            coordinateControlElement.innerHTML = updateControl(latlng);
            coordinateControlElement.setAttribute('data-tooltip-content', CoordinatesView[selectedView]);
        }
    };

    coordinateControl.onAdd = (map: L.Map) => {
        const selectedView = StorageHelper.get(StorageKey) ?? DefaultView;

        const coordinateDiv = L.DomUtil.create('div');
        coordinateDiv.className = 'leaflet-control-coordinates-control';
        coordinateDiv.setAttribute('data-testid', 'coordinates-control');
        coordinateDiv.setAttribute('data-tooltip-id', 'control-tooltip');
        coordinateDiv.setAttribute('data-tooltip-content', CoordinatesView[selectedView]);
        coordinateDiv.innerHTML = updateControl(map.getCenter());

        coordinateDiv.onclick = () => {
            handleSelectView();
        };

        map.addEventListener('mousemove', handleMouseMove);

        return coordinateDiv;
    };

    coordinateControl.onRemove = (map: L.Map) => {
        map.removeEventListener('mousemove', handleMouseMove);
    };

    return coordinateControl;
};

const CoordinatesControl = createControlComponent(createCoordinatesControl);

export default CoordinatesControl;
