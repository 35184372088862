import React from 'react';
import { useSelector } from 'react-redux';
import PolygonAnnotation from '../../../MapView/Annotations/Polygon/polygon-annotation';
import { selectPolygons } from '../../../../store/Annotations/Polygon/selectors';

const MobilePolygon = () => {
    const polygons = useSelector(selectPolygons);

    return (
        <React.Fragment>
            {polygons.map((polygon) => {
                return (
                    <PolygonAnnotation
                        key={`
                            ${polygon.id}-
                            ${polygon.options.color}-
                            ${polygon.options.fillOpacity}-
                            ${polygon.options.dashArray}-
                            ${polygon.options.dashOffset}-
                            ${polygon.options.weight}-
                            ${polygon.showArea}-
                            ${polygon.showLength}-
                            ${polygon.units}-
                            ${polygon.labelColor}-
                            ${polygon.fillPattern ? polygon.fillPattern : ''}
                            ${polygon.zIndex}-
                            ${polygon?.positions ? JSON.stringify(polygon.positions) : ''}
                        `}
                        polygon={polygon}
                        isSelected={false}
                    />
                );
            })}
        </React.Fragment>
    );
};

export default MobilePolygon;
