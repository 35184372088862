import { ListingDTO, UserDTO } from '../../../api/model';
import { Action } from '../../root-reducer';
import { actionTypes as at } from './constants';

interface StoreUserProfile {
    userProfile: UserDTO | undefined;
    userProfileLoading: boolean;
    userListings: ListingDTO[];
    userListingsLoading: boolean;
}

const initialState: StoreUserProfile = {
    userProfile: undefined,
    userProfileLoading: false,
    userListings: [],
    userListingsLoading: false,
};

export default (state: StoreUserProfile = initialState, action: Action): StoreUserProfile => {
    switch (action.type) {
        case at.FETCH_USER_PROFILE_WITH_LISTINGS_BEGIN:
            return {
                ...state,
                userProfile: undefined,
            };

        case at.FETCH_USER_PROFILE_SUCCESS:
            return {
                ...state,
                userProfile: action.payload,
                userProfileLoading: false,
            };

        case at.FETCH_USER_PROFILE_LOADING:
            return {
                ...state,
                userProfileLoading: action.payload,
            };

        case at.FETCH_USER_LISTINGS_SUCCESS:
            return {
                ...state,
                userListings: action.payload,
                userListingsLoading: false,
            };

        case at.FETCH_USER_LISTINGS_LOADING:
            return {
                ...state,
                userListingsLoading: action.payload,
            };

        case at.FETCH_USER_PROFILE_WITH_LISTINGS_ERROR:
            return {
                ...state,
                userListings: [],
                userProfile: undefined,
            };

        case at.CLEAR_USER_PROFILE:
            return {
                ...state,
                userProfile: undefined,
            };

        case at.CLEAR_USER_LISTINGS:
            return {
                ...state,
                userListings: [],
            };

        default:
            return state;
    }
};
