import styled from 'styled-components';

export const MobileButton = styled.button`
    display: inline-block;
    padding: 6px 24px;

    font-size: ${(props) => props.theme.fontSize.normal};
    border-radius: 4px;
    border: none;

    transition: all 0.25s;

    user-select: none;

    &:hover,
    &:focus {
        outline: none;
    }

    &:active {
        transform: scale(1.1);
    }
`;

export const MobileButtonText = styled(MobileButton)`
    background: transparent;
    color: ${(props) => props.theme.color.white};

    &:active {
        font-weight: 700;
    }
`;

export const MobileButtonTransparent = styled(MobileButton)`
    background: transparent;
    color: ${(props) => props.theme.color.white};
    border: 1px solid ${(props) => props.theme.color.white} !important;

    &:active {
        background: ${(props) => props.theme.color.opaqueYellow};
    }
`;

export const MobileButtonPrimary = styled(MobileButton)`
    background: ${(props) => props.theme.color.yellow};
    color: ${(props) => props.theme.color.black};

    &:active {
        background: ${(props) => props.theme.color.opaqueYellow};
    }
`;

export const MobileButtonSecondary = styled(MobileButton)`
    background: ${(props) => props.theme.color.opaqueWhite};
    color: ${(props) => props.theme.color.black};

    &:active {
        background: ${(props) => props.theme.color.white};
    }
`;

export const MobileButtonDanger = styled(MobileButton)`
    background: ${(props) => props.theme.color.red};
    color: ${(props) => props.theme.color.black};
    border: 1px solid ${(props) => props.theme.color.red} !important;

    &:active {
        background: ${(props) => props.theme.color.white};
    }
`;

export const MobileButtonBack = (props: React.ButtonHTMLAttributes<HTMLButtonElement>) => {
    return (
        <MobileBackButton {...props}>
            <i className="fas fa-arrow-left" />
        </MobileBackButton>
    );
};

const MobileBackButton = styled(MobileButtonText)`
    position: absolute;
    left: 0;
    top: 12px;
`;
