import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    setRectangleOptionsAction,
    setRectangleUpdateAction,
    setRectanglesAction,
} from '../../../../store/Annotations/Rectangle/actions';
import { selectRectangles } from '../../../../store/Annotations/Rectangle/selectors';
import { setDeleteFillPatternAction, setSelectedAnnotationAction } from '../../../../store/Annotations/actions';
import { selectSelectedAnnotation } from '../../../../store/Annotations/selectors';
import DrawToolAnalytics from '../Analytics/draw-tool-analytics';
import LineStyle from '../Popup/line-style';
import FillColor from '../ToolBar/fill-color';
import AnnotationDelete from '../annotation-delete';
import { ToolbarContainer, ToolbarDivider, ToolbarText } from '../annotation-toolbar';
import LayerOrderControl from '../layer-order-control';
import Rectangle from './rectangle';

import { findAndUpdateAvailablePattern, Pattern } from '../FillPattern/availablePatterns';
import { handlePattternIdFromPatternFillUrl, handlePatternFillUrl } from '../FillPattern/pattern-util';
import RectangleMeasurementToolbar from './rectangle-measurement-toolbar';
import { MeasurementLengthType } from '../Measurement/measurement-toolbar-polygon-length';

const RectangleToolbar = () => {
    const dispatch = useDispatch();
    const rectangles = useSelector(selectRectangles);
    const selectedAnnotation = useSelector(selectSelectedAnnotation);
    const selectedRectangle = rectangles.find((c) => c.id === selectedAnnotation?.id);

    const updateRectangle = (rectangle: Rectangle) => {
        dispatch(setRectangleUpdateAction(rectangle));
    };

    if (!selectedRectangle) return <React.Fragment />;

    return (
        <ToolbarContainer>
            <ToolbarText>Fill: </ToolbarText>
            <FillColor
                onSelectColor={(color) => {
                    let selectedRectangleFillColor = selectedRectangle.options.fillColor as string;

                    const patternId = handlePattternIdFromPatternFillUrl(selectedRectangleFillColor);

                    if (patternId !== 'none') {
                        const updatedPattern = findAndUpdateAvailablePattern(selectedRectangle.id, patternId, color);

                        selectedRectangleFillColor = handlePatternFillUrl(updatedPattern as Pattern);
                    } else {
                        selectedRectangleFillColor = color;
                    }

                    updateRectangle({
                        ...selectedRectangle,
                        options: {
                            ...selectedRectangle.options,
                            fillColor: selectedRectangleFillColor,
                            color: color,
                        },
                    });
                    dispatch(
                        setRectangleOptionsAction({
                            ...selectedRectangle.options,
                            fillColor: selectedRectangleFillColor,
                            color: color,
                        })
                    );
                }}
                selectedColor={selectedRectangle.options.color || '#eed926'}
                opacity={selectedRectangle.options.fillOpacity || 0.2}
                onSetOpacity={(opacity) => {
                    updateRectangle({
                        ...selectedRectangle,
                        options: {
                            ...selectedRectangle.options,
                            fillOpacity: opacity,
                        },
                    });
                    dispatch(
                        setRectangleOptionsAction({
                            ...selectedRectangle.options,
                            fillOpacity: opacity,
                        })
                    );
                }}
                showFillPattern={true}
                onSetFillPatternUrl={(fillPattern: string) => {
                    if (fillPattern.includes('none')) {
                        updateRectangle({
                            ...selectedRectangle,
                            fillPattern: undefined,
                            options: {
                                ...selectedRectangle.options,
                                fillColor: selectedRectangle.options.color,
                                fillOpacity: 0.2,
                            },
                        });
                        dispatch(
                            setRectangleOptionsAction({
                                ...selectedRectangle.options,
                                fillColor: selectedRectangle.options.color,
                                fillOpacity: 0.2,
                            })
                        );
                        return;
                    }

                    updateRectangle({
                        ...selectedRectangle,
                        fillPattern: fillPattern,
                        options: {
                            ...selectedRectangle.options,
                            fillColor: fillPattern,
                            fillOpacity: 1,
                        },
                    });
                    dispatch(
                        setRectangleOptionsAction({
                            ...selectedRectangle.options,
                            fillColor: fillPattern,
                            fillOpacity: 1,
                        })
                    );
                }}
                fillPattern={handlePattternIdFromPatternFillUrl(selectedRectangle?.options?.fillColor as string)}
            />
            <ToolbarDivider />

            <ToolbarText>Line Style:</ToolbarText>
            <LineStyle
                selectedLineStyle={{
                    dashArray: selectedRectangle.options.dashArray,
                    weight: selectedRectangle.options.weight,
                }}
                onSelectLineStyle={(lineStyle) => {
                    updateRectangle({
                        ...selectedRectangle,
                        options: {
                            ...selectedRectangle.options,
                            dashArray: lineStyle.dashArray ? lineStyle.dashArray : undefined,
                            weight: lineStyle.weight ? lineStyle.weight : undefined,
                        },
                    });
                    dispatch(
                        setRectangleOptionsAction({
                            ...selectedRectangle.options,
                            dashArray: lineStyle.dashArray ? lineStyle.dashArray : undefined,
                            weight: lineStyle.weight ? lineStyle.weight : undefined,
                        })
                    );
                }}
            />

            <ToolbarDivider />
            <ToolbarText>Measurements:</ToolbarText>
            <RectangleMeasurementToolbar
                rectangle={selectedRectangle}
                onChangeShowArea={(showArea: boolean) => {
                    updateRectangle({
                        ...selectedRectangle,
                        showArea: showArea,
                    });
                }}
                onChangeShowLength={(showLength: MeasurementLengthType) => {
                    updateRectangle({
                        ...selectedRectangle,
                        showLength: showLength,
                    });
                }}
                onChangeUnits={(units) => {
                    updateRectangle({
                        ...selectedRectangle,
                        units: units,
                    });
                }}
                onChangeLabelColor={(labelColor) => {
                    updateRectangle({
                        ...selectedRectangle,
                        labelColor: labelColor,
                    });
                }}
                onChangeBackgroundColor={(labelBgColor) => {
                    updateRectangle({
                        ...selectedRectangle,
                        labelBgColor: labelBgColor,
                    });
                }}
            />

            <ToolbarDivider />
            <ToolbarText>Arrange:</ToolbarText>
            <LayerOrderControl annotation={selectedRectangle} />

            <ToolbarDivider />

            <AnnotationDelete
                onClick={() => {
                    dispatch(setDeleteFillPatternAction(selectedRectangle.id));
                    DrawToolAnalytics.delete('rectangle');
                    const updatedRectangles = rectangles.filter((c) => c.id !== selectedRectangle.id);
                    dispatch(setRectanglesAction(updatedRectangles));
                    dispatch(setSelectedAnnotationAction(undefined));
                }}
            />
        </ToolbarContainer>
    );
};

export default RectangleToolbar;
