import { Helmet } from 'react-helmet-async';

// Adds the adsense script to the head of the document
// Note that currently both test and prod use the same adsense script
const Adsense = () => {
    return (
        <Helmet>
            <script
                async
                src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-2920378478344292"
                crossOrigin="anonymous"
            ></script>
        </Helmet>
    );
};

export default Adsense;
