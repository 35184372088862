import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Button, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import { toast } from 'react-toastify';

import ApiOrder from '../../../../api/api-order';
import ApiAnalytics from '../../../../api/api-analytics';
import { AnalyticsAction, AnalyticsNote, CSWTaskStatus, OrderDTO, TaskFile } from '../../../../api/model';

import { MobileButtonText } from '../../Shared/button';
import { PulseLoader } from '../../../Shared/pulse-loader';
import Analytics from '../../../../lib/user-analytics';
import UriHelper from '../../../../lib/uri-helper';

interface SatelliteOrdersControlProps {
    satelliteOrder: OrderDTO | undefined;
    direction?: 'up' | 'down';
}

const SatelliteOrdersControl = (props: SatelliteOrdersControlProps) => {
    const [downloadOptionsOpen, setDownloadOptionsOpen] = useState<boolean>(false);
    const [downloadFilesLoading, setDownloadFilesLoading] = useState<boolean>(false);
    const [downloadFilesError, setDownloadFilesError] = useState<string>('');
    const [downloadFiles, setDownloadFiles] = useState<TaskFile[]>([]);
    const [downloadBusy, setDownloadBusy] = useState<string[]>([]);

    useEffect(() => {
        if (!props.satelliteOrder) {
            return;
        }

        setDownloadFilesError('');

        if (downloadOptionsOpen && downloadFiles.length === 0) {
            setDownloadFilesLoading(true);

            ApiOrder.getOrderDetails(props.satelliteOrder.id)
                .then((data) => {
                    setDownloadFiles(data.files);
                    setDownloadBusy([]);
                })
                .catch((error) => {
                    setDownloadFilesError(error.message);
                })
                .finally(() => {
                    setDownloadFilesLoading(false);
                });
        }
    }, [downloadFiles.length, downloadOptionsOpen, props.satelliteOrder]);

    if (!props.satelliteOrder) return <React.Fragment />;

    const { invoiceUrl, receiptUrl } = props.satelliteOrder.payment;

    let mainButton = '';
    const generateMainButton = () => {
        if (!props.satelliteOrder) return <React.Fragment />;

        switch (props.satelliteOrder.status) {
            case CSWTaskStatus.DELIVERED: {
                mainButton = 'View';
                return (
                    <SatOrdersMainButton onClick={handleView} className="view">
                        <SatOrdersButtonIcon className="fa fa-eye " />
                        <SatOrdersButtonText>View</SatOrdersButtonText>
                    </SatOrdersMainButton>
                );
            }
            default: {
                if (invoiceUrl) {
                    mainButton = 'Invoice';
                    return (
                        <SatOrdersMainButton onClick={handleOpenInvoice}>
                            <SatOrdersButtonIcon className="fa fa-external-link" />
                            <SatOrdersButtonText>Invoice</SatOrdersButtonText>
                        </SatOrdersMainButton>
                    );
                } else if (receiptUrl) {
                    mainButton = 'Receipt';
                    return (
                        <SatOrdersMainButton onClick={handleOpenReceipt}>
                            <SatOrdersButtonIcon className="fa fa-external-link" />
                            <SatOrdersButtonText>Receipt</SatOrdersButtonText>
                        </SatOrdersMainButton>
                    );
                }
            }
        }

        return <React.Fragment />;
    };

    const generateButtons = () => {
        if (!props.satelliteOrder) return <React.Fragment />;

        const buttons: JSX.Element[] = [];
        if (mainButton !== 'Pay') {
            if (receiptUrl && mainButton !== 'Receipt') {
                buttons.push(
                    <SatOrdersDropdownMenuItem onClick={handleOpenReceipt}>
                        <SatOrdersButtonIcon className="fa fa-external-link" />
                        &nbsp; Receipt
                    </SatOrdersDropdownMenuItem>
                );
            }
            if (invoiceUrl && mainButton !== 'Invoice') {
                buttons.push(
                    <SatOrdersDropdownMenuItem onClick={handleOpenInvoice}>
                        <SatOrdersButtonIcon className="fa fa-external-link" />
                        &nbsp; Invoice
                    </SatOrdersDropdownMenuItem>
                );
            }
            if (props.satelliteOrder.status === CSWTaskStatus.DELIVERED) {
                if (buttons.length > 0) {
                    buttons.push(<DropdownItem divider />);
                }
                buttons.push(
                    <Dropdown
                        isOpen={downloadOptionsOpen}
                        toggle={() => setDownloadOptionsOpen(!downloadOptionsOpen)}
                        direction={props.direction}
                    >
                        <SatOrdersDropdownMenuToggle>
                            <SatOrdersButtonIcon className="fa fa-download" />
                            &nbsp; Download
                            <SatOrdersButtonIconCaret className="fa fa-caret-down" aria-hidden="true" />
                        </SatOrdersDropdownMenuToggle>
                        <SatOrdersDropdownSubMenu>
                            {downloadFilesLoading && (
                                <SatOrdersDropdownLoading>
                                    <PulseLoader />
                                </SatOrdersDropdownLoading>
                            )}
                            {downloadFilesError && (
                                <SatOrdersDropdownError>{downloadFilesError}</SatOrdersDropdownError>
                            )}
                            {downloadFiles.map((file) => {
                                const extension = file.fileName.slice(file.fileName.lastIndexOf('.') + 1);
                                return (
                                    <SatOrdersDropdownMenuItem
                                        key={`task_file_${file.id}`}
                                        onClick={() => handleDownload(`${file.id}`)}
                                        disabled={downloadBusy.includes(`${file.id}`)}
                                    >
                                        {downloadBusy.includes(`${file.id}`) ? (
                                            <PulseLoader className="download-busy" iconSize="0.5rem" />
                                        ) : (
                                            `.${extension}`
                                        )}
                                    </SatOrdersDropdownMenuItem>
                                );
                            })}
                        </SatOrdersDropdownSubMenu>
                    </Dropdown>
                );
            }
        }

        if (buttons.length === 0) {
            return <React.Fragment />;
        }

        return (
            <React.Fragment>
                <SatOrdersToggleButton caret />
                <SatOrdersDropdownMenu>{buttons}</SatOrdersDropdownMenu>
            </React.Fragment>
        );
    };

    const handleView = () => {
        if (!props.satelliteOrder) return;

        Analytics.Event('Mobile Profile', 'Clicked to view satellite order', props.satelliteOrder.id);
        UriHelper.navigateToPath(`/task/${props.satelliteOrder.id}`);
    };

    const handleOpenLink = (link: string) => {
        window.open(link, 'popUpWindow', 'height=600,width=700,left=10,top=10,scrollbars=yes,menubar=no');
    };

    const handleOpenInvoice = () => {
        if (!props.satelliteOrder) return;
        if (!invoiceUrl) return;

        Analytics.Event('Mobile Profile', 'Clicked to view invoice', props.satelliteOrder.id);
        handleOpenLink(invoiceUrl);
    };

    const handleOpenReceipt = () => {
        if (!props.satelliteOrder) return;
        if (!receiptUrl) return;

        Analytics.Event('Mobile Profile', 'Clicked to view receipt', props.satelliteOrder.id);
        handleOpenLink(receiptUrl);
    };

    const handleDownload = (fileId: string) => {
        if (!props.satelliteOrder) return;

        Analytics.Event('Mobile Profile', 'Clicked to download satellite order', fileId);
        ApiAnalytics.postAnalyticsListing(
            AnalyticsAction.DOWNLOAD,
            AnalyticsNote.DASHBOARD_TASK,
            props.satelliteOrder.id,
            true
        );

        setDownloadBusy([...downloadBusy, fileId]);

        const windowOpen = window.open();

        ApiOrder.getOrderDownloadLink(props.satelliteOrder.id, fileId)
            .then((downloadLink) => {
                if (windowOpen) {
                    windowOpen.location = downloadLink;
                }
            })
            .catch(() => {
                toast.error('Failed to download file');
            })
            .finally(() => {
                setDownloadBusy(downloadBusy.filter((id) => id !== fileId));
            });
    };

    return (
        <UncontrolledDropdown group className="satorders-control">
            {generateMainButton()}
            {generateButtons()}
        </UncontrolledDropdown>
    );
};

export default SatelliteOrdersControl;

const SatOrdersButtonText = styled.span`
    color: ${(props) => props.theme.color.white};
`;

const SatOrdersButtonIcon = styled.i`
    font-size: ${(props) => props.theme.fontSize.large};
    color: ${(props) => props.theme.color.white};

    & + ${SatOrdersButtonText} {
        margin-left: 10px;
    }
`;

const SatOrdersMainButton = styled(Button)`
    background-color: ${(props) => props.theme.color.opaqueGray};
    white-space: nowrap;

    @media (max-width: 480px) {
        &.view {
            ${SatOrdersButtonText} {
                display: none;
            }
        }
    }
`;

const SatOrdersToggleButton = styled(DropdownToggle)`
    background-color: ${(props) => props.theme.color.opaqueGray};
    color: ${(props) => props.theme.color.white};
`;

const SatOrdersDropdownMenu = styled(DropdownMenu)`
    background-color: ${(props) => props.theme.color.opaqueGray};
    margin-right: 5px;
    width: 150px;
`;

const SatOrdersDropdownMenuItem = styled(MobileButtonText)`
    display: flex;
    color: ${(props) => props.theme.color.white};

    width: 100%;
    align-items: center;
    white-space: nowrap;

    padding: 12px 18px;

    &:disabled {
        .download-busy {
            padding-bottom: 0;
        }
    }
`;

const SatOrdersDropdownLoading = styled(DropdownItem)`
    padding: 12px 0;
`;

const SatOrdersDropdownError = styled(DropdownItem)`
    color: ${(props) => props.theme.color.red} !important;
    text-align: center;
    max-width: 120px;
    margin: 0 auto;
    padding: 4px;

    white-space: break-spaces;
`;

const SatOrdersDropdownMenuToggle = styled(DropdownToggle)`
    display: flex;
    justify-content: flex-start;
    align-items: center;

    color: ${(props) => props.theme.color.white};
    width: 100%;
    padding: 12px 14px 12px 18px;

    border: none;
    text-align: left;
`;

const SatOrdersDropdownSubMenu = styled(DropdownMenu)`
    background-color: ${(props) => props.theme.color.opaqueGray};
`;
const SatOrdersButtonIconCaret = styled.i`
    display: inline-block;
    margin-left: auto;
`;
