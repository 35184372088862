import React from 'react';
import { useSelector } from 'react-redux';
import { selectMarkers } from '../../../../store/Annotations/Marker/selectors';
import MarkerAnnotation from '../../../MapView/Annotations/Marker/marker-annotation';

const MobileMarker = () => {
    const markers = useSelector(selectMarkers);
    return (
        <React.Fragment>
            {markers.map((marker) => {
                return (
                    <MarkerAnnotation
                        key={`
                            ${marker.id}-
                            ${marker.color}-${marker.iconName}-
                            ${marker.zoomBase}-
                            ${marker.position.lat}-
                            ${marker.position.lng}-
                            ${marker.zIndex}-
                            ${marker.iconName}
                        `}
                        marker={marker}
                        isSelected={false}
                        onUpdate={() => ''}
                    />
                );
            })}
        </React.Fragment>
    );
};

export default MobileMarker;
