import styled from 'styled-components';

interface JoinTrustListProps {
    type: string;
}

const JoinTrustList = (props: JoinTrustListProps) => {
    switch (props.type) {
        case 'US Aerial':
            return (
                <ContentTrust>
                    <ContentTrustHeader>AS USED BY:</ContentTrustHeader>
                    <ContentBelievers>
                        <ContentBeliever
                            src="assets/join-page/us-aerial/fox-news-logo.png"
                            alt="Fox News"
                            title="Fox News"
                        />
                        <ContentBeliever
                            src="assets/join-page/us-aerial/coldwell-banker-logo.jpg"
                            alt="Coldwell Banker Realty"
                            title="Coldwell Banker Realty"
                        />
                        <ContentBeliever src="assets/join-page/bbc-news-logo.png" alt="BBC News" title="BBC News" />
                        <ContentBeliever
                            src="assets/join-page/nyt-logo-square.jpg"
                            alt="The New York Times"
                            title="The New York Times"
                        />
                    </ContentBelievers>
                </ContentTrust>
            );
        case 'AU Geology':
            return (
                <ContentTrust>
                    <ContentTrustHeader>AS USED BY:</ContentTrustHeader>
                    <ContentBelievers>
                        <ContentBeliever
                            src="assets/join-page/au-geology/fortescue-logo.png"
                            alt="Fortescue"
                            title="Fortescue"
                        />
                        <ContentBeliever
                            src="assets/join-page/au-geology/barrick-gold-corp-logo.png"
                            alt="Barrick Gold Corporation"
                            title="Barrick Gold Corporation"
                        />
                        <ContentBeliever src="assets/join-page/au-geology/bhp-logo.png" alt="BHP" title="BHP" />
                    </ContentBelievers>
                </ContentTrust>
            );
        default:
            return (
                <ContentTrust>
                    <ContentTrustHeader>TRUSTED BY</ContentTrustHeader>
                    <ContentBelievers>
                        <ContentBeliever
                            src="assets/join-page/nyt-logo-square.jpg"
                            alt="The New York Times"
                            title="The New York Times"
                        />
                        <ContentBeliever src="assets/join-page/bbc-news-logo.png" alt="BBC News" title="BBC News" />
                        <ContentBeliever
                            src="assets/join-page/ft-logo.svg"
                            alt="Financial Times"
                            title="Financial Times"
                        />
                    </ContentBelievers>
                </ContentTrust>
            );
    }
};

export default JoinTrustList;

export const ContentTrust = styled.div`
    margin-top: 42px;
`;
export const ContentTrustHeader = styled.h2`
    font-size: 1.75rem;
    font-weight: 700;
    margin-bottom: 20px;
`;

export const ContentBelievers = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
`;

export const ContentBeliever = styled.img`
    width: auto;
    height: 50px;
    margin: 0 10px;
`;
