import React, { useEffect } from 'react';

interface AdsenseComponentProps {
    className?: string;
    style?: object; // eslint-disable-line
    client: string;
    slot: string;
    layout?: string;
    layoutKey?: string;
    format?: string;
    responsive?: string;
}

const AdsenseComponent = (props: AdsenseComponentProps) => {
    useEffect(() => {
        //@ts-ignore
        (window.adsbygoogle = window.adsbygoogle || []).push({});
    }, []);

    return (
        <ins
            className={`${props.className ? props.className : ''} adsbygoogle`}
            style={props.style}
            data-ad-client={props.client}
            data-ad-slot={props.slot}
            data-ad-layout={props.layout}
            data-ad-layout-key={props.layoutKey}
            data-ad-format={props.format}
            data-full-width-responsive={props.responsive}
        ></ins>
    );
};

export default AdsenseComponent;
