import React, { useState } from 'react';
import PolygonPath from '../Polygon/polygon';
import Polyline from '../Polyline/polyline';
import Rectangle from '../Rectangle/rectangle';
import {
    MeasurementToolbarCheckbox,
    MeasurementToolbarOptionContainer,
    MeasurementToolbarOptionLabel,
} from './measurement-toolbar';
import { shouldAllowLineSegmentLength, shouldAllowLineSegmentTotalLength } from './measurement-util';

export type MeasurementLengthType = 'sections' | 'total' | false;

interface MeasurementToolbarPolygonLengthProps {
    poly: PolygonPath | Polyline | Rectangle;
    disabled: boolean;
    disabledTotal: boolean;
    onChangeShowLength: (showLength: MeasurementLengthType) => void;
    isPolyline?: boolean;
}

const MeasurementToolbarPolygonLength = (props: MeasurementToolbarPolygonLengthProps) => {
    const shouldShowEdgeLength = props.isPolyline && shouldAllowLineSegmentLength(props.poly as Polyline);
    const shouldShowEdgeTotalLength = props.isPolyline && shouldAllowLineSegmentTotalLength(props.poly as Polyline);
    const disabled = props.disabled || !shouldShowEdgeLength;
    const disabledTotal = props.disabledTotal || !shouldShowEdgeTotalLength;

    const [toggleLength, setToggleLength] = useState(props.poly.showLength === 'sections');
    const [toggleLengthTotal, setToggleLengthTotal] = useState(props.poly.showLength === 'total');

    const sectionTitleText = () => {
        if (props.disabled) {
            return 'There is too much geometry to show length labels accurately';
        } else if (shouldShowEdgeLength) {
            return 'Show section length';
        } else {
            return 'Length(s) too long to show length labels';
        }
    };

    const totalTitleText = () => {
        if (props.disabledTotal) {
            return 'There is only one segment, use section length instead';
        } else if (shouldShowEdgeTotalLength) {
            return 'Show total length';
        } else {
            return 'Length(s) too long to show total length labels';
        }
    };

    return (
        <React.Fragment>
            <MeasurementToolbarOptionContainer>
                <MeasurementToolbarOptionLabel htmlFor="poly-length-tick">
                    Show section Length:
                </MeasurementToolbarOptionLabel>
                <MeasurementToolbarCheckbox
                    id="poly-length-tick"
                    disabled={disabled}
                    title={sectionTitleText()}
                    type="checkbox"
                    checked={toggleLength}
                    onClick={() => {
                        setToggleLength(!toggleLength);
                        setToggleLengthTotal(false);

                        props.onChangeShowLength(!toggleLength ? 'sections' : false);
                    }}
                />
            </MeasurementToolbarOptionContainer>
            <MeasurementToolbarOptionContainer>
                <MeasurementToolbarOptionLabel htmlFor="poly-length-total-tick">
                    Show total Length:
                </MeasurementToolbarOptionLabel>
                <MeasurementToolbarCheckbox
                    id="poly-length-total-tick"
                    disabled={disabledTotal}
                    title={totalTitleText()}
                    type="checkbox"
                    value="total"
                    checked={toggleLengthTotal}
                    onClick={() => {
                        setToggleLength(false);
                        setToggleLengthTotal(!toggleLengthTotal);

                        props.onChangeShowLength(!toggleLengthTotal ? 'total' : false);
                    }}
                />
            </MeasurementToolbarOptionContainer>
        </React.Fragment>
    );
};

export default MeasurementToolbarPolygonLength;
