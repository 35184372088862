import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled, { css, keyframes } from 'styled-components';
import { AnalyticsNote, ListingDTO } from '../../../../../api/model';
import Analytics from '../../../../../lib/user-analytics';
import { selectUserProfile } from '../../../../../store/Map/UserProfile/selectors';
import MapSearchDrawListingCard from '../../ListingsDrawer/map-search-draw-listing-card';
import MapSearchDrawPreviewDetails from '../../ListingsDrawer/map-search-draw-preview-details';
import SideDrawerNested from '../../Shared/side-drawer-nested';
import InfiniteScroll from 'react-infinite-scroll-component';
import SideDrawerLoader from '../../Shared/side-drawer-loader';
import ApiListings from '../../../../../api/api-listings';
import { Subject } from 'rxjs';
import { debounceTime, tap } from 'rxjs/operators';
import Constants from '../../../../../constants';

const DEBOUNCE_TIME = 300; //ms

const ProfileAllMaps = () => {
    const [searchTerm, setSearchTerm] = useState('');
    const [debouncedSearchInput, setDebouncedSearchInput] = useState<string>('');
    const [searchSubject] = useState(() => new Subject<string>());

    const [userListings, setUserListings] = useState<ListingDTO[]>([]);
    const [userListingsTotal, setUserListingsTotal] = useState(0);
    const [userListingsLoading, setUserListingsLoading] = useState(false);
    const [nestedListingPreview, setNestedListingPreview] = useState<ListingDTO>();

    const userProfile = useSelector(selectUserProfile);

    const fetchListings = useCallback(() => {
        if (userProfile) {
            setUserListingsLoading(true);

            ApiListings.getListingsByUserId(
                userProfile.userId,
                Constants.APP.LIMIT.LISTINGS,
                userListings.length,
                searchTerm
            )
                .then(({ listings, total }) => {
                    setUserListings([...userListings, ...listings]);
                    setUserListingsTotal(total);
                })
                .finally(() => setUserListingsLoading(false));
        }
    }, [searchTerm, userListings, userProfile]);

    const clearListings = useCallback(() => {
        setUserListings([]);
        setUserListingsTotal(0);
    }, []);

    useEffect(() => {
        clearListings();
        fetchListings();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [debouncedSearchInput]);

    useEffect(() => {
        searchSubject.next(searchTerm);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchTerm]);

    useEffect(() => {
        const subscription = searchSubject
            .pipe(
                tap(() => {
                    ApiListings.cancelGetListings('Cancel get listings');
                }),
                debounceTime(DEBOUNCE_TIME)
            )
            .subscribe((next) => {
                setDebouncedSearchInput(next);
            });

        fetchListings();

        return () => {
            clearListings();
            subscription.unsubscribe();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (!userProfile) {
        return <React.Fragment />;
    }

    return (
        <Container>
            <SearchContainer>
                <SearchIcon className="fa fa-search" />
                <SearchInput
                    type="text"
                    placeholder="Search"
                    value={searchTerm}
                    onChange={(e) => {
                        setSearchTerm(e.target.value);
                    }}
                />
            </SearchContainer>
            <MapsContainer>
                {userListingsLoading && <ProfileAllMapsLoader />}
                {userListings?.length > 0 ? (
                    <ProfileAllMapsList
                        key={searchTerm}
                        initialScrollY={0}
                        dataLength={userListings.length}
                        next={() => fetchListings()}
                        hasMore={userListings.length < userListingsTotal}
                        loader={<ProfileAllMapsLoader />}
                        height={`calc(100vh - 160px)`}
                    >
                        {userListings &&
                            userListings.map((listing, index) => (
                                <SideDrawerNested
                                    key={`${listing.id}-${index}`}
                                    closeDrawer={nestedListingPreview?.id !== listing.id}
                                    width={'500px'}
                                    left={705}
                                    controlText={`View ${listing.title}`}
                                    controlComponent={
                                        <ListingContainer
                                            key={`${index}-${listing.id}`}
                                            onClick={(e) => {
                                                e.preventDefault();
                                                Analytics.Event('Side Drawer', 'Clicked To View', listing.id);
                                                setNestedListingPreview(listing);
                                            }}
                                            title={listing.title}
                                            isHighlighted={false}
                                            isExiting={false}
                                            index={index}
                                        >
                                            <MapSearchDrawListingCard listing={listing} isUserNameDisabled={true} />
                                        </ListingContainer>
                                    }
                                >
                                    <MapSearchDrawPreviewDetails
                                        listing={listing}
                                        isUserNameDisabled={true}
                                        analyticNote={AnalyticsNote.USER_PROFILE}
                                    />
                                </SideDrawerNested>
                            ))}
                    </ProfileAllMapsList>
                ) : !userListingsLoading ? (
                    <ErrorLabel>No Maps were found</ErrorLabel>
                ) : (
                    <React.Fragment />
                )}
            </MapsContainer>
        </Container>
    );
};

export default ProfileAllMaps;

const Container = styled.div`
    color: white;
`;

const MapsContainer = styled.div`
    margin-top: 10px;
`;

const ProfileAllMapsList = styled(InfiniteScroll)`
    overflow-y: auto;

    &::-webkit-scrollbar-track {
        background-color: transparent;
    }
    &::-webkit-scrollbar {
        width: 6px;
        background-color: transparent;
    }
    &::-webkit-scrollbar-thumb {
        background-color: ${(props) => props.theme.color.yellow};
        border-radius: 4px;
    }
`;

const ProfileAllMapsLoader = styled(SideDrawerLoader)`
    width: 290px;
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
    height: 0;
  }
  to {
    opacity: 1;
    height: 48px;
  }
`;

const fadeOut = keyframes`
  from {
    opacity: 1;
    height: 48px;
  }
  to {
    opacity: 0;
    height: 0;
  }
`;

const ListingContainer = styled.a<{ isHighlighted: boolean; isExiting: boolean; index: number }>`
    cursor: pointer;
    height: 70px !important;
    width: 98%;
    min-width: 98%;
    border-radius: 6px;
    overflow: hidden;
    display: block;
    position: relative;
    margin: 0 0 5px 0;
    border: 1px solid rgba(255, 255, 255, 0.1);
    background: rgba(0, 0, 0, 0.15);
    opacity: 0;

    &,
    * {
        text-decoration: none;
    }

    ${({ isExiting, index }) =>
        css`
            animation: ${isExiting ? fadeOut : fadeIn} 0.05s ease-in-out forwards;
            animation-delay: ${index * 0.025}s;
        `}

    &:hover {
        border: 1px solid ${(props) => props.theme.color.yellow};
        text-decoration: none;
    }
`;

const SearchContainer = styled.div`
    margin-top: -30px;
    width: 100%;
`;

const SearchIcon = styled.i`
    size: 20px;
    padding: 10px;
    position: relative;
    color: ${(props) => props.theme.color.yellow};
    top: 35px;
`;

const SearchInput = styled.input`
    background: transparent;
    color: #ffffff;
    padding: 5px;
    width: 100%;
    border: 1px solid rgb(255 255 255 / 0.3);
    border-radius: 6px;
    padding-left: 30px;
    min-height: 35px;

    &:focus {
        background: transparent;
        outline: ${(props) => props.theme.color.yellow};
        border: 1px solid ${(props) => props.theme.color.yellow};
        color: #ffffff;
    }
`;

const ErrorLabel = styled.div`
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 100%;
    height: 25px;
    color: #ffffff;

    opacity: 0.3;
    margin-left: 5px;
`;
