import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import { selectLoggedIn, selectMyProfile } from '../../../../store/Account/selectors';

import UserHelper from '../../../../lib/user-helper';

import { Form, FormGroup, Input, InputHint, Label } from '../../Shared/form';
import { Content, ContentError, ContentErrorIcon } from '../../Shared/containers';
import { MobileButtonPrimary } from '../../Shared/button';
import { passwordActiveUrl, passwordInActiveUrl } from '../../../Registration/registration-icons';
import { PulseLoader } from '../../../Shared/pulse-loader';
import CognitoUtil from '../../../../lib/cognito-util';
import Analytics from '../../../../lib/user-analytics';
import InfoModal from '../../../Shared/Modal/info';

interface MobileAccountDetailsProps {
    onSuccess: () => void;
    onCancel: () => void;
    onChange: (hasChanges: boolean) => void;
}

const MobileAccountDetails = (props: MobileAccountDetailsProps) => {
    const isLoggedIn = useSelector(selectLoggedIn);
    const myProfile = useSelector(selectMyProfile);

    const [error, setError] = useState<string>('');
    const [hasChanges, setHasChanges] = useState<boolean>(false);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [submitSuccess, setSubmitSuccess] = useState<boolean>(false);

    const [oldPassword, setOldPassword] = useState<string>('');
    const [newPassword, setNewPassword] = useState<string>('');
    const [confirmNewPassword, setConfirmNewPassword] = useState<string>('');
    const [showOriginalPassword, setShowOriginalPassword] = useState<boolean>(false);
    const [showNewPassword, setShowNewPassword] = useState<boolean>(false);
    const [showConfirmNewPassword, setShowConfirmNewPassword] = useState<boolean>(false);

    useEffect(() => {
        window.addEventListener('beforeunload', blockExit);

        return () => {
            setHasChanges(false);
            window.removeEventListener('beforeunload', blockExit);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => props.onChange(hasChanges), [hasChanges, props]);

    useEffect(() => {
        if (!isLoggedIn || !myProfile) {
            props.onCancel();
            resetForm();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [myProfile, isLoggedIn]);

    useEffect(() => {
        if (oldPassword !== '' || newPassword !== '' || confirmNewPassword !== '') {
            setHasChanges(true);
        } else {
            setHasChanges(false);
        }
    }, [confirmNewPassword, newPassword, oldPassword]);

    const handleSubmitEdit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setError('');

        if (validForm()) {
            setIsSubmitting(true);

            const accessToken = UserHelper.getAccessToken();
            if (accessToken) {
                CognitoUtil.updatePassword(oldPassword, confirmNewPassword, accessToken)
                    .then(() => {
                        Analytics.Event('My Dashboard', 'Clicked to reset password');

                        setSubmitSuccess(true);
                    })
                    .catch((err) => {
                        let errorMessage = err.message;
                        if (err.name === 'NotAuthorizedException') {
                            errorMessage = 'Original password is incorrect';
                        }
                        setError(errorMessage);
                    })
                    .finally(() => {
                        resetForm();
                        setIsSubmitting(false);
                    });
            }
        }
    };

    const blockExit = (e: BeforeUnloadEvent) => {
        if (!hasChanges) return;

        e.preventDefault();

        if (e && e.returnValue) {
            e.returnValue = 'It seems you have some unsaved changes, do you want to continue?';
        }
        return 'It seems you have some unsaved changes, do you want to continue?';
    };

    const validForm = () => {
        if (oldPassword === '') {
            setError('Original password is empty');
            resetForm();
            return false;
        }

        if (!UserHelper.isPasswordValid(newPassword)) {
            setIsSubmitting(false);
            setError('Password must be at least 6 characters');
            resetForm();
            return false;
        }

        if (oldPassword === confirmNewPassword) {
            setIsSubmitting(false);
            setError('Your new password is not valid');
            resetForm();
            return false;
        }

        if (newPassword !== confirmNewPassword) {
            setIsSubmitting(false);
            setError('Confirm password does not match');
            setNewPassword('');
            setConfirmNewPassword('');
            return false;
        }

        return true;
    };

    const resetForm = () => {
        setOldPassword('');
        setNewPassword('');
        setConfirmNewPassword('');
    };

    if (!myProfile || !isLoggedIn) {
        return <React.Fragment />;
    }

    return (
        <AccountEditContent>
            <AccountEditDetails>
                <Form onSubmit={(e) => handleSubmitEdit(e)}>
                    <AccountDetailHeader>Reset Password</AccountDetailHeader>
                    <FormGroup>
                        <Label>Original Password</Label>
                        <Input
                            data-sentry-block
                            type={showOriginalPassword ? 'text' : 'password'}
                            placeholder="Enter your original password..."
                            value={oldPassword}
                            onChange={(e) => {
                                setOldPassword(e.target.value);
                                setError('');
                            }}
                        />
                        <PasswordIcon
                            showPassword={showOriginalPassword}
                            onClick={() => setShowOriginalPassword(!showOriginalPassword)}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label>New Password</Label>
                        <Input
                            data-sentry-block
                            type={showNewPassword ? 'text' : 'password'}
                            placeholder="Enter your new password..."
                            value={newPassword}
                            onChange={(e) => {
                                setNewPassword(e.target.value);
                                setError('');
                            }}
                        />
                        <PasswordIcon
                            showPassword={showNewPassword}
                            onClick={() => setShowNewPassword(!showNewPassword)}
                        />
                        <InputHint>Password must be at least 6 characters</InputHint>
                    </FormGroup>
                    <FormGroup>
                        <Label>Confirm Password</Label>
                        <Input
                            data-sentry-block
                            type={showConfirmNewPassword ? 'text' : 'password'}
                            placeholder="Confirm your new password"
                            value={confirmNewPassword}
                            onChange={(e) => {
                                setConfirmNewPassword(e.target.value);
                                setError('');
                            }}
                        />
                        <PasswordIcon
                            showPassword={showConfirmNewPassword}
                            onClick={() => setShowConfirmNewPassword(!showConfirmNewPassword)}
                        />
                    </FormGroup>
                    <AccountDetailHeader>&nbsp;</AccountDetailHeader>
                    {error && (
                        <ContentError>
                            <ContentErrorIcon />
                            {error}
                        </ContentError>
                    )}
                    {!isSubmitting ? (
                        <AccountEditSubmitButton type="submit" error={error ? true : false}>
                            APPLY CHANGES
                        </AccountEditSubmitButton>
                    ) : (
                        <PulseLoader />
                    )}
                </Form>
            </AccountEditDetails>
            {submitSuccess ? (
                <InfoModal
                    isOpen={true}
                    title={`Reset Password for ${myProfile.email}`}
                    onToggle={() => setSubmitSuccess(true)}
                    onContinue={() => props.onSuccess()}
                    continueText={'CLOSE'}
                >
                    <AccountEditSuccessMessage>Success! Your password has been updated!</AccountEditSuccessMessage>
                </InfoModal>
            ) : null}
        </AccountEditContent>
    );
};

export default MobileAccountDetails;

const AccountEditContent = styled(Content)`
    padding: 32px 16px;
`;

const AccountEditDetails = styled.div``;

const AccountDetailHeader = styled.h3`
    color: ${(props) => props.theme.color.white};

    margin-top: 32px;
`;

interface PasswordIconProps {
    showPassword: boolean;
}

const PasswordIcon = styled.div<PasswordIconProps>`
    background-image: url(${(props) => (props.showPassword ? passwordActiveUrl : passwordInActiveUrl)});
    background-repeat: no-repeat;
    width: 25px;
    height: 20px;
    float: right;
    margin-top: -30px;
    margin-right: 5px;
    opacity: 0.75;
`;

interface ErrorProps {
    error: boolean;
}

const AccountEditSubmitButton = styled(MobileButtonPrimary)<ErrorProps>`
    margin: 0 auto;
    margin-top: ${(props) => (props.error ? '0px' : '20px')};
    background-color: gray;
    width: 100%;

    :not(:disabled) {
        cursor: pointer;
        background-color: #eed926 !important;
        -webkit-text-fill-color: black;
        color: black;
    }
`;

const AccountEditSuccessMessage = styled.p``;
