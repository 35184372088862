import styled from 'styled-components';
import { ListingDTO } from '../../../../api/model';
import ShareService from '../../../../lib/share-service';
import Analytics from '../../../../lib/user-analytics';

interface ActiveMapShareButtonProps {
    listing: ListingDTO;
}

const ActiveMapShareButton = (props: ActiveMapShareButtonProps) => {
    const onClickShare = () => {
        const shortUrl = ShareService.shareMapUrl(props.listing.seoTitle || props.listing.id);

        const copyToClipboard = (shortUrl: string) => {
            if (navigator.clipboard) {
                navigator.clipboard.writeText(shortUrl);
                alert('Share link copied to clipboard');
                Analytics.Event(`Mobile`, 'Share by copy to clipboard', props.listing.id);
            } else {
                alert('No clipboard available. Please copy the link manually');
            }
        };

        // Attempt to use the native mobile share dialog first
        if (navigator.share) {
            navigator
                .share({
                    title: props.listing.title,
                    text: props.listing.description,
                    url: shortUrl,
                })
                .then(() => {
                    Analytics.Event(`Mobile`, 'Share by native share dialog', props.listing.id);
                })
                .catch(() => {
                    // The native share dialog failed, possibly due to permissions. Fall back to copying to clipboard
                    copyToClipboard(shortUrl);
                });
        } else {
            // The native share dialog is not available on this device (eg, local development). Fall back to copying to clipboard
            copyToClipboard(shortUrl);
        }
    };

    return (
        <Button onClick={onClickShare}>
            <Icon
                src={`data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' fill='white'%3E%3Cpath d='M307 34.8c-11.5 5.1-19 16.6-19 29.2l0 64-112 0C78.8 128 0 206.8 0 304C0 417.3 81.5 467.9 100.2 478.1c2.5 1.4 5.3 1.9 8.1 1.9c10.9 0 19.7-8.9 19.7-19.7c0-7.5-4.3-14.4-9.8-19.5C108.8 431.9 96 414.4 96 384c0-53 43-96 96-96l96 0 0 64c0 12.6 7.4 24.1 19 29.2s25 3 34.4-5.4l160-144c6.7-6.1 10.6-14.7 10.6-23.8s-3.8-17.7-10.6-23.8l-160-144c-9.4-8.5-22.9-10.6-34.4-5.4z'/%3E%3C/svg%3E`}
            />
            Share
        </Button>
    );
};

export default ActiveMapShareButton;

const Button = styled.button`
    background: rgba(0, 0, 0, 0.4);
    backdrop-filter: blur(5px);
    border: 1px solid rgba(142, 145, 143, 0.3);
    border-radius: 6px;
    height: 40px;
    letter-spacing: 0.25px;
    min-width: 40px;
    margin: 5px;
    outline: none !important;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    max-width: 100%;
    pointer-events: auto;
    color: white;
    font-size: 16px;
`;

const Icon = styled.img`
    height: 20px;
    margin: 0 6px 0 2px;
`;
