export const actionTypes = {
    MAP_ACTIVE_TILE_LAYER_CHANGED: 'MAP/ACTIVE_TILE_LAYER_CHANGED',

    MAP_404_TILE_LAYER: 'MAP/MAP_404_TILE_LAYER',

    MAP_ACTIVE_TILE_LAYER_FETCHING: 'MAP/MAP_ACTIVE_TILE_LAYER_FETCHING',
    MAP_ACTIVE_TILE_LAYER_LOADING: 'ALL_MAPS/MAP_ACTIVE_TILE_LAYER_LOADING',
    MAP_FETCH_TILE_LAYER_BY_ID_SUCCESS: 'ALL_MAPS/FETCH_TILE_LAYER_BY_ID_SUCCESS',
    MAP_CLEAR_TILE_LAYER: 'ALL_MAPS/MAP_CLEAR_TILE_LAYER',
    MAP_CLEAR_FETCHED_TILE_LAYER: 'ALL_MAPS/MAP_CLEAR_FETCHED_TILE_LAYER',
};
