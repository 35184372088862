import React from 'react';
import styled from 'styled-components';
import moment from 'moment';

import { UserDTO } from '../../../../api/model';
import UserAvatar from '../../../Shared/Avatar/user-avatar';
import ProfileIconUrl from '../../../Dashboard/profile-icon-url';
import SoarModal, { StyledModalBody } from '../../../Shared/soar-modal';
import EditProfileAvatar from '../../../Dashboard/Profile/edit-profile-avatar';

interface MobileEditAvatarProps {
    profile: UserDTO;
    onChange: (file?: File) => void;
    onUpdate: () => void;
    avatarPreview?: string;
}

const MobileEditAvatar = (props: MobileEditAvatarProps) => {
    const [showEditAvatar, setShowEditAvatar] = React.useState<boolean>(false);

    const handleToggle = () => {
        setShowEditAvatar(!showEditAvatar);
    };

    return (
        <ProfileEditAvatar>
            <ProfileAvatarContainer icon={ProfileIconUrl.cameraUrl}>
                {props.avatarPreview ? (
                    <ProfileAvatarPreview
                        src={props.avatarPreview}
                        alt="Profile Avatar Preview"
                        onClick={() => setShowEditAvatar(true)}
                    />
                ) : (
                    <ProfileAvatar
                        user={props.profile}
                        margin="0px 0px 0px 0px"
                        diameter={120}
                        onClick={() => setShowEditAvatar(true)}
                    />
                )}
            </ProfileAvatarContainer>
            {props.profile?.createdAt && (
                <ProfileMemberSince>
                    Member since {moment((props.profile.createdAt || 0) * 1000).format('D MMM YYYY')}
                </ProfileMemberSince>
            )}

            <SoarModal
                title="Edit your profile picture"
                isOpen={showEditAvatar}
                toggle={() => handleToggle()}
                width="360px"
            >
                <StyledModalBody>
                    <EditProfileAvatar
                        myProfile={props.profile}
                        onAvatarUnchanged={() => handleToggle()}
                        onAvatarUpdated={(file) => props.onChange(file)}
                        setHasChangedAvatar={(changed) => {
                            if (changed) props.onUpdate();
                        }}
                    />
                </StyledModalBody>
            </SoarModal>
        </ProfileEditAvatar>
    );
};

export default MobileEditAvatar;

const ProfileEditAvatar = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

interface ProfileAvatarContainerProps {
    icon: string;
}

const ProfileAvatarContainer = styled.div<ProfileAvatarContainerProps>`
    width: 120px;
    height: 120px;
    position: relative;

    &:after {
        content: '';
        position: absolute;
        bottom: 0;
        right: 0;
        z-index: 10;

        display: inline-block;
        width: 28px;
        height: 28px;

        background-position: center;
        background-size: contain;
        background-image: ${(props) => (props.icon ? `url("${props.icon}")` : 'none')};

        transition: all 0.5s;
    }

    &:active {
        &:after {
            transform: scale(1.1);
        }
    }
`;

const ProfileAvatar = styled(UserAvatar)``;

const ProfileAvatarPreview = styled.img`
    width: 100%;
    height: 100%;

    border-radius: 50%;
    border: 2px solid ${(props) => props.theme.color.yellow};
`;

const ProfileMemberSince = styled.p`
    margin-top: 1.5rem;

    color: ${(props) => props.theme.color.white};
`;
