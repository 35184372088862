import styled from 'styled-components';

import Api from '../../../api/api';
import { ListingDTO, ListingType, Review } from '../../../api/model';

interface StatusLabelProps {
    listing: ListingDTO;
    className?: string;
}

const StatusLabel = (props: StatusLabelProps) => {
    if (props.listing.listingType === ListingType.NOT_SUPPORTED) {
        return <StatusLabelPill className={`review ${props.className}`}>NOT SUPPORTED</StatusLabelPill>;
    }

    switch (Api.apiReviewToEnum(props.listing.review)) {
        case Review.APPROVED:
            return <StatusLabelPill className={`approved ${props.className}`}>APPROVED</StatusLabelPill>;
        case Review.REJECTED:
            return <StatusLabelPill className={`rejected ${props.className}`}>REJECTED</StatusLabelPill>;
        case Review.PENDING:
            return <StatusLabelPill className={`rejected ${props.className}`}>PENDING</StatusLabelPill>;
        default:
            return <StatusLabelPill className={`review ${props.className}`}>NOT SUPPORTED</StatusLabelPill>;
    }
};

export default StatusLabel;

const StatusLabelPill = styled.div`
    background-color: rgb(0, 0, 0, 0.8);
    height: 22px;
    width: 70px;
    border-radius: 6px;

    &.approved {
        color: ${(props) => props.theme.color.green};
        display: none;
    }

    &.review {
        color: ${(props) => props.theme.color.orange};

        font-size: 12px;
        font-weight: bold;
        padding: 2px 5px 2px 5px;
    }

    &.rejected {
        color: ${(props) => props.theme.color.red};

        font-size: 12px;
        font-weight: bold;
        padding: 2px 5px 2px 5px;
    }
`;
