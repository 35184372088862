import React from 'react';
import Masonry from 'react-masonry-css';
import styled from 'styled-components';

import { ListingDTO } from '../../../../api/model';
import { ListingTabPanelEmpty, ListingTabPanelLoading } from '../Shared/panels';
import DiscoverCardGridView from '../../../Drawer/SideDrawer/DiscoverDrawer/discover-card-grid-view';
import { useWindowSize } from '@react-hook/window-size';

interface MobileProfileListingsProps {
    listingsLoading: boolean;
    listings: ListingDTO[] | undefined;
}

const MAX_CARD_WIDTH = 185;

const MobileProfileListings = (props: MobileProfileListingsProps) => {
    const [windowWidth] = useWindowSize();

    const masonryBreakPoints = (windowWidth: number): number => {
        const numCards = Math.floor(windowWidth / MAX_CARD_WIDTH);
        return numCards;
    };

    const sortedListings = props.listings?.sort((a, b) =>
        (a.updatedAt ?? a.createdAt) > (b.updatedAt ?? b.createdAt) ? -1 : 1
    );

    if (props.listingsLoading) {
        return <ListingTabPanelLoading />;
    }

    if (sortedListings) {
        if (sortedListings.length === 0) {
            return (
                <ListingTabPanelEmpty
                    iconUrl={`/assets/mobile/map-yellow.svg`}
                    message="It looks like your Maps are empty"
                />
            );
        }

        return (
            <React.Fragment>
                <ProfileMasonry
                    breakpointCols={masonryBreakPoints(windowWidth)}
                    className="masonry-grid"
                    columnClassName="masonry-grid_column"
                >
                    {sortedListings.map((listing, index) => (
                        <DiscoverCardGridView key={index} listing={listing} hideAuthor={true} showStatus={true} />
                    ))}
                </ProfileMasonry>
            </React.Fragment>
        );
    }
    return <React.Fragment />;
};

export default MobileProfileListings;

const ProfileMasonry = styled(Masonry)`
    &.masonry-grid {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        width: auto;
    }

    &.masonry-grid_column {
        background-clip: padding-box;
        will-change: transform;
    }
`;
