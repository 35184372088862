import { useLocation } from 'react-router';
import { TabBarState } from './TabBar/tab-bar';
import { useEffect, useState } from 'react';
import UriHelper from '../../lib/uri-helper';

/*
    This hook should be declared at the top level component, re: `mobile.tsx`.
    If this will be used in other components, it will have a new state for each component.
    It will not pass or remember the state from the previous component.
    */

export const useMobileBrowserState = () => {
    const [tabBarState, setTabBarState] = useState<TabBarState | undefined>(undefined);
    const url = useLocation();

    useEffect(() => {
        const pos = UriHelper.tryGetParam('pos');

        let path = '';
        switch (tabBarState) {
            case 'maps':
                path = `/maps${pos ? `?pos=${pos}` : ''}`;
                break;
            case 'satellites':
                path = `/satellites${pos ? `?pos=${pos}` : ''}`;
                break;
            case 'drawProject':
                path = '/draw';
                break;
            case 'discover':
                path = '/discover';
                break;
            case 'profile':
                path = '/profile';
                break;
            default:
                break;
        }

        if (path) {
            window.history.pushState(null, '', path);
        }
    }, [tabBarState]);

    useEffect(() => {
        if (url.pathname === '/' || url.pathname === '/maps' || url.pathname.includes('/maps')) {
            setTabBarState('maps');
        } else if (url.pathname.includes('/satellites/')) {
            setTabBarState('satellite_view');
        } else if (url.pathname === '/satellites') {
            setTabBarState('satellites');
        } else if (url.pathname.includes('/task/')) {
            setTabBarState('satellite_order_view');
        } else if (url.pathname === '/discover') {
            setTabBarState('discover');
        } else if (url.pathname.includes('/profile') && url.pathname !== '/profile') {
            setTabBarState('profile_view');
        } else if (url.pathname === '/profile') {
            setTabBarState('profile');
        } else if (url.pathname === '/password_reset') {
            setTabBarState('profile_reset');
        } else {
            setTabBarState('maps');
            console.log('No match for path: ', url.pathname);
        }
    }, [url]);

    return { tabBarState: tabBarState, setTabBarState: setTabBarState };
};
