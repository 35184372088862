import styled from 'styled-components';
import SearchResultsBreadcrumbs from '../../../MapView/Annotations/StoryMaps/StoryBuilder/SearchModal/search-results-breadcrumbs';

const SearchNoResults = () => {
    return (
        <EmptySearchView>
            <SearchIcon className="fa fa-search" />
            <EmptySearchText>
                We searched the maps for <SearchResultsBreadcrumbs /> but found no results.
                <EmptySearchHelpText>Try changing the search to see what else you can find.</EmptySearchHelpText>
            </EmptySearchText>
        </EmptySearchView>
    );
};

export default SearchNoResults;

const EmptySearchView = styled.div`
    color: white;
    height: 50%;
    position: absolute;
    top: 20%;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    text-align: center;
    width: 90%;
`;

const SearchIcon = styled.i`
    color: #eed926;
    font-size: 74px;
    left: -15px;
    top: -15px;
    padding: 10px;
    z-index: 99999;
    border-radius: 8px;
    background: #191a1a;
    box-shadow: 0px 11px 14px -7px rgb(0 0 0 / 30%), 0px 23px 36px 3px rgb(0 0 0 / 24%),
        0px 9px 44px 8px rgb(0 0 0 / 22%);
    border: 2px solid #eed926;
    margin-bottom: -50px;
    position: initial;
`;

const EmptySearchText = styled.div`
    color: white;
    border: 2px solid #eed926;
    border-radius: 8px;
    font-size: 16px;
    margin: auto;
    padding: 60px 30px 60px 30px;
    text-align: center;
`;

const EmptySearchHelpText = styled.div`
    margin-top: 20px;
    color: white;
    font-size: 14px;
`;
