import {
    createElementHook,
    createElementObject,
    LeafletContextInterface,
    PathProps,
    useLayerLifecycle,
    useLeafletContext,
} from '@react-leaflet/core';
import L from 'leaflet';
import LayersUtil from '../layers-util';
import { selectedPolygonOutlineOptions } from '../Polygon/polygon';
import { polylineToPaddedPolygon } from '../use-select-annotation-utils';
import FreehandPolyline from './freehand-polyline';
import { createPolylineDragElement } from '../Polyline/polyline-drag-element';
import { shouldAllowLineSegmentLength } from '../Measurement/measurement-util';
import { createLengthLabel, createTotalLengthLabel } from '../Measurement/length-label';

interface FreehandPolylineProps extends PathProps {
    freehandPolyline: FreehandPolyline;
    positions: L.LatLng[];
    options: L.PolylineOptions;
    children?: React.ReactNode;
    isSelected: boolean;
    isDisabled?: boolean;
    onUpdate: (freehand: FreehandPolyline) => void;
}

const createFreehandPolyline = (props: FreehandPolylineProps, context: LeafletContextInterface) => {
    const paneId = LayersUtil.getPaneId(context.map, props.freehandPolyline);
    const freehandPolylineOptions: L.PolylineOptions = {
        ...props.options,
        interactive: true,
        pane: paneId,
    };

    const pane = LayersUtil.getPaneId(context.map, props.freehandPolyline);
    const freehandPolyline = new L.Polyline(props.positions, { ...freehandPolylineOptions, pane: pane });
    const freehandPolylineElement = createElementObject<L.Polyline, PathProps>(freehandPolyline, context);

    const polylineDragElement = createPolylineDragElement(
        {
            polylineElement: freehandPolylineElement,
            positions: props.freehandPolyline.positions,
            isSelected: props.isSelected,
        },
        context
    );

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let polylineMeasurementLabels: any[] = [];

    const addPathMeasurementLabels = () => {
        if (shouldAllowLineSegmentLength(props.freehandPolyline)) {
            if (props.freehandPolyline.showLength === 'sections') {
                (freehandPolylineElement.instance.getLatLngs() as L.LatLng[]).forEach((latLng, index) => {
                    if (index < (freehandPolylineElement.instance.getLatLngs() as L.LatLng[]).length - 1) {
                        const nextLatLng = (freehandPolylineElement.instance.getLatLngs() as L.LatLng[])[index + 1];
                        const label = createLengthLabel(
                            latLng,
                            nextLatLng,
                            freehandPolylineElement.instance.getLatLngs() as L.LatLng[],
                            props.freehandPolyline.units,
                            props.freehandPolyline.labelColor,
                            props.freehandPolyline.labelBgColor,
                            paneId,
                            context
                        );
                        polylineMeasurementLabels.push(label);
                        context.map.addLayer(label.instance);
                    }
                });
            } else if (props.freehandPolyline.showLength === 'total') {
                createTotalLengthLabel(
                    freehandPolylineElement.instance.getLatLngs() as L.LatLng[],
                    props.freehandPolyline.units,
                    props.freehandPolyline.labelColor,
                    props.freehandPolyline.labelBgColor,
                    paneId,
                    context
                ).then((label) => {
                    polylineMeasurementLabels.push(label);
                    context.map.addLayer(label.instance);
                });
            }
        } else {
            removePathMeasurementLabels();
            if (props.onUpdate) {
                props.onUpdate({
                    ...props.freehandPolyline,
                    positions: freehandPolylineElement.instance.getLatLngs() as L.LatLng[],
                    showLength: false,
                });
            }
        }
    };

    const removePathMeasurementLabels = () => {
        polylineMeasurementLabels.forEach((label) => {
            context.map.removeLayer(label.instance);
        });
        polylineMeasurementLabels = [];
    };

    const selectedOutlinePaneId = LayersUtil.getSelectedOutlinePaneId(context.map);
    const freehandBounds = new L.Polygon(polylineToPaddedPolygon(context.map, props.freehandPolyline.positions), {
        ...selectedPolygonOutlineOptions,
        pane: selectedOutlinePaneId,
    });
    const freehandBoundsElement = createElementObject<L.Polygon, PathProps>(freehandBounds, context);

    freehandPolylineElement.instance.on('remove', () => {
        context.map.removeLayer(freehandBoundsElement.instance);
        removePathMeasurementLabels();
    });

    freehandPolylineElement.instance.on('add', () => {
        if (props.isSelected) {
            context.map.addLayer(freehandBoundsElement.instance);
            context.map.addLayer(polylineDragElement.instance);
        } else {
            context.map.removeLayer(freehandBoundsElement.instance);
            context.map.removeLayer(polylineDragElement.instance);
        }

        if (props.freehandPolyline.showLength) {
            addPathMeasurementLabels();
        } else {
            removePathMeasurementLabels();
        }
    });

    freehandPolylineElement.instance.on('path-drag-start', () => {
        context.map.removeLayer(freehandBoundsElement.instance);
    });

    freehandPolylineElement.instance.on('path-drag-end', () => {
        freehandBoundsElement.instance.setLatLngs(
            polylineToPaddedPolygon(context.map, freehandPolylineElement.instance.getLatLngs() as L.LatLng[])
        );
        context.map.addLayer(freehandBoundsElement.instance);
    });

    freehandPolylineElement.instance.on('update', () => {
        const freehandPolyline: FreehandPolyline = {
            ...props.freehandPolyline,
            positions: freehandPolylineElement.instance.getLatLngs() as L.LatLng[],
            bounds: freehandBoundsElement.instance.getBounds(),
        };
        props.onUpdate(freehandPolyline);
    });

    // The interactive option adds or removes the leaflet-interactive class which changes the cursor
    // This means we have a third state of interactivity that needs to be handled when the annotation is disabled
    // otherwise hovering when editing will not show the pointer cursor
    if (props.isDisabled) {
        freehandPolylineElement.instance.options.interactive = false;
    } else {
        freehandPolylineElement.instance.options.interactive = true;
    }

    return freehandPolylineElement;
};

const updateFreehandPolyline = (instance: L.Polyline, props: FreehandPolylineProps, _: FreehandPolylineProps) => {
    instance.setStyle(props.options);
};

const useFreehandPolyline = createElementHook(createFreehandPolyline, updateFreehandPolyline);

const FreehandPolylineAnnotation = (props: FreehandPolylineProps) => {
    const context = useLeafletContext();
    const freehandPolyline = useFreehandPolyline(props, context);
    useLayerLifecycle(freehandPolyline.current, context);
    return null;
};

export default FreehandPolylineAnnotation;
