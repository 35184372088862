import ReactGA from 'react-ga4';
import { Provider } from 'react-redux';
import store from './store/store';
import { history } from './store/history';
// Allowed use of connected-react-router
// eslint-disable-next-line no-restricted-imports
import { ConnectedRouter } from 'connected-react-router';
import HttpsRedirect from 'react-https-redirect';
import ConfiguredRoot from './root-configured';
import ErrorBoundary from './error-boundary';
import { HelmetProvider } from 'react-helmet-async';
import SEO from './components/Helmet/seo';
import * as Sentry from '@sentry/react';
import { ThemeProvider } from 'styled-components';
import { theme } from './styles/style';
import Adsense from './components/Helmet/adsense';

history.listen((location) => {
    ReactGA.set({ page: location.pathname });
    ReactGA.send({ hitType: 'pageview', page: location.pathname });
});

const Root = () => {
    return (
        <HttpsRedirect disabled={process.env.NODE_ENV === 'development'}>
            <ErrorBoundary>
                <Provider store={store}>
                    <ConnectedRouter history={history}>
                        <ThemeProvider theme={theme}>
                            <HelmetProvider>
                                <SEO />
                                <Adsense />
                                <ConfiguredRoot />
                            </HelmetProvider>
                        </ThemeProvider>
                    </ConnectedRouter>
                </Provider>
            </ErrorBoundary>
        </HttpsRedirect>
    );
};

export default Sentry.withProfiler(Root);
