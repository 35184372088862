import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { selectLoggedIn, selectMyProfile } from '../../../../store/Account/selectors';
import { MobileButtonDanger } from '../../Shared/button';
import DeleteModal from '../../../Shared/Modal/delete';
import ApiUser from '../../../../api/api-user';
import { actionLogoutUser } from '../../../../store/Account/actions';
import UriHelper from '../../../../lib/uri-helper';
import Analytics from '../../../../lib/user-analytics';
import { ContentError, ContentErrorIcon } from '../../Shared/containers';

interface MobileAccountDeleteProps {
    onSuccess: () => void;
    onCancel: () => void;
}

const MobileAccountDelete = (props: MobileAccountDeleteProps) => {
    const dispatch = useDispatch();

    const isLoggedIn = useSelector(selectLoggedIn);
    const myProfile = useSelector(selectMyProfile);

    const [error, setError] = useState<string>('');
    const [showDeleteConfirm, setShowDeleteConfirm] = useState<boolean>(false);
    const [isDeleting, setIsDeleting] = useState<boolean>(false);

    useEffect(() => {
        if (!isLoggedIn || !myProfile) {
            props.onCancel();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [myProfile, isLoggedIn]);

    const handleDelete = () => {
        setShowDeleteConfirm(true);
    };

    const handleDeleteConfirmed = () => {
        if (!isLoggedIn || !myProfile) {
            props.onCancel();
        }

        setIsDeleting(true);

        ApiUser.deleteUser(myProfile?.userId)
            .then(() => {
                dispatch(actionLogoutUser());

                UriHelper.navigateToPath('/');

                Analytics.Event('Mobile', 'User deleted profile', myProfile?.userId);

                window.location.reload();
            })
            .catch((err) => setError(err.message))
            .finally(() => {
                setShowDeleteConfirm(false);
                setIsDeleting(false);
            });
    };

    if (!myProfile || !isLoggedIn) {
        return <React.Fragment />;
    }

    return (
        <AccountClosure>
            <AccountClosureHeader>Delete Account</AccountClosureHeader>
            <AccountClosureMessage>
                Once you delete your account, there is no going back.
                <br />
                Are you sure?
            </AccountClosureMessage>
            {error && (
                <ContentError>
                    <ContentErrorIcon />
                    {error}
                </ContentError>
            )}
            <AccountDeleteButton onClick={() => handleDelete()}>DELETE</AccountDeleteButton>
            {showDeleteConfirm ? (
                <DeleteModal
                    title={
                        <DeleteConfirmTitle>
                            <DeleteConfirmIcon className="fa fa-exclamation-circle" />
                            Delete Confirmation
                        </DeleteConfirmTitle>
                    }
                    message="Are you sure you want to delete your Soar account? This action can not be undone!"
                    isOpen={true}
                    onToggle={() => setShowDeleteConfirm(false)}
                    onDelete={() => handleDeleteConfirmed()}
                    isDeleting={isDeleting}
                />
            ) : null}
        </AccountClosure>
    );
};

export default MobileAccountDelete;

const AccountClosure = styled.div`
    margin-top: 128px;
    padding-bottom: 128px;
    text-align: center;
`;

const AccountClosureHeader = styled.h3`
    color: ${(props) => props.theme.color.red};
    font-size: ${(props) => props.theme.fontSize.xlarge};
`;

const AccountClosureMessage = styled.p`
    color: ${(props) => props.theme.color.white};
    margin: 16px 0;
`;

const AccountDeleteButton = styled(MobileButtonDanger)`
    margin: 16px 0;
`;

const DeleteConfirmTitle = styled.span`
    color: ${(props) => props.theme.color.red};
`;

const DeleteConfirmIcon = styled.i`
    color: ${(props) => props.theme.color.red};
    margin-right: 8px;
`;
