import SideDrawerBookMark from './BookMarkDrawer/side-drawer-bookmark';
// import SideDrawerFeatured from './FeaturedDrawer/side-drawer-featured';
import SideDrawerDiscover from './DiscoverDrawer/side-drawer-discover';
import SideDrawerListings from './ListingsDrawer/side-drawer-listings';
import SideDrawerMyMaps from './MyMapsDrawer/side-drawer-my-maps';
import SideDrawerProfile from './ProfileDrawer/side-drawer-profile';
import SideDrawerSatellites from './SatellitesDrawer/side-drawer-satellites';
import SideDrawerStoaries from './StoariesDrawer/side-drawer-stoaries';
import SideDrawerTask from './TasksDrawer/side-drawer-task';
import SideDrawerUpload from './UploadDrawer/side-drawer-upload';

import MapUploadStatus from './UploadDrawer/UploadWorkFlow/map-upload-status';

export interface LocationPath {
    pathname: string;
}

export interface SideDrawerPaths {
    satelliteSelectPath: boolean;
    uploadPath: boolean;
    reUploadPath: boolean;
    featuredPath: boolean;
    listingPath: boolean;
    mapsPath: boolean;
    adsenseTestPath: boolean;
    myMapsPath: boolean;
    bookmarkPath: boolean;
    profilePath: boolean;
    taskPath: boolean;
    stoariesPath: boolean;
}

export interface SideDrawerControlProps {
    width: string;
    minWidth: string;
    hasMinimizeButton: boolean;
    hasBackButton: boolean;
    closeIconPosition: string;
    hasCloseIcon: boolean;
    closeToPath: string;
    isSatellitePath: boolean;
    isProfilePath: boolean;
    isFeaturedPath: boolean;
    component: JSX.Element | null;
    extraComponent: JSX.Element | null;
}

export const getSideDrawerControlValues = (url: LocationPath, isExpanded: boolean) => {
    if (url.pathname.includes('/draw/')) return null;

    const paths = {
        satelliteSelectPath: url.pathname.includes('/satellites'),
        uploadPath: url.pathname === '/upload' || url.pathname.includes('/upload?'),
        reUploadPath: url.pathname.includes('/reupload'),
        featuredPath: url.pathname === '/discover' || url.pathname.includes('/discover?'),
        mapsPath: url.pathname === '/maps' || url.pathname.includes('/maps?'),
        adsenseTestPath: url.pathname.includes('/adsense'),
        listingPath: url.pathname.includes('/maps/'),
        myMapsPath: url.pathname.includes('/my-maps'),
        bookmarkPath: url.pathname.includes('/my-bookmarks'),
        profilePath: url.pathname.includes('/profile'),
        taskPath: url.pathname.includes('/task'),
        stoariesPath: url.pathname === '/draw',
    };

    const handleClosePath = (paths: SideDrawerPaths) => {
        if (paths.uploadPath || paths.reUploadPath || paths.mapsPath || paths.profilePath) {
            return '/maps';
        }

        return window.location.pathname.split('/')[1];
    };

    const closeToPath = handleClosePath(paths);

    const isCloseIconHidden =
        paths.mapsPath ||
        paths.listingPath ||
        paths.featuredPath ||
        paths.satelliteSelectPath ||
        paths.myMapsPath ||
        paths.bookmarkPath ||
        paths.taskPath ||
        paths.uploadPath ||
        paths.stoariesPath;

    let drawerControl: SideDrawerControlProps = {
        width: '405px',
        minWidth: '76px',
        closeIconPosition: 'calc(405px - 35px)',
        hasCloseIcon: isCloseIconHidden,
        closeToPath,
        hasBackButton: paths.listingPath ? true : false,
        hasMinimizeButton: paths.listingPath ? true : false,
        isSatellitePath: paths.satelliteSelectPath ? true : false,
        isProfilePath: paths.profilePath ? true : false,
        isFeaturedPath: paths.featuredPath ? true : false,
        component: getSideDrawerContent(paths, isExpanded),
        extraComponent: getSideDrawerExtraContent(paths, isExpanded),
    };

    if (paths.featuredPath) {
        drawerControl = {
            ...drawerControl,
            width: '100vw',
            minWidth: '76px',
            closeIconPosition: 'calc(100vw - 35px)',
        };
    }
    if (paths.uploadPath) {
        drawerControl = {
            ...drawerControl,
            width: '100%',
        };
    }

    return drawerControl;
};

export const getSideDrawerContent = (paths: SideDrawerPaths, isExpanded: boolean) => {
    if (!isExpanded) return null;
    if (paths.satelliteSelectPath) return <SideDrawerSatellites />;
    if (paths.mapsPath || paths.listingPath || paths.adsenseTestPath) return <SideDrawerListings />;
    if (paths.profilePath) return <SideDrawerProfile />;
    if (paths.uploadPath || paths.reUploadPath) return <SideDrawerUpload />;
    if (paths.featuredPath) return <SideDrawerDiscover />;
    if (paths.bookmarkPath) return <SideDrawerBookMark />;
    if (paths.myMapsPath) return <SideDrawerMyMaps />;
    if (paths.taskPath) return <SideDrawerTask />;
    if (paths.stoariesPath) return <SideDrawerStoaries />;
    return null;
};

export const getSideDrawerExtraContent = (paths: SideDrawerPaths, isExpanded: boolean) => {
    if (!isExpanded) return null;
    if (paths.uploadPath || paths.reUploadPath) return <MapUploadStatus />;
    return null;
};
