import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

interface MobileOpacityControlProps {
    className?: string;
    opacity: number;
    onOpacityChange: (opacity: number) => void;
    showOpacityLabel?: boolean;
}

const MobileOpacityControl = (props: MobileOpacityControlProps) => {
    const sliderRef = useRef<any>(null); // eslint-disable-line @typescript-eslint/no-explicit-any
    const [opacity, setOpacity] = useState(props.opacity ?? 1);

    const showOpacityLabel = props.showOpacityLabel ?? true;

    useEffect(() => {
        if (sliderRef && sliderRef.current) {
            sliderRef.current.focus();
        }
    }, []);

    useEffect(() => {
        setOpacity(props.opacity);
    }, [props.opacity]);

    return (
        <MobileOpacityControlContainer className={props.className}>
            <SliderInputContainer>
                {showOpacityLabel && <SliderValue>{(opacity * 100).toFixed()}%</SliderValue>}
                <Slider
                    className="opacity-slider_mobile"
                    ref={sliderRef}
                    type="range"
                    value={opacity * 100}
                    step={0.01}
                    onChange={(e) => {
                        const opacity = parseInt(e.target.value) / 100;
                        setOpacity(opacity);
                        props.onOpacityChange?.(opacity);
                    }}
                />
            </SliderInputContainer>
        </MobileOpacityControlContainer>
    );
};

export default MobileOpacityControl;

const MobileOpacityControlContainer = styled.div`
    position: absolute;
    z-index: ${(props) => props.theme.zIndex.control};
    top: 65px;
    left: 5px;

    width: 45px;
    height: 30vh;

    background: rgba(0, 0, 0, 0);

    padding-top: 25px;

    @keyframes slideInFromLeft {
        0% {
            transform: translateX(-100%);
            opacity: 0;
        }
        100% {
            transform: translateX(0);
            opacity: 1;
        }
    }

    animation: 1s ease-out 0s 1 slideInFromLeft;
`;

const SliderInputContainer = styled.div`
    width: 100%;
    height: 100%;
    position: relative;
`;

const SliderValue = styled.div`
    position: absolute;
    top: -25px;
    left: 0;
    width: 100%;

    text-align: center;

    color: ${(props) => props.theme.color.yellow};
    font-weight: 700;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.75);
`;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const Slider = styled.input<any>`
    width: 30vh;
    height: 8px;
    padding: 0;

    background: linear-gradient(
        to left,
        rgba(255, 255, 255, 0.3) ${(props) => 100 - props.value}%,
        #eed926 ${(props) => 100 - props.value}%
    );
    box-shadow: 1px -2px 5px rgba(0, 0, 0, 0.5);
    border: none !important;
    outline: none;
    border-radius: 5px;
    pointer-events: all;

    -webkit-appearance: none !important;
    appearance: none !important;

    transform: rotate(270deg) translateX(-230px) translateY(25px);
    transform-origin: bottom left;

    &::-webkit-slider-container {
        -webkit-appearance: none !important;
        appearance: none !important;
    }

    &::-webkit-slider-runnable-track {
        -webkit-appearance: none !important;
        appearance: none !important;

        height: 8px;
        border: none !important;
        border-color: transparent !important;
        color: transparent !important;
        outline: none;
        border-radius: 5px;
    }

    &::-webkit-slider-thumb {
        -webkit-appearance: none !important;
        pointer-events: all;
        cursor: pointer;
        margin-top: -4px;

        width: 18px;
        height: 18px;

        transform: scale(1.3);

        border-color: transparent !important;
        border-style: none !important;
        border-radius: 50%;

        box-shadow: 1px -2px 5px rgba(0, 0, 0, 0.5);
        background-color: #eed926 !important;
        color: #eed926 !important;
    }

    &::-moz-range-thumb {
        -moz-appearance: none !important;
        appearance: none !important;
        box-sizing: content-box;
        border-color: #eed926 !important;
        background: #eed926 !important;
        color: transparent !important;
        pointer-events: all;
        border-radius: 50%;
        height: 18px;
        width: 18px;
        cursor: pointer;

        box-shadow: 1px -2px 5px rgba(0, 0, 0, 0.5);
    }

    &:focus {
        pointer-events: all;
        background: linear-gradient(
            to left,
            rgba(255, 255, 255, 0.3) ${(props) => 100 - props.value}%,
            #eed926 ${(props) => 100 - props.value}%
        );
    }
`;
