import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    setPolygonDeleteAction,
    setPolygonOptionsAction,
    setPolygonUpdateAction,
} from '../../../../store/Annotations/Polygon/actions';
import { selectPolygons } from '../../../../store/Annotations/Polygon/selectors';
import { setDeleteFillPatternAction, setSelectedAnnotationAction } from '../../../../store/Annotations/actions';
import { selectSelectedAnnotation } from '../../../../store/Annotations/selectors';
import DrawToolAnalytics from '../Analytics/draw-tool-analytics';
import { Pattern, findAndUpdateAvailablePattern } from '../FillPattern/availablePatterns';
import { handlePatternFillUrl, handlePattternIdFromPatternFillUrl } from '../FillPattern/pattern-util';
import LineStyle from '../Popup/line-style';
import FillColor from '../ToolBar/fill-color';
import PolygonMeasurementToolbar from './polygon-measurement-toolbar';
import AnnotationDelete from '../annotation-delete';
import { ToolbarContainer, ToolbarDivider, ToolbarText } from '../annotation-toolbar';
import LayerOrderControl from '../layer-order-control';
import PolygonPath from './polygon';
import { MeasurementLabelColor } from '../Measurement/measurement-toolbar-label-color';
import { MeasurementBackgroundColor } from '../Measurement/measurement-toolbar-background-color';
import { MeasurementLengthType } from '../Measurement/measurement-toolbar-polygon-length';

const PolygonToolbar = () => {
    const polygons = useSelector(selectPolygons);
    const selectedAnnotation = useSelector(selectSelectedAnnotation);
    const dispatch = useDispatch();
    const selectedPolygon = polygons.find((c) => c.id === selectedAnnotation?.id);

    const updatePolygon = (polygon: PolygonPath) => {
        dispatch(setPolygonUpdateAction(polygon));
    };

    if (!selectedPolygon) return <React.Fragment />;

    return (
        <ToolbarContainer>
            <ToolbarText>Fill:</ToolbarText>
            <FillColor
                selectedColor={selectedPolygon.options.color || '#eed926'}
                onSelectColor={(color) => {
                    let selectedPolygonFillColor = selectedPolygon?.options?.fillColor as string;

                    const patternId = handlePattternIdFromPatternFillUrl(selectedPolygonFillColor);

                    if (patternId !== 'none') {
                        const updatedPattern = findAndUpdateAvailablePattern(selectedPolygon.id, patternId, color);

                        selectedPolygonFillColor = handlePatternFillUrl(updatedPattern as Pattern);
                    } else {
                        selectedPolygonFillColor = color;
                    }

                    updatePolygon({
                        ...selectedPolygon,
                        options: {
                            ...selectedPolygon.options,
                            fillColor: selectedPolygonFillColor,
                            color: color,
                        },
                    });
                    dispatch(
                        setPolygonOptionsAction({
                            ...selectedPolygon.options,
                            fillColor: selectedPolygonFillColor,
                            color: color,
                        })
                    );
                }}
                onSetOpacity={(opacity) => {
                    updatePolygon({
                        ...selectedPolygon,
                        options: {
                            ...selectedPolygon.options,
                            fillOpacity: opacity,
                        },
                    });
                    dispatch(
                        setPolygonOptionsAction({
                            ...selectedPolygon.options,
                            fillOpacity: opacity,
                        })
                    );
                }}
                opacity={selectedPolygon.options.fillOpacity || 0.2}
                showFillPattern={true}
                onSetFillPatternUrl={(fillPattern: string) => {
                    if (fillPattern.includes('none')) {
                        updatePolygon({
                            ...selectedPolygon,
                            fillPattern: undefined,
                            options: {
                                ...selectedPolygon.options,
                                fillColor: selectedPolygon.options.color,
                                fillOpacity: 0.2,
                            },
                        });
                        dispatch(
                            setPolygonOptionsAction({
                                ...selectedPolygon.options,
                                fillColor: selectedPolygon.options.color,
                                fillOpacity: 0.2,
                            })
                        );
                        return;
                    }

                    updatePolygon({
                        ...selectedPolygon,
                        fillPattern: fillPattern,
                        options: {
                            ...selectedPolygon.options,
                            fillColor: fillPattern,
                            fillOpacity: 1,
                        },
                    });
                    dispatch(
                        setPolygonOptionsAction({
                            ...selectedPolygon.options,
                            fillColor: fillPattern,
                            fillOpacity: 1,
                        })
                    );
                }}
                fillPattern={handlePattternIdFromPatternFillUrl(selectedPolygon?.options?.fillColor as string)}
            />

            <ToolbarDivider />

            <ToolbarText>Line Style:</ToolbarText>
            <LineStyle
                selectedLineStyle={{
                    dashArray: selectedPolygon.options.dashArray,
                    weight: selectedPolygon.options.weight,
                }}
                onSelectLineStyle={(lineStyle) => {
                    updatePolygon({
                        ...selectedPolygon,
                        options: {
                            ...selectedPolygon.options,
                            dashArray: lineStyle.dashArray ? lineStyle.dashArray : undefined,
                            weight: lineStyle.weight ? lineStyle.weight : undefined,
                        },
                    });
                    dispatch(
                        setPolygonOptionsAction({
                            ...selectedPolygon.options,
                            dashArray: lineStyle.dashArray ? lineStyle.dashArray : undefined,
                            weight: lineStyle.weight ? lineStyle.weight : undefined,
                        })
                    );
                }}
            />

            <ToolbarDivider />
            <ToolbarText>Measurements:</ToolbarText>
            <PolygonMeasurementToolbar
                polygon={selectedPolygon}
                onChangeShowArea={(showArea: boolean) => {
                    updatePolygon({
                        ...selectedPolygon,
                        showArea: showArea,
                    });
                }}
                onChangeShowLength={(showLength: MeasurementLengthType) => {
                    updatePolygon({
                        ...selectedPolygon,
                        showLength: showLength,
                    });
                }}
                onChangeUnits={(units: 'metric' | 'imperial') => {
                    updatePolygon({
                        ...selectedPolygon,
                        units: units,
                    });
                }}
                onChangeLabelColor={(labelColor: MeasurementLabelColor) => {
                    updatePolygon({
                        ...selectedPolygon,
                        labelColor: labelColor,
                    });
                }}
                onChangeLabelBgColor={(labelBgColor: MeasurementBackgroundColor) => {
                    updatePolygon({
                        ...selectedPolygon,
                        labelBgColor: labelBgColor,
                    });
                }}
            />

            <ToolbarDivider />
            <ToolbarText>Arrange:</ToolbarText>
            <LayerOrderControl annotation={selectedPolygon} />

            <ToolbarDivider />
            <AnnotationDelete
                onClick={() => {
                    dispatch(setDeleteFillPatternAction(selectedPolygon.id));
                    DrawToolAnalytics.delete('polygon');
                    dispatch(setPolygonDeleteAction(selectedPolygon));
                    dispatch(setSelectedAnnotationAction(undefined));
                }}
            />
        </ToolbarContainer>
    );
};

export default PolygonToolbar;
