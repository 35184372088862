import React from 'react';
import { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Dropdown, DropdownToggle } from 'reactstrap';
import styled from 'styled-components';
import { selectMyProfile } from '../../../store/Account/selectors';
import { LoginModalMode } from '../../Registration/login-enum';
import LoginRegisterDialog from '../../Registration/login-register-dialog';
import ReportMapContent from './report-map-content';
import ReportMapMenu from './report-map-menu';

interface ReportMapProps {
    className?: string;
}

const ReportMap = ({ className }: ReportMapProps) => {
    const [dropdownOpen, setDropdownOpen] = useState<boolean>(false);
    const [showLogin, setShowLogin] = useState<boolean>(false);
    const toggleRef = useRef<HTMLInputElement>(null);

    const myProfile = useSelector(selectMyProfile);

    const handleReportImage = () => {
        if (!myProfile) {
            setShowLogin(true);
            return;
        }
        setDropdownOpen(!dropdownOpen);
    };

    return (
        <React.Fragment>
            <ReportMapDropdown
                data-testid="report-map-button"
                className={className}
                direction="right"
                onClick={(e) => e.stopPropagation()}
                isOpen={dropdownOpen}
                toggle={handleReportImage}
            >
                <ReportToggle
                    tag="span"
                    className="report-map-toggle"
                    id="report-map-toggle"
                    data-tooltip-id={`report-map`}
                    data-tooltip-content={`Report Map`}
                >
                    <ReportEllipsisIcon title={`Report Map`} className={'fa fa-flag report-map-icon'} ref={toggleRef} />
                </ReportToggle>

                {dropdownOpen && toggleRef?.current ? (
                    <React.Fragment>
                        <ReportMapMenu toggleRef={toggleRef.current}>
                            <ReportMapContent handleReportIsOpen={() => setDropdownOpen(!dropdownOpen)} />
                        </ReportMapMenu>
                    </React.Fragment>
                ) : null}
            </ReportMapDropdown>
            {showLogin && (
                <LoginRegisterDialog
                    isOpen={true}
                    initialMode={LoginModalMode.LOGIN}
                    onClose={() => setShowLogin(false)}
                />
            )}
        </React.Fragment>
    );
};

export default ReportMap;

const ReportMapDropdown = styled(Dropdown)``;

const ReportToggle = styled(DropdownToggle)`
    /* duplicate styling of StyledButton (../../Drawer/Maps/ActiveMap/Sharing/side-drawer-social) */

    color: white;

    display: flex;
    align-items: center;
    justify-content: center;

    background: rgba(0, 0, 0, 0.4);
    backdrop-filter: blur(5px);
    border: 1px solid rgba(142, 145, 143, 0.3);
    border-radius: 6px;
    height: 40px;
    letter-spacing: 0.25px;
    transition: transform 0.3s ease, box-shadow 0.3s ease, background-color 0.218s, border-color 0.218s;
    width: 40px;
    margin: 5px;
    outline: none !important;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

    :hover {
        transform: translateY(-2px);
        box-shadow: 0 8px 15px rgba(0, 0, 0, 0.2);
        background: rgba(26, 26, 27, 0.9);
        border-color: rgba(116, 119, 117, 0.9);
    }

    &:hover {
        color: #eed926;
    }

    &:after {
        display: block;
    }
`;

const ReportEllipsisIcon = styled.i`
    font-size: 16px;
    color: white;
    padding: 8px 2px;
    cursor: pointer;
    margin-right: 5px;
`;
