import styled from 'styled-components';
import DiscoverSearchResults from './discover-search-results';
import DiscoverRecentlyViewedMaps from './discover-recently-viewed-maps';
import { useSelector } from 'react-redux';
import { selectSelectedSearchTerm } from '../../../../store/Map/MapSelection/selectors';
import AdsenseComponent from '../../../Adsense/adsense';

const SideDrawerDiscover = () => {
    const searchTerm = useSelector(selectSelectedSearchTerm);

    return (
        <DiscoverMasonryContainer>
            <DiscoverSearchResults
                selectedSearchTerm={searchTerm || ''}
                selectedOrder={!searchTerm || searchTerm.length === 0 ? '' : 'RELEVANCE'}
            >
                {!searchTerm || searchTerm.length === 0 ? <DiscoverRecentlyViewedMaps /> : null}
                <AdsenseComponent
                    client="ca-pub-2920378478344292"
                    slot="2730650283"
                    format="auto"
                    data-ad-format="autorelaxed"
                    style={{ display: 'block', margin: '0 auto 8px auto', width: '800px', height: '480px' }}
                />
            </DiscoverSearchResults>
        </DiscoverMasonryContainer>
    );
};

export default SideDrawerDiscover;

const DiscoverMasonryContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: calc(100vw - 80px);
    height: calc(100vh - 75px);
`;
