export const actionTypes = {
    FETCH_USER_PROFILE_WITH_LISTINGS_BEGIN: 'USER_PROFILE/FETCH_USER_PROFILE_WITH_LISTINGS_BEGIN',
    FETCH_USER_PROFILE_WITH_LISTINGS_ERROR: 'USER_PROFILE/FETCH_USER_PROFILE_WITH_LISTINGS_ERROR',

    FETCH_USER_PROFILE_LOADING: 'USER_PROFILE/FETCH_USER_PROFILE_LOADING',
    FETCH_USER_LISTINGS_LOADING: 'USER_PROFILE/FETCH_USER_LISTINGS_LOADING',

    FETCH_USER_PROFILE_SUCCESS: 'USER_PROFILE/FETCH_USER_PROFILE_SUCCESS',
    FETCH_USER_LISTINGS_SUCCESS: 'USER_PROFILE/FETCH_USER_LISTINGS_SUCCESS',

    CLEAR_USER_PROFILE: 'USER_PROFILE/CLEAR_USER_PROFILE',
    CLEAR_USER_LISTINGS: 'USER_PROFILE/CLEAR_USER_LISTINGS',
};
