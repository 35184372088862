import PageError from '../Shared/page-error';

const Mobile404 = () => {
    return (
        <PageError
            gradient
            background="/assets/backgrounds-large/error-background.jpg"
            credit={{
                avatarUrl: 'https://avatar.soar.earth/ffba502ef2914a1f975b6a7868c722achsf.png/preview',
                location: 'Salt Pans',
                name: 'Kim Shapley',
                profile: 'https://soar.earth/profile/ffba502ef2914a1f975b6a7868c722ac',
            }}
        >
            <h3>Oops!</h3>
            <p>
                It looks like the page you are after
                <br />
                does not exist.
            </p>
            <button onClick={() => window.open('/', '_self')}>BACK TO SOAR</button>
        </PageError>
    );
};

export default Mobile404;
