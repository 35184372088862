import styled from 'styled-components';
import Analytics from '../../../lib/user-analytics';
import profileIconUrl from '../profile-icon-url';
import ImageryPublicSelector from './imagery-public-selector';
import { ListingDTO, ListingType } from '../../../api/model';
import { isMobileVersion } from '../../../lib/soar-helper';

interface ProfileImageryEditDropdownProps {
    setEditImage: (editImage: boolean) => void;
    setEditDetailsOpen: (detailsOpen: boolean) => void;
    handleShowDelete: () => void;
    download: () => void;
    setIsContactFormActive: (isActive: boolean) => void;
    listing: ListingDTO;
    handleAction?: (action: string) => void;
    dataTestId?: string;
}

const ImageryEditDropdown = (props: ProfileImageryEditDropdownProps) => {
    const {
        setEditDetailsOpen,
        setEditImage,
        handleShowDelete,
        download,
        setIsContactFormActive,
        listing,
        handleAction,
    } = props;

    return (
        <ProfileDropDownListContainer onMouseEnter={() => setEditImage(true)} onMouseLeave={() => setEditImage(false)}>
            {listing.listingType !== ListingType.NOT_SUPPORTED && !listing.feedback && listing.review !== 'REJECTED' && (
                <ProfileDropDownListItem
                    data-testid={props.dataTestId && `${props.dataTestId}-edit`}
                    onClick={() => {
                        setEditDetailsOpen(true);
                        setEditImage(false);
                        Analytics.Event('My Dashboard', 'Clicked Imagery Dropdown Edit');
                    }}
                >
                    <ProfileDropDownIcon src={profileIconUrl.editIconLightUrl} />
                    Edit
                </ProfileDropDownListItem>
            )}
            {listing.listingType !== ListingType.NOT_SUPPORTED && listing.review === 'REJECTED' && (
                <ProfileDropDownListItem
                    data-testid={props.dataTestId && `${props.dataTestId}-resubmit`}
                    onClick={() => {
                        handleAction && handleAction('RESUBMIT');
                        setEditImage(false);
                        Analytics.Event('My Dashboard', 'Clicked Imagery Dropdown Resubmit');
                    }}
                >
                    <ProfileDropDownIcon src={profileIconUrl.editIconLightUrl} />
                    Resubmit
                </ProfileDropDownListItem>
            )}
            {/* Enable reupload only for rejected map */}
            {listing.listingType !== ListingType.NOT_SUPPORTED && listing.review === 'REJECTED' && (
                <ProfileDropDownListItem
                    data-testid={props.dataTestId && `${props.dataTestId}-reupload`}
                    onClick={() => {
                        handleAction && handleAction('REUPLOAD');
                        setEditImage(false);
                        Analytics.Event('My Dashboard', 'Clicked Imagery Dropdown Reupload');
                    }}
                >
                    <ProfileDropDownIcon src={profileIconUrl.imageryLightUrl} />
                    Reupload
                </ProfileDropDownListItem>
            )}

            {!listing.feedback && (
                <ProfileDropDownListItem data-testid={props.dataTestId && `${props.dataTestId}-visibility`}>
                    <ImageryPublicSelector
                        listingId={listing.id}
                        isPublic={listing.public || listing.public === undefined}
                        onChangeVisibility={(visible) => {
                            handleAction && handleAction(visible ? 'PUBLIC' : 'PRIVATE');
                        }}
                    />
                </ProfileDropDownListItem>
            )}
            {!isMobileVersion ? (
                <ProfileDropDownListItem
                    data-testid={props.dataTestId && `${props.dataTestId}-download`}
                    onClick={() => {
                        download();
                        setEditImage(false);
                        Analytics.Event('My Dashboard', 'Clicked Imagery Dropdown Download');
                    }}
                >
                    <ProfileDropDownIcon src={profileIconUrl.downloadUrl} />
                    Download
                </ProfileDropDownListItem>
            ) : null}
            <ProfileDropDownListItem
                data-testid={props.dataTestId && `${props.dataTestId}-report`}
                onClick={() => {
                    setIsContactFormActive(true);
                    setEditImage(false);
                    Analytics.Event('My Dashboard', 'Clicked Imagery Dropdown Report Problem');
                }}
            >
                <ProfileDropDownIcon src={profileIconUrl.reportProblemURL} />
                Report A Problem
            </ProfileDropDownListItem>

            <ProfileDropDownListItem
                data-testid={props.dataTestId && `${props.dataTestId}-delete`}
                onClick={(e) => {
                    e.preventDefault();
                    handleShowDelete();
                    Analytics.Event('My Dashboard', 'Clicked Imagery Dropdown Delete');
                }}
            >
                <ProfileDropDownIcon src={profileIconUrl.deleteIconUrl} />
                Delete
            </ProfileDropDownListItem>
        </ProfileDropDownListContainer>
    );
};

export default ImageryEditDropdown;

const ProfileDropDownListContainer = styled.div`
    border-radius: 0px 0px 6px 6px;
    position: absolute;
    z-index: 1000;
    width: 200px;
    right: 0px;
`;

const ProfileDropDownListItem = styled.li`
    cursor: pointer;
    list-style: none;
    color: #ffffff;
    padding: 8px 20px;
    border-bottom: 1px solid rgb(255 255 255 / 30%);
    background: rgb(0 0 0 / 0.8);

    &:hover {
        background: rgb(0 0 0 / 0.6);
    }

    &:last-child {
        border-bottom: none;
        border-radius: 0px 0px 6px 6px;
        color: red;
    }
`;

const ProfileDropDownIcon = styled.img`
    width: 20px;
    height: 20px;
    padding-right: 4px;
    margin: -4px 8px 0 -12px;
`;
