import styled from 'styled-components';

export const StyledButton = styled.button`
    outline: none !important;
    display: block;
    background-color: ${(props) => (props.disabled ? 'rgba(255,255,255,0.8)' : '#eed926')} !important;
    border: 1px solid rgba(0, 0, 0, 0.2);
    color: black;
    -webkit-text-fill-color: black;
    font-size: 1rem;
    border-radius: 4px;
    padding: 8px 25px;
    text-transform: uppercase;
    user-select: none;

    :active,
    :focus {
        outline: none;
    }

    &:disabled {
        cursor: not-allowed;
    }

    @media (hover: hover) and (pointer: fine) {
        &:hover {
            opacity: 0.6;
        }
    }

    & > i.fa {
        &.ml-2 {
            margin-left: 0.5rem;
        }

        &.mr-2 {
            margin-right: 0.5rem;
        }

        &.mr-1 {
            margin-right: 0.25rem;
        }
    }
`;

export const TransparentButton = styled(StyledButton)`
    background-color: transparent !important;
    border: 1px solid #eed926 !important;
    color: #eed926;
    -webkit-text-fill-color: #eed926;

    &:hover {
        opacity: 0.6;
    }
`;

export const StyledDarkButton = styled(StyledButton)`
    background-color: transparent !important;
    border: 1px solid rgba(255, 255, 255, 0.8) !important;
    color: white;
    -webkit-text-fill-color: white;

    &:hover {
        opacity: 0.6;
    }
`;

export const StyledDeleteButton = styled(StyledButton)`
    background-color: red !important;
    border: 1px solid rgba(0, 0, 0, 0.2);
    color: black;
    -webkit-text-fill-color: black;

    &:hover {
        opacity: 0.6;
    }
`;

export const StyledTransButton = styled(StyledButton)`
    background-color: transparent !important;
    color: white;
    -webkit-text-fill-color: white;
    border: none;
    padding: 8px 2px;
`;
